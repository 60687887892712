import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import FooterDesktop from './Desktop'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';

import FooterMobile from './Mobile';
import { CommonBtn } from '../../components/CustomStyle/Common';
import { Link } from 'react-router-dom';

const Footer = () => {

    const theme = useTheme();

    const company = [
        {
            title: 'About Us',
            link: '/company/about',
            arialable: 'About Ugam Infotech'
        },
        {
            title: 'Careers',
            link: '/company/careers',
            arialable: 'Read more about our Careers'
        },
        {
            title: 'Contact Us',
            link: '/contact-us',
            arialable: 'Our contact detail'
        },
        {
            title: 'Our Culture',
            link: '/company/our-culture',
            arialable: 'Read more about our culture'
        },
        // {
        //     title: 'Testimonials',
        //     link: ''
        // }, {
        //     title: 'Our Team',
        //     link: ''
        // }
    ]

    const Services = [
        {
            title: 'Web Design & Development',
            link: '/services/web-design-development',
            arialable: 'Read more about Web Design and Development'

        },
        // {
        //     title: 'Mobile App Development',
        //     link: ''
        // },
        {
            title: 'E-Commerce & CMS',
            link: '/services/ecommerce-and-cms',
            arialable: 'Read more about E-Commerce & CMS'
        }, {
            title: 'Animation',
            link: '/services/animation',
            arialable: 'Read more about Animation'
        }, {
            title: 'Designing',
            link: '/services/design',
            arialable: 'Read more about Designing'
        }, {
            title: 'Digital Marketing & Content Solutions',
            link: '/services/digital-marketing-and-contentSolutions',
            arialable: 'Read more about Digital Marketing & Content Solutions'
        }
    ]

    const Portfolio = [
        {
            title: 'Website Design',
            link: '/portfolio/website-design',
            arialable: 'Read more about Website Design'
        }, {
            title: 'Graphic Design',
            link: '/portfolio/graphic-design',
            arialable: 'Read more about Graphic Design'
        }, {
            title: 'Brochure Design',
            link: '/portfolio/brochure-design',
            arialable: 'Read more about Brochure Design'
        }, {
            title: 'Coroprate Brand Identity Design',
            link: '/portfolio/brandidentity-design',
            arialable: 'Read more about Coroprate Brand Identity Design'
        }, {
            title: 'Logo Design',
            link: '/portfolio/logo-design',
            arialable: 'Read more about Logo Design'
        }, {
            title: 'Business Card Design',
            link: '/portfolio/business-card-design',
            arialable: 'Read more about Business Card Design'
        }, {
            title: 'Social Media Design',
            link: '/portfolio/social-media-design',
            arialable: 'Read more about Social Media Design'
        }, {
            title: 'Flyer/Pamphlet Design',
            link: '/portfolio/pamphlet-design',
            arialable: 'Read more about Flyer/Pamphlet Design'
        }, {
            title: 'T-shirt Design',
            link: '/portfolio/tshirt-design',
            arialable: 'Read more about T-shirt Design'
        }
    ]

    const contact = [
        {
            title: 'Office',
            number: '+91(846)-096-1523',
            link: 'tel:+918460961523',
            arialable: 'Our contact detail'
        }, {
            title: 'Career',
            number: '+91(846)-096-1523',
            link: 'tel:+918460961523',
            arialable: 'Our Career detail'
        }
    ]

    const socialIcon = [
        {
            icon: FacebookOutlinedIcon,
            link: 'https://www.facebook.com/ugaminfotech',
            arialable: 'Visit our facebook page',
            name: 'Facebook'
        },
        {
            icon: InstagramIcon,
            link: 'https://www.instagram.com/ugaminfotech/',
            arialable: 'Visit our instagram page',
            name: 'Instagram'
        },
        {
            icon: LinkedInIcon,
            link: 'https://www.linkedin.com/company/ugam-infotech',
            arialable: 'Visit our linkedin page',
            name: 'Linkedin'
        },
    ]

    const access_cost = [
        {
            email: 'info@ugaminfotech.com',
            link: ''
        }
    ]

    const currentYear = new Date().getFullYear();

    const copyRightCnt = `© ${currentYear} Ugam Infotech. All Rights Reserved`;

    const privacyTremBtn = [
        {
            text: 'Privacy Policy',
            link: ''
        },
        {
            text: 'Terms and Conditions',
            link: ''
        }
    ];

    return (
        <>
            <Box sx={{ pt: 10, backgroundColor: theme.palette.background.lightDark }}></Box>
            <Box sx={{ backgroundColor: theme.palette.background.default, pt: 14, position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: 0, left: '50%', transform: 'translate(-50% , -50%)', width: { xl: '50%', lg: '70%', md: '90%', xs: '90%' }, borderRadius: 8, background: theme.palette.secondary.main, zIndex: 999 }}>
                    <Box sx={{
                        position: 'relative', '&:before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: `url(${`${process.env.REACT_APP_URL}/images/footer-img/footer_cta_bg.png`})`,
                        }
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { md: 'row', xs: 'column' }, py: { md: 8, xs: 4 }, px: { md: 6, sm: 4, xs: 3 } }}>
                            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 600, fontSize: { md: '26px', sm: '22px', xs: '20px' }, textAlign: { md: 'start', xs: 'center' }, color: '#FFF' }}>
                                Want to know more about Ugam Infotech's Life?
                            </Typography>
                            <Box sx={{ mt: { md: 0, xs: 2 } }}>
                                <Link to="/company/our-culture" aria-label="Learn more about our culture" title='life @ugam' rel="follow">
                                    <CommonBtn text={"life @ugam"} backgroundColor={'#FFF'} hoverBGColor={'#FFF'} color={'#000'} />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: { md: 'block', xs: 'none' }, position: 'relative', py: 8, backgroundColor: theme.palette.background.default
                }}>
                    <FooterDesktop company={company} services={Services} portfolio={Portfolio} contact={contact} socialIcon={socialIcon} copyRightCnt={copyRightCnt} privacyTremBtn={privacyTremBtn} />
                </Box>
                <Box sx={{
                    display: { md: 'none', xs: 'block' }, position: 'relative', py: 3, backgroundColor: theme.palette.background.default
                }}>
                    <FooterMobile company={company} services={Services} portfolio={Portfolio} contact={contact} socialIcon={socialIcon} copyRightCnt={copyRightCnt} privacyTremBtn={privacyTremBtn} />
                </Box>
            </Box>
        </>
    )
}

export default Footer