import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import { ServiceHeading } from '../../../Common';

const ServiceFrontEnd = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, pt: { md: 10, xs: 6 }, pb: { md: 20, xs: 10 } }} >
            <Container maxWidth='xl'>
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ backgroundColor: theme.palette.background.default, textAlign: 'center', width: { lg: '56%', md: '70%', xs: '100%' }, m: '0 auto', p: { md: 5, xs: 2 }, position: 'relative', zIndex: 1 }}>
                        <ServiceHeading text='Front End' />
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        In frontend app development, we leverage a variety of technologies and libraries. We utilize HTML and CSS to structure content on the page, enhance style, and design to create visually appealing web pages that meet user expectations.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        When developing front-end applications, we prioritize accessibility to ensure the website is usable by all users across the web.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        Our frontend experts utilize preprocessors, libraries, and CSS frameworks to streamline your tasks, complemented by JavaScript frameworks like React, Vue, and Angular.
                        </Typography>
                    </Box>
                    <Box sx={{ position: { md: 'absolute' }, top: 400, left: 0, zIndex: 0, mt: { md: 0, xs: 3 } }}>
                        <Box sx={{ p: { md: 3, sm: 2, xs: 1 }, backgroundColor: theme.palette.primary.contrastText, width: '100%', height: '100%', position: 'relative' }}>
                            <img width='100%' height='100%' src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/front-end-image.png`} alt='front-end-image' title='Web Design Trends Exploring the Latest Innovations' loading='lazy' />
                            <Box sx={{ position: 'absolute', top: 0, transform: 'rotate(270deg)', left: -110, zIndex: -1 }}>
                                <img src={`${process.env.REACT_APP_URL}/images/about.svg`} alt='about' title='Ugam Infotech Team Meeting at Conference' loading='lazy' width='auto' height='auto' />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default ServiceFrontEnd