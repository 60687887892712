import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import { ArrowCommon, ServiceHeading } from '../../../Common';
import { useTheme } from '@emotion/react';

const DesignIllustration = () => {
    const theme = useTheme();

    const arr = [
        'Illustrative Animation Services',
        'Brand Graphics Design',
        'Image Illustration Services',
        'Digital illustration',
        'Sports Graphic Illustrations',
        'Comic Illustration Services',
        'Vector Graphic Solutions',
        'Character Illustration Services',
        'Two-Dimensional Data Visuals'
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, pt: { md: 10, xs: 8 }, pb: { md: 10, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row' } }}>
                    <Grid item lg={7} md={12} xs={12}>
                        <Box sx={{ position: 'relative' }}>
                            <ServiceHeading text='Illustration' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>Ugam Infotech supports individuals and businesses by <b>providing customized services</b> at the most competitive prices and within the shortest time frames.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 6, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                Our team adheres to strict <b>quality standards</b> and ensures a smooth, trouble-free outsourcing process.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 6, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                We create <b>visually compelling designs</b> that capture the interest of your target audience and enhance readability. Our creative team has assisted numerous clients globally over the past several decades.
                            </Typography>
                            <Box sx={{ position: 'absolute', display: { lg: 'block', xs: 'none' }, left: { xl: -180, xs: 0 }, bottom: { lg: -410 }, width: { lg: '30%', md: '25%', sm: '34%', xs: '60%' }, height: 'auto', mt: { md: 0, xs: 3 }, m: '0 auto' }}>
                                <img src={`${process.env.REACT_APP_URL}/images/pages/services/designing/illustration.png`} width='100%'  height='auto' title='Illustration' loading='lazy' alt='illustration'/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={12} xs={12} >
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ p: { lg: 6, md: 5, sm: 4, xs: 3 }, backgroundColor: theme.palette.primary.contrastText, borderRadius: 6 }}>
                                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 600, fontSize: { lg: '26px', md: '24px', xs: '22px' } }}>
                                    As part of our illustration services, we provide the following:
                                </Typography>
                                <Box sx={{ mt: { md: 4, xs: 2 } }}>
                                    {
                                        arr.map((item, i) => (
                                            <ArrowCommon key={i} text={item} color={theme.palette.primary.main} backgroundColor={theme.palette.background.default} />
                                        ))
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}

export default DesignIllustration