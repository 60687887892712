import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Container, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import Festival from './Components/Festival';
import OurCultureDesc from './Components/OurCultureDesc';
import SEO from '../../SEO';

function OurCulture() {
    const theme = useTheme();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SEO
                title="Our Culture - Ugam Infotech"
                description="Discover the unique culture at Ugam Infotech. Learn about our values, work environment, and team spirit."
                keywords="Ugam, Ugam Infotech, our culture, company culture, values, work environment, team spirit, React.js, Node.js"
                canonical={`${process.env.REACT_APP_URL}/company/our-culture`}
                ogTitle="Our Culture - Ugam Infotech"
                ogDescription="Discover the unique culture at Ugam Infotech. Learn about our values, work environment, and team spirit."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/company/our-culture`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Our Culture - Ugam Infotech"
                twDescription="Discover the unique culture at Ugam Infotech. Learn about our values, work environment, and team spirit."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <Box>
                <PageHeading text={"Life@Ugam"} />
                <OurCultureDesc />
                <Festival />
            </Box>
        </>
    )
}

export default OurCulture