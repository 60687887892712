import React, { useEffect } from 'react';
import PageHeading from '../../commonComponents/PageHeading';
import PageTilteSection from '../../commonComponents/PageTitleSection';
import ServicePageDesc from './Components/ServicePageDesc';
import ServiceFullstack from './Components/ServiceFullstack';
import ServiceHtmlCss from './Components/ServiceHtmlCss';
import ServiceJavascript from './Components/ServiceJavascript';
import ServiceJQuery from './Components/ServiceJQuery';
import ServiceBackend from './Components/ServiceBackend';
import ServicePhpDevelopment from './Components/ServicePhpDevelopment';
import ServiceReactJs from './Components/ServiceReactJs';
import ServiceFrontEnd from './Components/ServiceFrontEnd';
import ServiceAngularWeb from './Components/ServiceAngularWeb';
import ServiceNodeJs from './Components/ServiceNodeJs';
import ServiceCodeIgniter from './Components/ServiceCodeIgniter';
import ServiceLaravel from './Components/ServiceLaravel';
import ServiceOtherCommon from '../Common/ServiceOtherCommon';
import ExpertiseTechnology from '../../Home/components/ExpertiseTechnology';
import SEO from '../../SEO';

const WebDesignDevelopment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SEO
                title="Web Design & Development - Ugam Infotech"
                description="Explore our web design and development services at Ugam Infotech. We specialize in creating responsive and innovative websites using React.js, Node.js, and more."
                keywords="Ugam, Ugam Infotech, web design, web development, React.js, Node.js, responsive design, innovative websites, frontend, backend"
                canonical={`${process.env.REACT_APP_URL}/services/web-design-development`}
                ogTitle="Web Design & Development - Ugam Infotech"
                ogDescription="Explore our web design and development services at Ugam Infotech. We specialize in creating responsive and innovative websites using React.js, Node.js, and more."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/services/web-design-development`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Web Design & Development - Ugam Infotech"
                twDescription="Explore our web design and development services at Ugam Infotech. We specialize in creating responsive and innovative websites using React.js, Node.js, and more."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={"Web Design & Development"} />
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/web-developemnt-header-image.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'Web Design & Development'} fontSize={'39px'}  textAlign={'center'}/>
            <ServicePageDesc />
            <ExpertiseTechnology />
            <ServiceFullstack />
            <ServiceFrontEnd />
            <ServiceHtmlCss />
            <ServiceJavascript />
            <ServiceJQuery />
            <ServiceBackend />
            <ServiceAngularWeb />
            <ServiceNodeJs />
            <ServiceReactJs />
            <ServiceCodeIgniter />
            <ServicePhpDevelopment />
            <ServiceLaravel />
            <ServiceOtherCommon />
        </>
    )
}

export default WebDesignDevelopment