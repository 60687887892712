import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { CommonHeading, Heading72px } from '../../../CustomStyle/Common'
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { useNavigate } from 'react-router-dom';


const OurExpertisePart = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const themeColor = localStorage.getItem('theme');
    const data = [
        {
            icon: themeColor == 'light' ?
                `${process.env.REACT_APP_URL}/images/our-expertise-icons/2022-11-web-design-new.svg` :
                `${process.env.REACT_APP_URL}/images/our-expertise-icons/2022-11-web-design-new-svg-dark.svg`,
            title: 'Web Design & Development',
            arrowIcon: NorthEastIcon,
            border: {
                borderBottom: '1px solid #e1e1e1',
                borderRight: { xs: '0', sm: `1px solid #e1e1e1` },
            },
            link: '/services/web-design-development',
            name: '2022-11-web-design-new'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/our-expertise-icons/2022-11-ecommerce-development-icon-new.svg`,
            title: 'eCommerce Development',
            arrowIcon: NorthEastIcon,
            border: {
                borderBottom: '1px solid #e1e1e1',
                borderRight: { sm: '1px solid #e1e1e1' },
            },
            link: '/services/ecommerce-and-cms',
            name: '2022-11-ecommerce-development-icon-new'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/our-expertise-icons/2022-11-mobile-app-development-new.svg`,
            title: 'Mobile App Development',
            arrowIcon: NorthEastIcon,
            border: {
                borderBottom: '1px solid #e1e1e1',
                borderRight: 0,
            },
            link: '',
            name: '2022-11-mobile-app-development-new'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/our-expertise-icons/2022-11-graphic-design-new.svg`,
            title: 'Creating Design Solution',
            arrowIcon: NorthEastIcon,
            border: {
                borderRight: { sm: '1px solid #e1e1e1' },
                borderBottom: { xs: '1px solid #e1e1e1', sm: '0' }
            },
            link: '/services/design',
            name: '2022-11-graphic-design-new'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/our-expertise-icons/2022-11-animation-new.svg`,
            title: 'Animation',
            arrowIcon: NorthEastIcon,
            border: {
                borderRight: { xs: '0', sm: '1px solid #e1e1e1' },
                borderBottom: { xs: '1px solid #e1e1e1', sm: '0' }
            },
            link: '/services/animation',
            name: '2022-11-animation-new'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/our-expertise-icons/2022-11-digital-marketing-new.svg`,
            title: 'Digital Solution',
            arrowIcon: NorthEastIcon,
            border: {
                borderBottom: '0px solid #e1e1e1',
                borderRight: '0px solid #e1e1e1',
            },
            link: '/services/digital-marketing-and-contentSolutions',
            name: '2022-11-digital-marketing-new'
        }
    ]
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { lg: 16, md: 10, sm: 8, xs: 5 } }}>
            <Container maxWidth='lg'>
                <Box sx={{ textAlign: 'center' }}>
                    <CommonHeading text={'Our Expertise'} color={theme.palette.primary.main} />
                    <Heading72px text={'With Ugam Infotech, You Can Expect the Ideal Web Solution'} color={theme.palette.primary.main} />
                </Box>
                <Box sx={{ mt: { xs: 3, lg: 8 } }}>
                    <Grid container>
                        {
                            data.map((item, index) => {
                                return (
                                    <Grid key={index} onClick={() => { navigate(item.link); window.scrollTo({ top: 0, behavior: 'smooth' }) }} item xs={12} sm={4} md={4} sx={{
                                        ...item.border, padding: { md: '84px 27px 51px 24px', xs: '50px 20px 30px 20px' }, transition: '.5s',
                                        '&:hover': {
                                            boxShadow: '0 8px 29px 0 #00000014',
                                            transition: '0.5s'
                                        },
                                        cursor: 'pointer'
                                    }}>
                                        <Box sx={{ width: { md: '44px', sm: '38px' }, height: { md: '44px', sm: '38px' }, mb: '20px' }}>
                                            <img src={item.icon} alt={item.name} loading='lazy' title={item.title} />
                                        </Box>
                                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { md: '18px', sm: '16px' }, fontWeight: 600, lineHeight: '22px' }}>{item.title}</Typography>
                                        <Box sx={{ textAlign: 'right', margin: { md: '35px 0 -20px 0', sm: '20px 0 -20px 0' } }}>
                                            <NorthEastIcon sx={{ color: theme.palette.secondary.main }} />
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default OurExpertisePart