import React, { useEffect, } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box,  Container, Divider, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';
import { alltab } from '../Json';

import Brochurecomponent from './Brochurecomponent';
import SEO from '../../SEO';



const BrochureDesign = () => {
    
    const theme = useTheme();

    let Brochurecomponent1 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure1.jpg`,
            name :'brochure1'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure2.jpg`,
            name :'brochure2'
        }
    ]

    let Brochurecomponent2 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure3.jpg`,
            name :'brochure3'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure4.jpg`,
            name :'brochure4'
        }
    ]


    let Brochurecomponent3 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure5.jpg`,
            name :'brochure5'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure6.jpg`,
            name :'brochure6'
        }
    ]


    let Brochurecomponent4 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure7.jpg`,
            name :'brochure7'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/brochure-design/brochure8.jpg`,
            name :'brochure8'
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <SEO
                title="Brochure Design - Ugam Infotech"
                description="Explore our brochure design services at Ugam Infotech. We create impactful brochures that effectively communicate your brand message and offerings."
                keywords="Ugam, Ugam Infotech, brochure design services, impactful brochures, brand message, communication design"
                canonical={`${process.env.REACT_APP_URL}/portfolio/brochure-design`}
                ogTitle="Brochure Design - Ugam Infotech"
                ogDescription="Explore our brochure design services at Ugam Infotech. We create impactful brochures that effectively communicate your brand message and offerings."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/brochure-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Brochure Design - Ugam Infotech"
                twDescription="Explore our brochure design services at Ugam Infotech. We create impactful brochures that effectively communicate your brand message and offerings."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />

            
            <PageHeading text={'Brochure Design'} />
            <Box sx={{ overflow: 'hidden', width: '100%', backgroundColor: theme.palette.background.lightDark }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "14px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' } }}>Brochure design services encompass the creation of printed marketing materials that deliver detailed information about a business, product, service, or event. Brochures usually consist of multiple pages and are crafted to be visually attractive and reader-friendly.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{
                            display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}} >
                                        {
                                            item.text == "Brochure Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark,
                                                }} aria-label={item.arialable} title={item.text} rel='follow'
                                                >{item.text}</Link>
                                        }

                                        {index < alltab.length  && !index == 0 && (
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ margin: '50px 0px 140px' }}>
                            <Brochurecomponent allbrochureimage={Brochurecomponent1} />
                            <Brochurecomponent allbrochureimage={Brochurecomponent2} />
                            <Brochurecomponent allbrochureimage={Brochurecomponent3} />
                            <Brochurecomponent allbrochureimage={Brochurecomponent4} />
                        </Box>
                    </Box>

                </Container >
            </Box>
        </>
    )
}

export default BrochureDesign