import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box,  Container, Divider, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';
import Tshirtcomponent from './Tshirtcomponent';
import { alltab } from '../Json';
import SEO from '../../SEO';


const TshirtDesign = () => {
    const theme = useTheme();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    let Tshirtcomponet1 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt1.png`,
            name:'tshirt1'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt2.png`,
            name:'tshirt2'
        }
    ]

    let Tshirtcomponet2 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt3.png`,
            name:'tshirt3'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt4.png`,
            name:'tshirt4'
        }
    ]


    let Tshirtcomponet3 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt5.png`,
            name:'tshirt5'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt6.png`,
            name:'tshirt6'
        }
    ]


    let Tshirtcomponet4 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt7.png`,
            name:'tshirt7'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt8.png`,
            name:'tshirt8'
        }
    ]
    let Tshirtcomponet5 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt9.png`,
            name:'tshirt9'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt10.png`,
            name:'tshirt10'
        }
    ]
    let Tshirtcomponet6 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt11.png`,
            name:'tshirt11'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/tshirt-design/tshirt12.png`,
            name:'tshirt12'
        }
    ]



    return (
        <>
            <SEO
                title="T-Shirt Design - Ugam Infotech"
                description="Explore our T-shirt design services at Ugam Infotech. We create custom and trendy designs to help you stand out."
                keywords="Ugam, Ugam Infotech, T-shirt design services, custom designs, trendy designs, fashion design"
                canonical={`${process.env.REACT_APP_URL}/portfolio/tshirt-design`}
                ogTitle="Flyer / Pamphlet Design - Ugam Infotech"
                ogDescription="Explore our T-shirt design services at Ugam Infotech. We create custom and trendy designs to help you stand out."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/tshirt-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Flyer / Pamphlet Design - Ugam Infotech"
                twDescription="Explore our T-shirt design services at Ugam Infotech. We create custom and trendy designs to help you stand out."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'T-shirt Design'} />
            <Box sx={{ overflow: 'hidden', width: '100%', backgroundColor: theme.palette.background.lightDark }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "14px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' } }}>Our graphic design team at Ugam Infotech has extensive experience in offering T-shirt design services, which involve creating custom designs that can be printed on t-shirts for individuals, groups, or businesses. T-shirt designs can serve various purposes, including personal fashion statements, event promotions, and marketing campaigns.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{
                            display: 'flex', justifyContent: 'center', flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                        {
                                            item.text == "T-shirt Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main,
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark,
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                        }

                                        {index < alltab.length  && !index == 0 && (
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ margin: '50px 0px 140px' }}>
                            <Tshirtcomponent alltshirtimage={Tshirtcomponet1} />
                            <Tshirtcomponent alltshirtimage={Tshirtcomponet2} />
                            <Tshirtcomponent alltshirtimage={Tshirtcomponet3} />
                            <Tshirtcomponent alltshirtimage={Tshirtcomponet4} />
                            <Tshirtcomponent alltshirtimage={Tshirtcomponet5} />
                            <Tshirtcomponent alltshirtimage={Tshirtcomponet6} />
                        </Box>
                    </Box>

                </Container >
            </Box>
        </>
    )
}

export default TshirtDesign