import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import PageTilteSection from '../../../../commonComponents/PageTitleSection'
import { ArrowCommon } from '../../../Common'


const ServiceReactJs = () => {
    const theme = useTheme();
    const arr = [
        'Tailored React.js Solutions',
        'Transition to React',
        'Technical Enhancements',
        'App Updates',
        'UI/UX Development',
    ]
    return (
        <>
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/react-js-image.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'React Js'} />
            <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { md: 10, xs: 4 } }}>
                <Container maxWidth="xl">
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        At Ugam Infotech, our team of ReactJS developers <Box component='span' sx={{ fontWeight: 700 }}>crafts versatile applications</Box> that empower clients with a competitive edge. We leverage the foundational components of this advanced frontend framework and employ strategic methodologies to identify crucial elements of client products, making timely adjustments from their perspective.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 5, md: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        <Box component='span' sx={{ fontWeight: 700 }}>Ugam Infotech</Box> prioritizes essential components, toolkits, and React API libraries necessary to develop the smoothest, interactive, and lightweight applications for users.
                    </Typography>
                    <Box sx={{ mt: { lg: 5, md: 4, sm: 3, xs: 2 } }}>
                        <Box sx={{ p: { lg: 8, md: 6, sm: 4, xs: 3 }, borderRadius: 6, border: `1px solid ${theme.palette.primary.light}` }}>
                            <Grid container spacing={{ lg: 8, md: 6, sm: 4, xs: 2 }}>
                                <Grid item lg={4} md={5} sm={6} xs={12}>
                                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: { lg: '26px', md: '24px', sm: '22px', xs: '20px' }, fontWeight: 600, lineHeight: { lg: '46px', md: '42px', xs: '32px' } }}>
                                        Our team took the initiative to meet their demands while offering the following services:
                                    </Typography>
                                </Grid>
                                <Grid item lg={8} md={7} sm={6} xs={12}>
                                    <Grid container spacing={2}>
                                        {
                                            arr.map((item, i) => (
                                                <Grid item lg={4} md={6} xs={12} key={i} >
                                                    <ArrowCommon text={item} color={theme.palette.primary.main} backgroundColor={theme.palette.background.default} />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default ServiceReactJs