
import { Box, Divider, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import WOW from 'wowjs';
import 'animate.css';


const Tshirtcomponent = ({ alltshirtimage }) => {


    const theme = useTheme();
    useEffect(() => {
        new WOW.WOW().init();
    }, [])

    return (
        <>
            <Box sx={{ margin: '30px 0px' }}>
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',

                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            background: theme.palette.background.default,
                            height: '60%',
                            width: { lg: '80%', xs: '100%' },
                            right: { lg: '10%', md: '0%', sm: '0%' },
                            top: '49%',
                            zIndex: '-1',
                            transform: 'translateY(-50%)',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            borderRadius: '10px',
                            display: { sm: 'block', xs: 'none' }
                        },

                    }}
                >
                    <Box sx={{
                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `6px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: { lg: '76%', md: '90%', sm: '96%' },
                            right: { lg: '12%', md: '5%', sm: '2%' },
                            top: '18%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        '&::before': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `8px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: { lg: '76%', md: '90%', sm: '96%' },
                            right: { lg: '12%', md: '5%', sm: '2%' },
                            bottom: '20%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        display: 'flex',
                        justifyContent: 'center'

                    }}>
                        <Box sx={{ padding: { md: '26px 30px', xs: '20px' }, width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: { lg: '80%', md: '96%', xs: '100%' }, display: 'flex', flexWrap: 'wrap', padding: { sm: '10px', xs: '0px' } }}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', margin: { sm: '10px 0px', xs: '0px' }, flexWrap: { md: 'nowrap', xs: 'wrap' } }}>
                                    {
                                        alltshirtimage?.map((item , index) => (
                                            <Box key={index}>
                                                {
                                                    item.img1 ? <Box className="wow animate__animated animate__pulse" sx={{ width: { lg: '430px', md: '390px', sm: '300px', xs: '100%' }, height: { lg: '630px', md: '550px', sm: '480px' }, marginTop: '10px' }}>
                                                        <img src={item.img1} width='100%' height='100%' alt={item.name} loading='lazy' title='Creative T-shirt Design' />
                                                    </Box> :
                                                        <Box className="wow animate__animated animate__pulse" sx={{ width: { lg: '430px', md: '390px', sm: '300px' }, height: { lg: '630px', md: '550px', sm: '480px' }, marginTop: '10px' }}>
                                                            <img src={item.img2} width='100%' height='100%' alt={item.name} loading='lazy' title='Creative T-shirt Design' />
                                                        </Box>
                                                }

                                            </Box>
                                        ))
                                    }
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </>
    )
}

export default Tshirtcomponent