import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ServiceHeading } from '..';
import { Link } from 'react-router-dom';

const ServiceOtherCommon = () => {
    const theme = useTheme();

    const arr = [
        {
            name: 'WebDesign & Development',
            icon: <ArrowRightAltIcon sx={{ color: theme.palette.secondary.main, fontSize: { lg: '30px' } }} />,
            link: '/services/web-design-development',
            arialable: 'Read more about Web Design and Development'
        },
        {
            name: 'Ecommerce & CMS',
            icon: <ArrowRightAltIcon sx={{ color: theme.palette.secondary.main, fontSize: { lg: '30px' } }} />,
            link: '/services/ecommerce-and-cms',
            arialable: 'Read more about Ecommerce & CMS'
        },
        {
            name: 'Designing',
            icon: <ArrowRightAltIcon sx={{ color: theme.palette.secondary.main, fontSize: { lg: '30px' } }} />,
            link: '/services/design',
            arialable: 'Read more about Designing'
        },
        {
            name: 'Animation',
            icon: <ArrowRightAltIcon sx={{ color: theme.palette.secondary.main, fontSize: { lg: '30px' } }} />,
            link: '/services/animation',
            arialable: 'Read more about Animation'
        },
        {
            name: 'Digital Marketing & Content Solutions',
            icon: <ArrowRightAltIcon sx={{ color: theme.palette.secondary.main, fontSize: { lg: '30px' } }} />,
            link: '/services/digital-marketing-and-contentSolutions',
            arialable: 'Read more about Digital Marketing & Content Solutions'
        },
    ]


    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pt: { lg: 10, xs: 8 }, pb: { lg: 16, md: 14, sm: 12, xs: 10 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ textAlign: 'center' }}>
                    <ServiceHeading text={'Other Services'} />
                </Box>
                <Box sx={{ display: { md: 'flex', xs: 'block' }, alignItems: 'center', justifyContent: 'center', flexWrap: { lg: 'nowrap', md: 'wrap' }, gap: 6, mt: { lg: 8, md: 6, sm: 4, xs: 3 }, px: { lg: 4, md: 0, sm: 4, xs: 3 } }}>
                    {
                        arr.map((item, i) => (
                            <Link key={i} to={item.link} style={{ textDecoration: 'none' }} aria-label={item.arialable} title={item.name} rel='follow'>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'start', xs: 'space-between' }, gap: 8, position: 'relative', height: '50px', my: { md: 0, xs: 5 } }}>
                                    <Box sx={{ position: 'absolute', top: -10, left: -20, width: '70px', borderRadius: '50%', height: '70px', border: `2px solid ${theme.palette.primary.light}`, opacity: .4 }}></Box>
                                    <Typography sx={{ fontSize: { xl: '20px', md: '18px', sm: '16px', xs: '14px' }, fontWeight: 600, fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main }}>
                                        {item.name}
                                    </Typography>
                                    {item.icon}
                                </Box>
                            </Link>
                        ))
                    }

                </Box>
            </Container>
        </Box>
    )
}

export default ServiceOtherCommon