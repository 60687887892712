import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom'

function Festival() {
    const theme = useTheme();

    const FestivalData = [
        {
            title: "Blessing Ceremony at the Grand Opening of ugam Infotech's New Office 🥰",
            Desc: [
                "The grand opening of our new office at Ugam Infotech was not only a celebration of our growth and achievements but also a deeply spiritual event marked by a sacred blessing ceremony performed by <b> esteemed monks and saints </b>.",
                "The space was elegantly prepared for the ceremony, adorned with flowers, incense, and traditional decorations that reflected our respect for the sacred practices.",
                "At the heart of the ceremony were the monks and saints, who had graciously accepted our invitation to <b> bless the new office. they brought a sense of peace and spirituality to the event. </b>",
                "We are profoundly grateful for the monks and saints who honored us with their presence and for everyone who joined us to celebrate this significant milestone. 🙏🏻",
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/3.jpg`,
            Float: 'left'
        },
        {
            title: '🥳🎈Grand Opening of the New Ugam Infotech Office! 🎈🥳',
            Desc: [
                "<b>The grand opening of our new office</b> was a landmark event, marking a significant milestone in Ugam Infotech's journey ! ",
                "Upon arrival, guests were warmly greeted by our welcoming committee. To set the tone for the celebration, <b>each guest received a beautiful bouquet of fresh flowers</b>. The fragrant blooms, carefully selected and artfully arranged, added a touch of elegance and festivity to the occasion.",
                "The main event took place in the spacious, beautifully decorated atrium. The atmosphere was vibrant, with music playing softly in the background and the buzz of engaging conversations filling the air.",
                "The grand opening of the new office was not just a celebration of a physical space, but a testament to Ugam Infotech's <b>growth, innovation, and commitment to excellence</b>. It was a day filled with joy, camaraderie, and a shared vision for a prosperous future.",
                "🙏🏻 Thank you to everyone who joined us and made the opening of our new office a truly memorable event !"
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/2.jpg`,
            Float: 'right'
        },
        {
            title: '🎉🥳 Celebrating Birthday at Ugam Infotech 🥳🎉',
            Desc: [
                "Today was a special day at Ugam Infotech as we gathered to <b>celebrate the birthday</b> of one of our esteemed colleagues, <b>Vishal</b>. 🎂",
                "The excitement in the air was palpable as the team came together to honor and appreciate Vishal’s contributions and share in his joy on this wonderful occasion.",
                "As a token of our appreciation, we presented Vishal with a thoughtful gift – a gesture to show how much he means to the team and how grateful we are to have him as a colleague.",
                "Here’s to many more years of success, happiness, and shared celebrations. 🎊"
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/1.jpg`,
            Float: 'left'
        },
        {
            title: '🍲 Ugam Infotech Team Visit to Sondamitha Halapati Ashramshal 🥘',
            Desc: [
                "In the spirit of giving back to the community, our team at Ugam Infotech recently <b>visited Sondamitha Halapati Ashramshal to spend time with the children and provide them with a hearty meal</b>. The visit was a heartwarming experience, filled with joy, laughter, and a sense of shared humanity.",
                "As we arrived at the orphanage, we were greeted with eager smiles and curious faces. The children’s excitement was palpable, and it was clear that our visit was a welcome change in their daily routine. We were warmly welcomed by the orphanage staff, who <b>introduced us to the children and gave us a brief tour of the facility</b>.",
                "The main event of the day was a special meal that we had prepared for the children. Our team had put in great effort to ensure the menu was both nutritious and delicious. <b>We wanted to make sure that every child found something they loved</b>.",
                "Our team members assisted in <b>serving the food, making sure that each child received a generous helping</b>. Seeing the children’s faces light up as they enjoyed their meal was the highlight of the visit. The laughter and chatter around the tables created a warm and joyful atmosphere.",
                "We look forward to continuing our relationship with the orphanage and finding more ways to contribute to the well-being and happiness of these wonderful children. 😊"
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/4.jpg`,
            Float: 'right'
        },
        {
            title: "🎉 Celebrating Birthday at Ugam Infotech🎉",
            Desc: [
                "Today, our office was buzzing with excitement as we <b>celebrated the birthday</b> of our wonderful developer, <b>Parth!</b> The atmosphere was filled with joy and laughter as we came together to honor Parth on his special day.",
                "As the clock struck the designated hour, everyone gathered in the break room to surprise Parth. The look of joy and surprise on his face as he walked in was priceless.",
                "In addition to <b>the cake, a variety of delicious snacks and refreshments</b> were laid out for everyone to enjoy. There were savory bites, sweet treats, and refreshing drinks, catering to all tastes and preferences.",
                "As a token of our gratitude, we presented Parth with a thoughtful gift, carefully chosen to reflect his interests and hobbies.",
                "It was a day filled with joy, laughter, and heartfelt moments. Happy Birthday, Parth! Here’s to many more years of success, happiness, and shared celebrations. 🎈🎉"
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/5.jpg`,
            Float: 'left'
        },
        {
            title: '🌿 A Day at the Lakeview Garden with Ugam Infotech 🌿',
            Desc: [
                "Our team at Ugam Infotech recently <b>enjoyed a refreshing and fun-filled day</b> at the botanical garden! allowing us to bond, relax, and enjoy the beauty of nature together.",
                "After a leisurely stroll through the beautiful pathways, admiring the diverse flora and fauna, we gathered in an open area designated for our activities. <b>The space was perfect for the games and fun we had planned.</b>",
                "To cap off the day, we enjoyed a <b>picnic lunch</b> in a shaded area of the garden. The delicious food and beautiful setting made for a perfect end to our outing. Everyone enjoyed sharing stories, laughing, and simply relaxing together.",
                "Thank you to everyone who participated and made this day so memorable! 🌿🌞",
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/6.jpg`,
            Float: 'right'
        },
        {
            title: "🎂 Birthday Celebrating 🎂",
            Desc: [
                "We had the pleasure of <b>celebrating the birthday</b> of one of our talented developers, <b>Raj</b>, at the beautiful Weekend Address Hotel. It was an evening filled with joy, laughter, and heartfelt moments as we gathered to honor Raj on their special day.",
                "The celebration took place in one of the hotel’s elegant banquet halls, which was beautifully decorated. The ambiance was perfect, creating a warm and festive atmosphere for the occasion.As Raj entered the room, they were greeted with enthusiastic cheers and applause from colleagues and friends. <b>The surprise and happiness on their face set the tone for a fantastic evening ahead.the hotel provided a lavish buffet 🍽️ featuring a variety of delicious dishes</b>. The food was not only delectable but also beautifully presented, adding to the overall celebration experience. we presented Raj with a thoughtful gift  from the team. The gift was carefully chosen to reflect their interests and hobbies, making it a meaningful and memorable token of our appreciation.",
                "Happy Birthday, Raj! Here’s to many more years of success, happiness, and shared celebrations. 🎈🥳"
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/7.jpg`,
            Float: 'left'
        },
        {
            title: "🎉 Celebrating Our Owner's Birthday at Ugam Infotech 🎉",
            Desc: [
                "This is truly special day at Ugam Infotech as we gathered to <b>celebrate the birthday of Dharmikbhai</b>. The event was a grand affair, filled with joy, gratitude, as we honored the visionary leader who has guided us to success.",
                "The celebration took place in the beautifully decorated main hall of our office. The space was adorned with elegant decorations. The ambiance was both festive and sophisticated, reflecting the significance of the occasion. As Dharmikbhai entered the hall, they were greeted with a standing ovation and enthusiastic cheers from the entire team. <b>The surprise and happiness on their face were priceless</b>, setting a perfect tone for the evening. As a token of our appreciation, we presented Dharmikbhai with a special gift. The gift was thoughtfully chosen to reflect their interests and to commemorate this milestone. The smile on Dharmikbhai's face as they received the gift was a highlight of the celebration.<b>The birthday celebration for our owner was not just an occasion to enjoy and have fun</b>, but also an opportunity to express our gratitude and appreciation for their leadership and vision.",
                "Happy Birthday, Dharmikbhai! Here’s to many more years of success, innovation, and shared celebrations. 🎈🥳"
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/8.jpg`,
            Float: 'right'
        },
        {
            title: "💻Guidance Seminar at Ugam Infotech👏",
            Desc: [
                'Our esteemed leader and visionary, conducted an insightful guidance seminar at Ugam Infotech.',
                "The seminar took place in the spacious conference room, which was set up to facilitate an engaging and interactive session. <b>Dharmikbhai began by sharing their personal journey and experiences</b>, emphasizing key lessons learned throughout their career. Their words resonated deeply with everyone present, setting a motivational tone for the seminar. Throughout the session, <b>Dharmikbhai covered a wide range of topics relevant to our company's growth and development</b>.The guidance seminar by Dharmikbhai was a transformative experience for everyone at Ugam Infotech, leaving us motivated and energized to achieve new heights of success.It underscored our owner's commitment to leadership excellence and their unwavering belief in the potential of our team.",
                "We look forward to applying the valuable insights gained from the seminar as we continue on our journey of growth and excellence under Dharmikbhai's visionary guidance."
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/9.jpg`,
            Float: 'left'
        },
        {
            title: "🍽 Company Dinner🍝",
            Desc: [
                "Our company treated us to a delightful evening at Hotel, where we enjoyed a wonderful dinner together.It was a special occasion <b>filled with good food, camaraderie</b>, and a chance to unwind and celebrate our collective achievements.",
                "The company dinner at Hotel was not just a meal; it was a gesture of appreciation and a celebration of our team’s accomplishments. 🍽️🌟"
            ],
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/13.jpg`,
            Float: 'right'
        },        
    ]

    return (
        <Box sx={{ overflow: 'hidden' }}>
            {
                FestivalData.map((item, i) => (
                    <Box key={i} sx={{ backgroundColor: i % 2 == 0 ? theme.palette.background.default : theme.palette.background.lightDark }}>
                        <Container maxWidth='xl' sx={{ display: 'flex' }} >
                            <Box sx={{ py: 5, width: '100%', position: 'relative' }}>
                                <Typography sx={{ textAlign: "center", mb: 6, fontSize: { sm: '36px', xs: '18px' }, lineHeight: { sm: '54px', xs: '38px' }, fontWeight: 700, color: theme.palette.secondary.main, fontFamily: theme.palette.typography.fontFamily }} >{item.title}</Typography>
                                <Box sx={{
                                    animation: 'circularMotion 8s linear infinite',
                                    content: '""',
                                    // borderRadius: '50%',
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    zIndex: 0,
                                    height: { sm: '500px', xs: '360px' },
                                    width: { sm: '500px', xs: '360px' },
                                    position: 'absolute',
                                    top: { md: 100, sm: 220, xs: 190 },
                                    left: i % 2 == 0 ? { sm: -250, xs: -190 } : '',
                                    right: i % 2 == 1 ? { sm: -250, xs: -190 } : '',
                                    opacity: .5
                                }}>
                                </Box>
                                <Box sx={{
                                    animation: 'circularMotion 8s linear infinite',
                                    content: '""',
                                    // borderRadius: '50%',
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    zIndex: 0,
                                    height: { sm: '500px', xs: '360px' },
                                    width: { sm: '500px', xs: '360px' },
                                    position: 'absolute',
                                    top: { md: 100, sm: 230, xs: 180 },
                                    left: i % 2 == 0 ? { sm: -180, xs: -150 } : '',
                                    right: i % 2 == 1 ? { sm: -180, xs: -150 } : '',
                                    opacity: .5
                                }}>
                                </Box>
                                <Box sx={{ maxWidth: { lg: '560px', md: '400px' }, p: { md: 0, sm: 5 }, float: item.Float == 'left' ? { md: 'left', sm: 'center' } : { md: 'right', sm: 'center' }, mr: { md: 5, sm: 0 }, position: 'relative' }}>
                                    <img width='100%' height='100%' src={item.image} alt={item.name} title={item.title} loading='lazy' />
                                </Box>
                                <Box >
                                    {
                                        item.Desc.map((des, i) => (
                                            <Typography key={i} sx={{ py: { lg: 3, md: 1.5, sm: 2 }, color: theme.palette.primary.main, fontSize: { sm: '18px', xs: '16px' }, lineHeight: {md:'42px', sm: '34px', xs: '28px' }, fontFamily: theme.palette.typography.fontFamily }}dangerouslySetInnerHTML={{__html: des}}></Typography>
                                        ))
                                    }
                                    <Box sx={{ width: '100%' }}>
                                        <Link to='https://www.facebook.com/ugaminfotech' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Facebook' aria-label="Visit our Facebook page"><FacebookIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} /></Link>
                                        <Link to='https://www.linkedin.com/company/ugam-infotech' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Linkedin' aria-label="Visit our Linkedin page"><LinkedInIcon sx={{ fontSize: '30px', ml: 2, color: theme.palette.primary.main }} /></Link>
                                        <Link to='https://www.instagram.com/ugaminfotech/' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Instagram' aria-label="Visit our Instagram page"><InstagramIcon sx={{ fontSize: '30px', ml: 2, color: theme.palette.primary.main }} /></Link>
                                    </Box>
                                </Box>

                            </Box>
                        </Container>
                    </Box>
                ))
            }

        </Box>

    )
}

export default Festival