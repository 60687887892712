import './App.css';
import Layout from './layout';
import Home from './components/Home';
import { Route, Routes } from 'react-router-dom';
import { Loader } from './components/CustomStyle/Common';
import { useEffect, useState } from 'react';
import AboutUs from './components/Company/AboutUs';
import Careers from './components/Company/Careers';
import OurCulture from './components/Company/Our Culture';
import ContactUs from './components/Company/ContactUs';
import WebDesignDevelopment from './components/Services/WebDesignDevelopment';
import EcommerceAndCMS from './components/Services/EcommerceAndCMS';
import DigitalMarketingAndContentSolutions from './components/Services/DigitalMarketing&ContentSolutions';
import Design from './components/Services/Designing';
import Animation from './components/Services/Animation';
import WebsiteDesign from './components/Portfolio/WebsiteDesign';
import GraphicDesign from './components/Portfolio/GraphicDesign';
import LogoDesign from './components/Portfolio/LogoDesign';
import BrochureDesign from './components/Portfolio/BrochureDesign';
import BrandDesign from './components/Portfolio/BrandDesign';
import BusinessCardDesign from './components/Portfolio/BusinessCardDesign';
import SocialMediaDesign from './components/Portfolio/SocialMediaDesign';
import PamphletDesign from './components/Portfolio/PamphletDesign';
import TshirtDesign from './components/Portfolio/TshirtDesign';
import Wordpress from './components/Portfolio/WebsiteDesign/Components/Wordpress';
import Shopify from './components/Portfolio/WebsiteDesign/Components/Shopify';
import Laravel from './components/Portfolio/WebsiteDesign/Components/Laravel';
import CodeIgniter from './components/Portfolio/WebsiteDesign/Components/CodeIgniter';
import ReactPage from './components/Portfolio/WebsiteDesign/Components/ReactPage';
import ToptoBottom from './components/commonComponents/ToptoBottom';
import Pagenotfound from './components/Pagenotfound';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
    scrollFun();
  }, [])

  const scrollFun = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        })
        .catch((error) => {
          console.error('ServiceWorker registration failed: ', error);
        });
    });
  }

  return (
    <>
      <Layout>
        <ToptoBottom />
        {
          loading ?
            <Loader loading={true} /> :
            <Routes>
              <Route path="/" element={<Home />} />
              {/* company */}
              <Route path="/company/about" element={<AboutUs />} />
              <Route path="/company/careers" element={<Careers />} />
              <Route path="/company/our-culture" element={<OurCulture />} />
              <Route path="/contact-us" element={<ContactUs />} />
              {/* service */}
              <Route path="/services/web-design-development" element={<WebDesignDevelopment />} />
              <Route path="/services/ecommerce-and-cms" element={<EcommerceAndCMS />} />
              <Route path="/services/digital-marketing-and-contentSolutions" element={<DigitalMarketingAndContentSolutions />} />
              <Route path="/services/animation" element={<Animation />} />
              <Route path="/services/design" element={<Design />} />
              {/* portfolio */}
              <Route path="/portfolio/website-design" element={<WebsiteDesign />} />
              <Route path="/portfolio/graphic-design" element={<GraphicDesign />} />
              <Route path="/portfolio/brochure-design" element={<BrochureDesign />} />
              <Route path="/portfolio/brandidentity-design" element={<BrandDesign />} />
              <Route path="/portfolio/logo-design" element={<LogoDesign />} />
              <Route path="/portfolio/business-card-design" element={<BusinessCardDesign />} />
              <Route path="/portfolio/social-media-design" element={<SocialMediaDesign />} />
              <Route path="/portfolio/pamphlet-design" element={<PamphletDesign />} />
              <Route path="/portfolio/tshirt-design" element={<TshirtDesign />} />
              <Route path="/portfolio/website-design/wordpress" element={<Wordpress />} />
              <Route path="/portfolio/website-design/shopify" element={<Shopify />} />
              <Route path="/portfolio/website-design/laravel" element={<Laravel />} />
              <Route path="/portfolio/website-design/codeIgniter" element={<CodeIgniter />} />
              <Route path="/portfolio/website-design/react" element={<ReactPage />} />
              {/* page not found */}
              <Route path='/404-page' element={<Pagenotfound />} />
            </Routes>
        }
      </Layout>
    </>
  );
}

export default App;
