import React from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Container, Grid, Link, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material'
import { CommonHeading, Heading72px } from '../../../CustomStyle/Common'

const WeAreTechComny = () => {
    const theme = useTheme();
    const serviceData = [{
        name: 'Knowledge Hub',
        dot: false
    }, {
        name: 'Educational Environment',
        dot: true
    }, {
        name: 'Growth Space',
        dot: true
    }, {
        name: 'Intellectual Climate',
        dot: true
    }]
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 16, md: 10, sm: 8, xs: 5 } }}>
            <Container maxWidth='xl' >
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={5} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <CommonHeading text={'We are a'} color={theme.palette.primary.main} />
                        <Heading72px text={'Tech Company'} color={theme.palette.primary.main} />
                    </Grid>
                    <Grid item lg={0.5}></Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6.5} sx={{ mt: { sm: '20px', md: 0 } }}>
                        <Box sx={{
                            fontFamily: theme.palette.typography.fontFamily,
                            color: theme.palette.primary.main,
                            fontSize: { xs: '16px', lg: '21px' },
                            lineHeight: { xs: '32px', lg: '48px' },
                            textAlign: { xs: 'center', md: 'left' }
                        }}>Ugam Infotech has partnered with numerous businesses and organizations to deliver prompt
                            <Typography variant='span' sx={{fontWeight:'bold', textDecoration: 'none', color: theme.palette.secondary.main }}> services </Typography>
                            and web solutions, empowering clients to swiftly adapt and unlock new business opportunities.
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ pt: { lg: 16, md: 10, sm: 8, xs: 5 }, display: { sm: 'flex', xs: 'block' }, flexWrap: 'wrap', justifyContent: { sm: 'center', xs: 'start', transition: 'all .3s ease-in-out' } }}>
                    {serviceData.map((val, i) =>
                        <Box key={i} sx={{
                            textAlign: { lg: 'center', sm: 'start' }, position: 'relative', transition: 'all .1s ease-in-out'
                        }}>
                            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                                {
                                    val && val.dot && (
                                        <Box sx={{
                                            '&::after': {
                                                content: '""',
                                                left: '0%',
                                                position: 'absolute',
                                                top: '40%',
                                                backgroundColor: theme.palette.secondary.main,
                                                borderRadius: '50%',
                                                height: '8px',
                                                width: '8px',
                                            }
                                        }}></Box>
                                    )
                                }
                            </Box>
                            <Box sx={{
                                display: { sm: 'none', xs: 'block' },
                                '&::after': {
                                    content: '""',
                                    left: '0%',
                                    position: 'absolute',
                                    top: '40%',
                                    backgroundColor: '#fd035a',
                                    borderRadius: '50%',
                                    height: '8px',
                                    width: '8px',
                                }
                            }}></Box>
                            <Typography sx={{
                                fontSize: { lg: '22px', md: '20px', sm: '18px', xs: '16px' }, fontWeight: 700, position: 'relative',
                                px: { lg: 5, md: 4, xs: 3 }, color: theme.palette.primary.main, fontFamily: theme.palette.typography.fontFamily
                            }}>{val.name}</Typography>
                        </Box>
                    )
                    }
                </Box>
            </Container >
        </Box >
    )
}

export default WeAreTechComny