import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { ServiceHeading } from '../../../Common';

const WooCommerce = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' }, display: 'flex', alignItems: 'center' }}>
                    <Grid item lg={7} md={6} xs={12}>
                        <Box>
                            <ServiceHeading text='Woo-Commerce' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Top-notch WooCommerce Development Company

                                At Ugam Infotech, one of the leading <Typography variant='span' sx={{fontWeight:'bold'}}> WooCommerce development </Typography>  companies in India, we specialize in providing advanced eCommerce solutions, WooCommerce development, and plugins to enhance your online store.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>With our years of working experience we come up with the results that WooCommerce is the most widely used e-Commerce platform for online business development. Providing you with a superb selection of features and flexibility for your online shopping cart. Are you looking for WooCommerce development services? Make your eCommerce website according to user perspective with the help of engaging web development platforms like Woo-Commerce. Above all these, we have done a number of WordPress projects and received positive feedback from our clients.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={6} xs={12} sx={{
                        textAlign: 'center', width: { md: '80%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto', position: 'relative', '&::after': {
                            content: "''",
                            position: 'absolute',
                            top: 150,
                            left: { lg: -100, md: -43, sm: -58, xs: -40 },
                            width: { lg: '200px', md: '170px', sm: '150px', xs: '120px' },
                            height: { lg: '130px', md: '110px', sm: '90px', xs: '70px' },
                            backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/about.svg`})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            transform: 'rotate(270deg)',
                            zIndex: -1
                        },
                    }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/ecommerce-and-cms/woocommerce.png`} width='100%' style={{ objectFit: 'cover' }} height='auto' alt="woocommerce"  title='Online Shopping Cart Interface' loading='lazy' />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default WooCommerce