import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { ServiceHeading } from '../../../Common';

const ServicePhpDevelopment = () => {
    const theme = useTheme();

    const arr = [
        {
            text: 'Custom PHP Application Development',
            border: {
                borderRight: { sm: `1px solid ${theme.palette.primary.light}` },
                borderBottom: { md: 0, xs: `1px solid ${theme.palette.primary.light}` }
            }
        },
        {
            text: 'PHP CMS Development',
            border: {
                borderRight: { md: `1px solid ${theme.palette.primary.light}` },
                borderBottom: { md: 0, xs: `1px solid ${theme.palette.primary.light}` }
            }
        },
        {
            text: 'PHP Integration with Flash/Flex',
            border: {
                borderRight: { sm: `1px solid ${theme.palette.primary.light}` },
                borderBottom: { sm: 0, xs: `1px solid ${theme.palette.primary.light}` }
            }
        },
        {
            text: 'Custom PHP Programming',
            border: {
                borderRight: 0,
            }
        }
    ]
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={3} sx={{ flexDirection: { md: 'row', xs: 'column-reverse' } }}>
                    <Grid item lg={9} md={8} xs={12}>
                        <Box>
                            <ServiceHeading text='Php Development' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Php Development <Box component='span' sx={{ fontWeight: 600 }}>with extensive feature</Box> sets. At Ugam Infotech, leveraging our expertise, we craft a diverse array of interactive web products that inspire customer confidence.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                We provide services to both <Box component='span' sx={{ fontWeight: 600 }}>small-scale</Box> and <Box component='span' sx={{ fontWeight: 600 }}>large-scale</Box> enterprises.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                We at <Box component='span' sx={{ fontWeight: 600 }}>Ugam Infotech</Box> provide <Box component='span' sx={{ fontWeight: 600 }}>dependable, accessible, and cost-effective</Box> PHP programming services, leveraging our experienced team in PHP-based frameworks.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={3} md={4} xs={12} sx={{ textAlign: 'center', width: { md: '100%', sm: '50%', xs: '86%' }, m: '0 auto' }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/php-development-image.png`} width='100%' height='auto' alt="php-development-image" title='Building Dynamic Websites with PHP' loading='lazy' />
                    </Grid>
                </Grid>
                <Typography sx={{ fontWeight: 600, fontSize: { md: '26px', sm: '24px', xs: '22px' }, mt: { lg: 6, md: 4, sm: 3, xs: 2 }, mb: { md: 3, xs: 2 } }}>
                    We are ready to serve:
                </Typography>
                <Grid container>
                    {
                        arr.map((val, i) => (
                            <Grid item md={3} sm={6} xs={12} key={i} sx={{ p: 3, backgroundColor: theme.palette.primary.contrastText, ...val.border, textAlign: 'center' }}>
                                {val.text}
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Box>
    )
}

export default ServicePhpDevelopment