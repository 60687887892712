import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'

const AboutPageService = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pt: { lg: 10, md: 8, sm: 6, xs: 6 }, pb: { xs: 16 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ background: `url(${`${process.env.REACT_APP_URL}/images/pages/company/about-us/about-4.jpg`})`, position: 'relative', width: '100%', height: '250px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', textAlign: 'center', width: { md: '70%', sm: '80%', xs: '100%' }, borderRadius: '40px', m: '0 auto' }}>
                    <Typography variant='h3' sx={{ fontSize: { lg: '45px', md: '45px', sm: '35px', xs: '30px' }, color: '#fff', fontWeight: 700, pt: { sm: 7, xs: 5 }, px: { sm: 0, xs: 4 }, fontFamily: theme.palette.typography.fontFamily }}>Looking For Services</Typography>
                    <Box sx={{ width: { md: '420px', sm: '400px', xs: '88%' }, position: 'absolute', top: '96%', left: '50%', transform: 'translate(-50% , -50%)', boxShadow: '0 10px 30px #00000026', borderRadius: 8, background: '#fff' }}>
                        <Grid container sx={{ px: { md: 5, sm: 4, xs: 1 }, py: { md: 4, sm: 4, xs: 3 } }}>
                            <Grid item lg={3.5} md={3.5} sm={3.5} xs={12} sx={{ textAlign: { sm: 'start', xs: 'center' } }}>
                                <img src={`${process.env.REACT_APP_URL}/images/about-5.svg`} alt='about-5' loading='lazy' title='Our contact detail' width='auto' height='auto'></img>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box sx={{ fontSize: { lg: '16px', md: '16px', sm: '16px', xs: '12px' }, textAlign: { sm: 'start', xs: 'center' }, color: '#898988', fontWeight: 600, fontFamily: theme.palette.typography.fontFamily, }}>For Enquiry call now</Box>
                                <Box sx={{ color: '#000', fontSize: { lg: '25px', ms: '25px', sm: '25px', xs: '18px' }, textAlign: { sm: 'start', xs: 'center' }, fontWeight: 700, lineHeight: { sm: '3.5rem', xs: '2rem' }, fontFamily: theme.palette.typography.fontFamily }}>+91-84609 61523</Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default AboutPageService