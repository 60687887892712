import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { CommonHeading, Heading72px } from '../../../CustomStyle/Common'

const JoinOurTeam = () => {

    const theme = useTheme();

    const clientOfferdata = [
        {
            icon: `${process.env.REACT_APP_URL}/images/join-ourteam-benefits-icon/flexible-working-hours-icon.svg`,
            title: 'Flexible Working Hours',
            desc: 'Experience Flexibility with Our Tailored Working Hours for Better Balance',
            name: 'flexible-working-hours-icon'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/join-ourteam-benefits-icon/cooperative-seniors-icon.svg`,
            title: 'Cooperative Seniors',
            desc: 'Supportive and Cooperative Senior Team Members',
            name: 'cooperative-seniors-icon'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/join-ourteam-benefits-icon/birthday-cake-icon.svg`,
            title: 'Employee Birthday Celebration',
            desc: "Celebrate Our Team’s Milestones with Employee Birthday Parties!",
            name: 'birthday-cake-icon'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/join-ourteam-benefits-icon/employee-of-the-month-awards-icon.svg`,
            title: 'Employee of the Month Awards',
            desc: "Recognizing Excellence: Employee of the Month Awards Celebrate Success",
            name: 'employee-of-the-month-awards-icon'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/join-ourteam-benefits-icon/friendly-paid-leave-icon.svg`,
            title: 'Better Collaboration',
            desc: 'Enhancing Teamwork Through Better Collaboration',
            name: 'friendly-paid-leave-icon'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/join-ourteam-benefits-icon/annual-trip-and-fun-activity-icon.svg`,
            title: 'Annual Trip and Fun Activity',
            desc: 'Join Our Annual Trip and Enjoy Fun Team Activities Together!',
            name: 'annual-trip-and-fun-activity-icon'
        }
    ];
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            <Container maxWidth='xl'>
                <Grid container sx={{ padding: { xs: '40px 0', md: '60px 0', lg: '80px 0' } }}>
                    <Grid item xs={12} md={5} lg={4.6}>
                        <CommonHeading text={'Benefits'} color={theme.palette.primary.main} />
                        <Heading72px margin={'20px 0'} text={'Why You Should Join Our Team?'} color={theme.palette.primary.main} />
                        <Typography sx={{ fontSize: { xs: '16px', md: '18px' }, fontWeight: 400, color: theme.palette.primary.main, fontFamily: theme.palette.typography.fontFamily }}>
                            At Ugam Infotech, we are more than just an IT company—we are a community of forward-thinkers and innovators passionate about shaping the future of technology. Joining our team means being part of a <Typography variant='span' sx={{fontWeight:'bold'}}>dynamic and supportive environment</Typography> where your skills and ideas are valued and nurtured.
                        </Typography>
                    </Grid>
                    <Grid item md={0.5} lg={0.4}></Grid>
                    <Grid item xs={12} md={6.5} lg={7} sx={{ mt: { xs: '25px', md: '0px' } }}>
                        <Grid container sx={{ display: 'flex' }}>
                            {
                                clientOfferdata.map((item, i) => {
                                    return (
                                        <Grid item sm={6} md={6} lg={6} key={i} sx={{ mb: { xs: '20px', lg: '30px' }, textAlign: { sm: 'left', xs: 'center' }, p: { xs: '0', lg: '20px' } }}>
                                            <Box sx={{ backgroundColor: theme.palette.secondary.main, display: 'block', p: { lg: 3, sm: 2, xs: 2 }, borderRadius: '50%', width: { lg: '90px', xs: '65px' }, m: { sm: '0px', xs: '0px auto' }, height: { lg: '90px', xs: '65px' } }}>
                                                <img width="100%" src={item.icon} height="100%" alt={item.name} title={item.title} loading='lazy' />
                                            </Box>
                                            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: { xs: '20px', lg: '25px' }, fontWeight: 500, m: { sm: '28px 0 16px 0', xs: '8px 0 4px 0' } }}>{item.title}</Typography>
                                            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: { xs: '16px', lg: '18px' }, fontWeight: 400 }}>{item.desc}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default JoinOurTeam