import React from 'react'
import { Container, Grid, Link, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Heading72px } from '../../../CustomStyle/Common';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ExpertiseTechnology = () => {
    const themeColor = localStorage.getItem('theme');

    const frontEndIcons = [
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/react-icon.svg`, title: 'React.js', name: 'react-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/skill-icons_sass.svg`, title: 'Sass', name: 'skill-icons_sass' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_bootstrap.svg`, title: 'Bootstrap', name: 'logos_bootstrap' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/Javascript.svg`, title: 'Javascript', name: 'Javascript' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/vue-icon.svg`, title: 'Vue.js', name: 'vue-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/angular-icon.svg`, title: 'Angular', name: 'angular-icon' },       
        {
            icon: themeColor === 'light'
                ? `${process.env.REACT_APP_URL}/images/lenguage-icon/next-icon.svg`
                : `${process.env.REACT_APP_URL}/images/lenguage-icon/next-icon-svg-dark.svg`,
            title: 'Next.js', name: 'next-icon'
        },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/css-logo.svg`, title: 'CSS', name: 'css-logo' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/nuxt-icon.svg`, title: 'Nuxt.js', name: 'nuxt-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_html-5.svg`, title: 'HTML', name: 'logos_html-5' }
    ];

    const backEndIcons = [
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/laravel-logo.svg`, title: 'Laravel', name: 'laravel-logo' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/php-icon.svg`, title: 'PHP', name: 'php-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/node-icon.svg`, title: 'Node.js', name: 'node-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_python.svg`, title: 'Python', name: 'logos_python' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/codeigniter-icon.svg`, title: 'Codeigniter', name: 'codeigniter-icon' }
    ];

    const databaseIcons = [
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/mongodb-icon.svg`, title: 'MongoDB', name: 'mongodb-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/mysql-icon.svg`, title: 'MySQL', name: 'mysql-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/postgresql-logo.svg`, title: 'PostgreSQL', name: 'postgresql-logo' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/sqlite-icon.svg`, title: 'SQLite', name: 'sqlite-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/firebase-icon.svg`, title: 'Firebase', name: 'firebase-icon' }
    ];

    const cms_ecommerce = [
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/webflow-icon.svg`, title: 'Webflow', name: 'webflow-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/squarespace-icon.svg`, title: 'Squarespace', name: 'squarespace-icon.' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/shopify-icon.svg`, title: 'Shopify', name: 'shopify-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/wordpress-icon.svg`, title: 'WordPress', name: 'wordpress-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/magento-icon.svg`, title: 'Magento', name: 'magento-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/bagisto-icon.svg`, title: 'Bagisto', name: 'bagisto-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/woocommerce-icon.svg`, title: 'WooCommerce', name: 'woocommerce-icon' },
    ];

    const ui_ux = [
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/proto-io-icon.svg`, title: 'Proto.io', name: 'proto-io-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/axure-icon.svg`, title: 'Axure', name: 'axure-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/sketch-icon.svg`, title: 'Sketch', name: 'sketch-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/marvel-icon.svg`, title: 'Marvel', name: 'marvel-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/photoshop-icon.svg`, title: 'Photoshop', name: 'photoshop-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/illustrator-icon.svg`, title: 'Illustrator', name: 'illustrator-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/figma-icon.svg`, title: 'Figma', name: 'figma-icon' },
    ];

    const mobile = [
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/android-icon.svg`, title: 'Android', name: 'android-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/ios-icon.svg`, title: 'IOS', name: 'ios-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/react-icon.svg`, title: 'React Native', name: 'react-icon' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/flutter-icon.svg`, title: 'Flutter', name: 'flutter-icon' },
    ];


    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const tabSx = {
        '& .MuiButtonBase-root.MuiTab-root': {
            color: theme.palette.primary.main,
            fontWeight: 500,
            fontSize: { sm: '16px', md: '18px' },
            fontFamily: '"Poppins", sans-serif',
            textTransform: 'capitalize',
        },
        '& .css-heg063-MuiTabs-flexContainer': {
            display: 'block',
            overflowX: { xs: 'scroll', md: 'hidden' }
        }
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ py: { md: 8, xs: 5 } , textAlign:{md:'left' , xs:'center'} }}>
                <Heading72px color={theme.palette.primary.main} text={'Our Mastery of Tech and Platforms'} />
                <Typography sx={{
                    fontFamily: theme.palette.typography.fontFamily,
                    color: theme.palette.primary.main,
                    fontSize: { xs: '16px', lg: '21px' },
                    lineHeight: { xs: '28px', lg: '48px' , md:'35px' },
                    textAlign: { xs: 'center', md: 'left' }
                }}>
                    Our approach centers on utilizing advanced technologies that effectively tackle unique challenges, enhance performance, boost productivity, enrich user experience, and meet industry standards and compatibility requirements.
                </Typography>
                <Box sx={{ width: '100%', m: { xs: '20px 0', md: '30px 0', lg: '38px 0' } }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs variant='fullWidth' value={value} sx={tabSx} onChange={handleChange} scrollButtons="auto">
                            <Tab label="Front End" {...a11yProps(0)} />
                            <Tab label="Back End" {...a11yProps(1)} />
                            <Tab label="Database" {...a11yProps(2)} />
                            <Tab label="CMS/E-commerce" {...a11yProps(3)} />
                            <Tab label="UI/UX" {...a11yProps(4)} />
                            <Tab label="Mobile" {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Grid container gap={'28px'} sx={{ justifyContent: { xs: 'space-between', xl: 'flex-start' }, mt: '30px' }}>
                            {
                                frontEndIcons.map((val, i) => {
                                    return (
                                        <Grid key={i} item xs={3} sm={2} md={2} lg={2} xl={1.3} sx={{
                                            border: `1px solid ${theme.palette.primary.light}`,
                                            padding: { xs: '15px', md: '30px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s',
                                            '&:hover': {
                                                border: `1px solid ${theme.palette.primary.main}`,
                                                transition: '0.2s'
                                            }
                                        }}>
                                            <img src={val.icon} width='100%' height='auto' style={{ aspectRatio: '3/2', objectFit: 'contain' }} title='Frount-End language icon' loading='lazy' alt={val.name} />
                                            <Typography sx={{ textDecoration: 'none', mt: '7px', fontSize: { xs: '10px', md: '16px' } }} >{val.title}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Grid container gap={'28px'} sx={{ justifyContent: { xs: 'start', sm: 'space-between', xl: 'flex-start' }, mt: '30px' }}>
                            {
                                backEndIcons.map((val, i) => {
                                    return (
                                        <Grid key={i} item xs={3} sm={2} md={2} lg={2} xl={1.3} sx={{
                                            border: `1px solid ${theme.palette.primary.light}`,
                                            padding: { xs: '15px', md: '30px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s',
                                            '&:hover': {
                                                border: '1px solid #040201',
                                                transition: '0.2s'
                                            }
                                        }}>
                                            <img src={val.icon} width='100%' height='auto' style={{ aspectRatio: '3/2', objectFit: 'contain' }} title='Frount-End language icon' loading='lazy' alt={val.name} />
                                            <Typography sx={{ textDecoration: 'none', mt: '7px', fontSize: { xs: '10px', md: '16px' } }}>{val.title}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Grid container gap={'28px'} sx={{ justifyContent: { xs: 'start', sm: 'space-between', xl: 'flex-start' }, mt: '30px' }}>
                            {
                                databaseIcons.map((val, i) => {
                                    return (
                                        <Grid key={i} item xs={3} sm={2} md={2} lg={2} xl={1.3} sx={{
                                            border: `1px solid ${theme.palette.primary.light}`,
                                            padding: { xs: '15px', md: '30px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s',
                                            '&:hover': {
                                                border: '1px solid #040201',
                                                transition: '0.2s'
                                            }
                                        }}>
                                            <img src={val.icon} width='100%' height='auto' style={{ aspectRatio: '3/2', objectFit: 'contain' }} title='Frount-End language icon' loading='lazy' alt={val.name} />
                                            <Typography sx={{ textDecoration: 'none', mt: '7px', fontSize: { xs: '10px', md: '16px' } }}>{val.title}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <Grid container gap={'28px'} sx={{ justifyContent: { xs: 'start', sm: 'space-between', xl: 'flex-start' }, mt: '30px' }}>
                            {
                                cms_ecommerce.map((val, i) => {
                                    return (
                                        <Grid key={i} item xs={3} sm={2} md={2} lg={2} xl={1.3} sx={{
                                            border: `1px solid ${theme.palette.primary.light}`,
                                            padding: { xs: '15px', md: '30px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s',
                                            '&:hover': {
                                                border: '1px solid #040201',
                                                transition: '0.2s'
                                            }
                                        }}>
                                            <img src={val.icon} width='100%' height='auto' style={{ aspectRatio: '3/2', objectFit: 'contain' }} title='Frount-End language icon' loading='lazy' alt={val.name} />
                                            <Typography sx={{ textDecoration: 'none', mt: '7px', fontSize: { xs: '10px', md: '16px' } }}>{val.title}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <Grid container gap={'28px'} sx={{ justifyContent: { xs: 'start', sm: 'space-between', xl: 'flex-start' }, mt: '30px' }}>
                            {
                                ui_ux.map((val, i) => {
                                    return (
                                        <Grid key={i} item xs={3} sm={2} md={2} lg={2} xl={1.3} sx={{
                                            border: `1px solid ${theme.palette.primary.light}`,
                                            padding: { xs: '15px', md: '30px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s',
                                            '&:hover': {
                                                border: '1px solid #040201',
                                                transition: '0.2s'
                                            }
                                        }}>
                                            <img src={val.icon} width='100%' height='auto' style={{ aspectRatio: '3/2', objectFit: 'contain' }} title='Frount-End language icon' loading='lazy' alt={val.name} />
                                            <Typography sx={{ textDecoration: 'none', mt: '7px', fontSize: { xs: '10px', md: '16px' } }}>{val.title}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <Grid container gap={'28px'} sx={{ justifyContent: { xs: 'start', sm: 'space-between', xl: 'flex-start' }, mt: '30px' }}>
                            {
                                mobile.map((val, i) => {
                                    return (
                                        <Grid key={i} item xs={3} sm={2} md={2} lg={2} xl={1.3} sx={{
                                            border: `1px solid ${theme.palette.primary.light}`,
                                            padding: { xs: '15px', md: '30px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s',
                                            '&:hover': {
                                                border: '1px solid #040201',
                                                transition: '0.2s'
                                            }
                                        }}>
                                            <img src={val.icon} width='100%' height='auto' style={{ aspectRatio: '3/2', objectFit: 'contain' }} title='Frount-End language icon' loading='lazy' alt={val.name} />
                                            <Typography sx={{ textDecoration: 'none', mt: '7px', fontSize: { xs: '10px', md: '16px' } }}>{val.title}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CustomTabPanel>
                </Box>
            </Container>
        </>
    )
}

export default ExpertiseTechnology