import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../../../../App.css'
import { useTheme } from '@emotion/react';
import { CommonBtn } from '../../../../CustomStyle/Common';
import { Link } from 'react-router-dom';

function Award() {
    const theme = useTheme();
    const options = {
        loop: false,
        nav: true,
        arrows: 'true',
        dots: false,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }

    const slider = [
        {
            image: `${process.env.REACT_APP_URL}/images/pages/company/careers/cs1.jpg`,
            name: 'cs1'
        }
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: 2}}>
            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, textAlign: 'center', fontWeight: 800, lineHeight: '32px', fontSize: { sm: '45px', xs: '40px' }, pt:{md: 12 , xs:5}, pb:{md: 8 ,xs:5}, lineHeight: { xs: '50px' } }}>Awards & Praise</Typography>
            <Container className='Awardmain' maxWidth='lg' sx={{ mb: 6 }}>
                <Box className='AwardSlider' >
                    <OwlCarousel {...options}>
                        {
                            slider.map((item, index) => (
                                <Box key={index}>
                                    <img src={item.image} style={{ objectFit: 'contain' }} width='100%' alt={item.name} title='Achievement Award Celebration' loading='lazy' height='auto' />
                                </Box>
                            ))
                        }

                    </OwlCarousel>
                </Box>
                <Box sx={{ textAlign: 'center', p: 5, mt: 6 }}>
                    <Link to="/company/our-culture" aria-label="Read more about our culture" rel="follow" title='Check Our Culture'>
                        <CommonBtn text={'Check Our Culture'} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} />
                    </Link>
                </Box>
            </Container>
        </Box>
    )
}

export default Award