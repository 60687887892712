
import { Box, Button, CircularProgress, LinearProgress, Typography, styled } from "@mui/material"

import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { ReactTyped } from "react-typed"
import { useNavigate } from "react-router-dom";

const CommonButtonStyle = styled(Button)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.secondary.light,
    color: '#fff',
    fontWeight: 700,
    padding: '15px 28px',
    fontSize: '16px',
    textTransform: 'uppercase',
    borderRadius: '31px',
    transition: 'all .3s ease',
    position: 'relative',
    backgroundColor: '#000',
    [theme.breakpoints.down('lg')]: {
        fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
}))

export const CommonBtn = ({ text, link, handleDrawer, backgroundColor, color, name, border, borderRadious, hoverBGColor, hoverColor, width }) => {

    const navigate = useNavigate();

    const handlebuttonClick = () => {
        handleDrawer('right', false)
        navigate(link)
    }
    return (
        <Box>
            <CommonButtonStyle onClick={text == 'GROW CAREER WITH US' ? handlebuttonClick : null} sx={{
                backgroundColor: backgroundColor, color: color, border: border, borderRadius: borderRadious, '&:hover': {
                    backgroundColor: hoverBGColor, color: hoverColor,
                }
            }
            }> {text} {name == 'Add' ? (<AddIcon />) : name == 'ArrowOutwardIcon' ? (<ArrowOutwardIcon />) : ''} </CommonButtonStyle >
        </Box>
    )
}

const CommonHeadingStyle = styled(Typography)(({ theme }) => ({
    color: '#000',
    display: 'inline',
    fontSize: '21px',
    fontWeight: 700,
    letterSpacing: '.42px',
    lineHeight: '24px',
    position: 'relative',
    zIndex: 1,
    '&::after': {
        content: '""',
        border: `2px solid ${theme.palette.secondary.main}`,
        left: '-10%',
        position: 'absolute',
        right: 0,
        top: '-40%',
        width: '40px',
        height: '40px',
        zIndex: -1,
        borderRadius: '50%'
    }
}))

export const CommonHeading = ({ text, color }) => {
    return (
        <CommonHeadingStyle sx={{ color: color }}>{text}</CommonHeadingStyle>
    )
}

const CommonHeading72px = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontSize: '48px',
    fontWeight: 600,
    letterSpacing: '.42px',
    lineHeight: '60px',
    position: 'relative',
    margin: '20px 0 0',
    [theme.breakpoints.down('lg')]: {
        fontSize: '40px',
        lineHeight: '52px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '32px',
        lineHeight: '44px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '25px',
        lineHeight: '37px',
    },
}))

export const Heading72px = ({ text, color, margin }) => {
    return (
        <CommonHeading72px sx={{ color: color, margin: margin }}>{text}</CommonHeading72px>
    )
}

const TypingTextAnimationStyle = styled(ReactTyped)(({ theme }) => ({
    overflow: 'hidden',
    color: theme.palette.secondary.main,
}))

export const TypingTextAnimation = ({ textArr }) => {
    return (
        <TypingTextAnimationStyle
            strings={textArr}
            typeSpeed={100}
            backSpeed={100}
            loop
        >
        </TypingTextAnimationStyle>
    )
}

// second stage code

const CommonParaStyle = styled(Typography)(({ theme }) => ({
    fontFamily: '"Poppins", sans-serif;',
    fontSize: { md: '16px', lg: '18px' },
    color: '#e6e6e6',
    lineHeight: '32px'
}))

export const ParagraphStyle = ({ text, color, mt, fontSize }) => {
    return (
        <CommonParaStyle sx={{ color: color, mt: mt, fontSize: fontSize }} dangerouslySetInnerHTML={{ __html: text }}></CommonParaStyle>
    )
}

const DisabledBackground = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100vh",
    position: "fixed",
    background: theme.palette.background.default,
    opacity: 1,
    zIndex: 999999,
    overflow: 'hidden'
}))

const LinearLoading = () => (
    <>
        <CircularProgress
            size={50}
            sx={{
                position: "fixed",
                left: { md: "48%", sm: "46%", xs: '44%' },
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999999
            }}
        />
        <DisabledBackground />
    </>
);

export const Loader = ({ loading }) => (
    loading ? <LinearLoading /> : null
)