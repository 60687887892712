import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ServiceHeading } from '../../../Common'

const DesignLogoBrandIdentity = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item md={6} xs={12} >
                        <Box sx={{ width: { lg: '70%', md: '70%', sm: '60%', xs: '100%' }, m: '0 auto', mb: { lg: 0, sm: 3, xs: 2 } }}>
                            <img src={`${process.env.REACT_APP_URL}/images/pages/services/designing/digital-tablet-screen-smart-tech.png`} alt='digital-tablet-screen-smart-tech' title='Close-up of Digital Tablet Screen with Smart Technology Interface' loading='lazy' width='100%' />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ServiceHeading text='Logo & Brand Identity' />
                        <Typography sx={{ fontWeight: 600, fontSize: { lg: '30px', md: '28px', sm: '24px', xs: '20px' }, textTransform: 'uppercase', mt: { lg: 4, md: 3, xs: 2 } }}>
                            WHAT'S LOGO & BRAND CAN DO?
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            A graphic logo is an effective way to inform your customers about your company and the ongoing process from the viewer's point of view depends upon the logo's structure.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            A company’s logo is crucial for making a lasting <Typography variant='span' sx={{fontWeight:'bold'}}>first impression, capturing your clients' attention</Typography>, and sparking their interest, as it is often the first element your audience notices.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            At Ugam Infotech, we help you create a logo that achieves this by analyzing your competitors and their branding strategies. Our goal is to help you distinctively position your brand in the market.
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ width: { lg: '80%', md: '84%', xs: '100%' }, m: '0 auto', mt: { lg: 10, md: 8, sm: 6, xs: 3 } }}>
                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 600, fontSize: { lg: '30px', md: '28px', sm: '24px', xs: '22px' }, textTransform: 'uppercase', mt: { lg: 4, md: 3, xs: 2 } }}>
                        WHY ARE WE DIFFERENT FROM OTHERS?
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        Over the years, we've crafted an approach that yields professional, standout brand designs that inspire trust.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        Founded in <b>2018</b>, we are an Indian-based IT support provider committed to ensuring uninterrupted business continuity throughout the year.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        Dedicated to being your <b>long-term partner</b>, our core values drive our client-centric approach, serving as the foundation of our service.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        Trust us to keep your business running smoothly.
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default DesignLogoBrandIdentity