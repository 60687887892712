import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react'
import { ArrowCommon, ServiceHeading } from '../../../Common';


const ServiceAngularWeb = () => {
    const theme = useTheme();

    const arr = [
        'Single Page Application Development with AngularJS',
        'AngularJS Application Maintenance',
        'Dynamic Web Application Development',
        'User Interaction Development',
        'Widgets Development Services',
        'Custom Development Solutions'
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pt: { md: 10, xs: 8 }, pb: { md: 10, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row' } }}>
                    <Grid item lg={7} md={12} xs={12}>
                        <Box>
                            <ServiceHeading text='Angular Web Development' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Our skilled professionals ensure that customer projects are implemented with the most cutting-edge features and designs.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 6, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                As a leading Angular development service provider, we strive not only to meet requirements but also to <Box component='span' sx={{ fontWeight: 600 }}>maximize your business's potential</Box> in the competitive market.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 6, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Our comprehensive Angular development services, renowned for exceptional web design and development, <Box component='span' sx={{ fontWeight: 600 }}>deliver outstanding results.</Box>
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 6, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Ugam Infotech's Angular web development services should be your first choice if you're seeking a <Box component='span' sx={{ fontWeight: 600 }}>highly skilled professional</Box> in this advanced technical world.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={12} xs={12} >
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ p: { lg: 6, md: 5, sm: 4, xs: 3 }, backgroundColor: theme.palette.primary.contrastText, borderRadius: 6 }}>
                                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 600, fontSize: { lg: '30px', md: '28px', sm: '24px', xs: '22px' } }}>
                                    Our services encompass
                                </Typography>
                                <Box sx={{ mt: { md: 4, xs: 2 } }}>
                                    {
                                        arr.map((item, i) => (
                                            <ArrowCommon key={i} text={item} color={theme.palette.primary.main} backgroundColor={theme.palette.background.default} />
                                        ))
                                    }
                                </Box>
                            </Box>
                            <Box sx={{ position: { md: 'absolute' }, right: { xl: -80, xs: 0 }, bottom: { xl: -190, lg: -180, xs: -70 }, width: { lg: '65%', md: '30%', sm: '40%', xs: '70%' }, height: 'auto', mt: { md: 0, xs: 3 }, m: '0 auto' }}>
                                <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/angular-web-image.png`} width='100%' loading='lazy' alt='angular-web-image' title='Angular Creating Dynamic UI Elements' height='100%' />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}

export default ServiceAngularWeb