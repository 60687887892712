import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import './index.css';


const Map = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { sm: 10, xs: 15 } }} >
            <Container maxWidth='xl'>
                <Box sx={{ position: 'relative', maxWidth: { sm: 1100 }, mx: 'auto' }}>
                    <img src={`${process.env.REACT_APP_URL}/images/pages/company/contact-us/map2.png`} height='100%' width='100%' alt='map2' title='Map of world' loading='lazy' />
                    <Box>
                        <Box className="map_indicator"  sx={{
                            width: { sm: '300px', xs: '240px' }, p: 2, boxShadow: '0 10px 40px 0 #00000029', borderRadius: '15px', position: "absolute", top: { lg: '31%', md: '28%', sm: '18%', xs: '-32%' }, left: { lg: '59%', md: '58%', sm: '56%', xs: '20%' }, backgroundColor: theme.palette.background.default,
                            cursor: 'pointer'
                        }}>
                            <Box sx={{
                                justifyContent: 'space-between', position: 'relative', display: { sm: 'flex', xs: 'block' }, '& ::before': {
                                    content: '""',
                                    borderLeft: `10px solid #0000`,
                                    borderRight: '12px solid #0000',
                                    borderTop: `19px solid ${theme.palette.primary.contrastText}`,
                                    position: 'absolute',
                                    bottom: { sm: '-36%', xs: '-24%' },
                                    left: { sm: '9%', xs: '70%' }
                                }, '& ::after': {
                                    content: '""',
                                    backgroundColor: theme.palette.secondary.main,
                                    borderRadius: '100%',
                                    height: { sm: 12, xs: 8 },
                                    width: { sm: 12, xs: 8 },
                                    position: 'absolute',
                                    bottom: { sm: '-48%', xs: '-28%' },
                                    left: { sm: '10.7%', xs: '73%' },
                                    animation: "pulsate 2s ease-out",
                                    animationIterationCount: 'infinite',
                                    animationDelay: '1.1s',
                                    boxShadow: { md: '0 0 1px 16px #ffffff60', xs: '0 0 1px 8px #ffffff60' },
                                    zIndex: 999
                                }
                            }}>
                                <Box sx={{ width: { sm: '22%', xs: '100%' }, mr: { sm: 2, xs: 'auto' } }}>
                                    <img src={`${process.env.REACT_APP_URL}/images/pages/company/contact-us/india.png`} alt='india' title='India Land of Diversity' loading='lazy' width='auto' height='auto' />
                                </Box>
                                <Typography sx={{
                                    fontFamily: theme.palette.typography.fontFamily, fontSize: '12px', fontWeight: 700, lineHeight: '24px', '&:hover': {
                                        color: theme.palette.secondary.main
                                    }
                                }}>Unique Square, Causeway Link Rd, beside Shivan Avenue, Singanpor, Surat, Gujarat 395004.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Map