import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import ContactDesc from './Components/ContactDesc'
import Contact from './Components/Contact'
import Map from './Components/Map'
import SEO from '../../SEO'

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  return (
    <>
      <SEO
        title="Contact Us - Ugam Infotech"
        description="Get in touch with Ugam Infotech. We are here to help you with your React.js, Node.js, and other development needs."
        keywords="Ugam, Ugam Infotech, contact us, get in touch, React.js, Node.js, backend, frontend, web development, software development"
        canonical={`${process.env.REACT_APP_URL}/contact-us`}
        ogTitle="Contact Us - Ugam Infotech"
        ogDescription="Get in touch with Ugam Infotech. We are here to help you with your React.js, Node.js, and other development needs."
        ogType="website"
        ogUrl={`${process.env.REACT_APP_URL}/contact-us`}
        ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
        twTitle="Contact Us - Ugam Infotech"
        twDescription="Get in touch with Ugam Infotech. We are here to help you with your React.js, Node.js, and other development needs."
        twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
      />
      <PageHeading text={'Contact Us'} />
      <ContactDesc />
      <Contact />
      <Map />
    </>
  )
}

export default ContactUs