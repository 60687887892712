import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CommonHeading, Heading72px } from '../../CustomStyle/Common'

const PageTilteSection = ({ imageSrc, titleText, subTitleText, bgImage , fontSize ,textAlign }) => {
    const theme = useTheme();
    return (
        <Box sx={{ pt: { lg: 10, xs: 8 }, pb: { lg: 18, md: 16, sm: 6, xs: 4 }, backgroundColor: theme.palette.background.lightDark }}>
            <Container maxWidth='xl'>
                <Box sx={{
                    position: 'relative', zIndex: 1, width: { md: '66%', sm: '90%', xs: '100%' }, m: '0 auto', '&::after': {
                        content: "''",
                        position: 'absolute',
                        bottom: -20,
                        left: { lg: -140, md: -120, sm: -100, xs: -150 },
                        width: { lg: '200px', md: '170px', sm: '150px', xs: '120px' },
                        height: { lg: '130px', md: '110px', sm: '90px', xs: '70px' },
                        backgroundImage: `url(${bgImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        transform: 'rotate(270deg)',
                        zIndex: -1
                    },
                }}>
                    <Box sx={{ height: { sm: '100%', xs: '100px'} , overflow: 'hidden'  }}>
                        <img src={imageSrc} width='100%' height='auto' title='Our Company' alt='about-2' loading='eager' />
                    </Box>
                    <Box sx={{ py: { lg: 6, md: 4, xs: 3 }, px: { lg: 6, md: 4, xs: 2 }, background: theme.palette.background.default, position: { md: 'absolute', xs: '' }, mt:-1 ,bottom: -80, right: { md: 0, xs: 0 }, width: { lg: '70%', md: '80%', xs: '100%' }, zIndex: 2  , textAlign : textAlign ? {md: textAlign , xs:textAlign} : 'left'}}>
                        {subTitleText ? <CommonHeading text={subTitleText} color={theme.palette.primary.main} /> : ''}
                        <Typography sx={{
                            fontSize:  fontSize ?  {lg: '38px', md: '36px', sm: '42px', xs: '24px'} : {lg: '46px', md: '36px', sm: '42px', xs: '24px'}  ,
                            fontWeight: 700,
                            letterSpacing: '.42px',
                            lineHeight: { lg: '60px', md: '60px', sm: '60px', xs: '30px' },
                            position: 'relative',
                            margin:{md: '8px 0' ,  xs:'16px 0px 0px 0px '},
                            fontFamily: theme.palette.typography.fontFamily
                        }}>{titleText}</Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default PageTilteSection