import { Box, Divider, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import WOW from 'wowjs';
import 'animate.css';

const BusinessCardComponet = ({ allcardimage }) => {


    const theme = useTheme();
    useEffect(() => {
        new WOW.WOW().init();
    }, [])
    return (
        <>
            <Box sx={{ margin: '30px 0px' }}>
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',

                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            background: theme.palette.background.default,
                            height: '60%',
                            width: { lg: '80%', xs: '100%' },
                            right: { lg: '10%', md: '0%', sm: '0%' },
                            top: '51%',
                            zIndex: '-1',
                            transform: 'translateY(-50%)',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            borderRadius: '10px',
                            display: { sm: 'block', xs: 'none' }
                        },

                    }}
                >
                    <Box sx={{
                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `6px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: { lg: '76%', md: '90%', sm: '96%' },
                            right: { lg: '12%', md: '5%', sm: '2%' },
                            top: '20%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        '&::before': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `8px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: { lg: '76%', md: '90%', sm: '96%' },
                            right: { lg: '12%', md: '5%', sm: '2%' },
                            bottom: '18%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        display: 'flex',
                        justifyContent: 'center'

                    }}>
                        <Box sx={{ padding: { md: '46px 30px', xs: '0px 10px' }, width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: { lg: '80%', md: '90%' }, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: { sm: '10px', xs: '0px' } }}>
                                <Box sx={{ width: { lg: '86%', xs: '100%' }, display: 'flex', justifyContent: 'space-around', margin: { sm: '10px 0px', xs: '0px' }, flexWrap: 'wrap' }}>


                                    {
                                        allcardimage.map((item, index) => (
                                            <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                                {
                                                    index % 2 == '0' ?
                                                        <Box className="wow animate__animated animate__pulse" sx={{ width: { lg: '444px', md: '360px', sm: '310px', xs: '100%' }, height: { lg: '265px', md: '220px', sm: '180px' }, marginTop: { md: '20px', sm: '0px', xs: '20px' } }}>
                                                            <img src={item.img} width='100%' height='100%' alt={item.name} title='Creative Business Card Design' loading='lazy' />
                                                        </Box> :
                                                        <Box className="wow animate__animated animate__pulse" sx={{ width: { lg: '444px', md: '360px', sm: '310px', xs: '100%' }, height: { lg: '265px', md: '220px', sm: '180px' }, marginTop: { md: '50px', sm: '30px', xs: '20px' } }}>
                                                            <img src={item.img} width='100%' height='100%' alt={item.name} title='Creative Business Card Design' loading='lazy' />
                                                        </Box>
                                                }
                                            </Box>
                                        ))
                                    }
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </>
    )
}

export default BusinessCardComponet