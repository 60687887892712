import React from 'react'
import { Box, useTheme } from '@mui/material'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TechCompanyTvSlider = () => {

    const theme = useTheme();

    const options = {
        loop: true,
        nav: false,
        margin: 5,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    return (
        <Box sx={{ overflow: 'hidden', backgroundColor: theme.palette.background.default, pt: { lg: 10, md: 6, xs: 0 } }}>
            <Box sx={{ position: 'relative', width: { lg: '60%', md: '90%', sm: '150%', xs: '200%' }, m: { md: '0 auto -10px' }, ml: { md: 'auto', sm: '-25%', xs: '-50%' } }}>
                <img src={`${process.env.REACT_APP_URL}/images/pc-img-1.png`} alt='pc-img-1' title='pc image' loading='lazy' width='100%' height='auto' />
                <Box sx={{ position: 'absolute', width: '40%', top: '23.5%', right: '30%', height: '38%' }}>
                    <OwlCarousel {...options}>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/mac-logoslider-img/2023-3-about-home-9.jpg`} alt='2023-3-about-home-9' title='Our complate webpage' loading='lazy' width='100%' height='100%' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/mac-logoslider-img/2023-3-about-home-10.jpg`} alt='2023-3-about-home-10' title='Our complate webpage' loading='lazy' width='100%' height='100%' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/mac-logoslider-img/2023-3-about-home-11.jpg`} alt='2023-3-about-home-11' title='Our complate webpage' loading='lazy' width='100%' height='100%' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/mac-logoslider-img/2023-3-about-home-12.jpg`} alt='2023-3-about-home-12' title='Our complate webpage' loading='lazy' width='100%' height='100%' />
                        </Box>
                    </OwlCarousel>
                </Box>
            </Box>
        </Box>
    )
}

export default TechCompanyTvSlider