import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CommonHeading } from '../../../../CustomStyle/Common'


const AboutTour = () => {

    const theme = useTheme();
    const themeColor = localStorage.getItem('theme');

    const technology = [
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/react-icon.svg`, title: 'React.js' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/skill-icons_sass.svg`, title: 'Sass' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_bootstrap.svg`, title: 'Bootstrap' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/Javascript.svg`, title: 'Javascript' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/vue-icon.svg`, title: 'Vue.js' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/angular-icon.svg`, title: 'Angular' },
        {
            icon: themeColor === 'light' 
            ? `${process.env.REACT_APP_URL}/images/lenguage-icon/next-icon.svg`
            : `${process.env.REACT_APP_URL}/images/lenguage-icon/next-icon-svg-dark.svg`,
            title:'Next.js'
        },        
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/css-logo.svg`, title: 'CSS' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/nuxt-icon.svg`, title: 'Nuxt.js' },
        { icon: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_html-5.svg`, title: 'HTML' }
    ];
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item md={4}>
                        <Box >
                            <Box sx={{
                                textAlign: { lg: 'left', xs: 'center' },
                                p: { md: 6, sm: '16px 2px', xs: '16px 0' }
                            }}>
                                <CommonHeading text={'Technologies'} fontSize={'20px'} color={theme.palette.primary.main} />
                                <Typography sx={{
                                    fontSize: { lg: '50px', md: '32px', sm: '28px', xs: '22px' },
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.main,
                                    letterSpacing: '.42px',
                                    lineHeight: { lg: '55px', md: '50px', sm: '50px', xs: '40px' },
                                    margin: '20px 0 0',
                                    fontFamily: theme.palette.typography.fontFamily
                                }}>What We Are Serving?</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={8}>
                        <Grid container spacing={{ lg: 4, md: 2, xs: 4 }}>
                            {
                                technology.map((item, i) => (
                                    <Grid key={i} item sm={3} xs={6} sx={{ height: { md: '200px', xs: '150px' } }}>
                                        <Box sx={{ width: '100%', height: '100%', border: `1px solid ${theme.palette.primary.light}`, display: 'flex', justifyContent: 'center', alignItems: 'center', p: { lg: 6, xs: 3 } }}>
                                            <img src={item.icon} width='70%' alt={item.title} title='Popular Coding Language Logos' loading='lazy' height='100%' />
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

export default AboutTour