import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../../../../App.css'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { CommonHeading } from '../../../../CustomStyle/Common';

function AboutSlider() {
    const theme = useTheme();
    const slid = [
        {
            Year: '2018',
            text: 'Grand Opening',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/3.jpg`,
            desc: "Ugam Infotech's grand office opening was a landmark event, celebrating growth and innovation. Guests enjoyed a vibrant atmosphere with flowers, music, and engaging conversations.",
            name: 'slider2'
        },
        {
            Year: '2018',
            text: 'Blessing',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/2.jpg`,
            desc: "Ugam Infotech's new office opening was blessed by esteemed monks and saints, celebrated with traditional decorations, marking a significant milestone.",
            name: 'slider1'
        },
        {
            Year: '2019',
            text: 'Guidance Seminar',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/9.jpg`,
            desc: "Dharmikbhai's guidance seminar at Ugam Infotech was transformative. His personal journey and insights on growth energized us, highlighting his commitment to leadership and our team's potential.",
            name: 'slider9'
        },

        {
            Year: '2020',
            text: 'Company Dinner',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/13.jpg`,
            desc: "Our company dinner celebrated our achievements with good food, camaraderie, and appreciation, making it a special evening.",
            name: 'slider10'
        },
        {
            Year: '2021',
            text: 'Birthday Celebration With Dinner',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/7.jpg`,
            desc: "We celebrated Raj's birthday with joy, a cake cutting and dinner ceremony, and a thoughtful gift, creating a memorable evening of celebration and appreciation.",
            name: 'slider7'
        },
        {
            Year: '2022',
            text: 'Sondamitha Halapati Ashramshal',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/4.jpg`,
            desc: "Ugam Infotech visited Sondamitha Halapati Ashramshal, sharing joy, laughter, and a hearty meal with the children, fostering a heartwarming connection.",
            name: 'slider4'
        },
        {
            Year: '2023',
            text: 'Birthday Celebration',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/1.jpg`,
            desc: "Today at Ugam Infotech, we celebrated Vishal's birthday with joy and gratitude, honoring his contributions and wishing him continued success and happiness.",
            name: 'slider3'
        },

        {
            Year: '2023',
            text: 'Owner Birthday Celebration',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/8.jpg`,
            desc: "Today at Ugam Infotech, we celebrated Dharmikbhai's birthday with joy and gratitude, honoring our visionary leader with a grand celebration filled with appreciation and heartfelt moments.",
            name: 'slider8'
        },
        {
            Year: '2024',
            text: 'Fun Activities',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/6.jpg`,
            desc: "Ugam Infotech team enjoyed a bonding day at a botanical garden with games, picnic, and nature's beauty.",
            name: 'slider6'
        },
        {
            Year: '2024',
            text: 'Birthday Celebration',
            image: `${process.env.REACT_APP_URL}/images/ourculture-img/5.jpg`,
            desc: "Today, we celebrated Parth's birthday with joy, surprises, delicious treats, and a thoughtful gift, making it a memorable day of celebration.",
            name: 'slider5'
        },



    ]

    const options = {
        loop: true,
        margin: 30,
        center: true,
        arrows: 'false',
        dots: true,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            768: {
                items: 2
            },
            1000: {
                items: 2
            },
            1024: {
                items: 3
            },
            1366: {
                items: 3
            }
        }
    };

    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ textAlign: 'center' }} >
                    <Box sx={{
                        textAlign: 'center',
                        maxWidth: { md: 1600, sm: '100%', xs: '500px' }
                    }}>
                        <CommonHeading text={'Tour to Ugam Infotech'} fontSize={'20px'} color={theme.palette.primary.main} />
                        <Typography sx={{
                            fontSize: { lg: '50px', md: '32px', sm: '28px', xs: '22px' },
                            fontWeight: 'bold',
                            color: theme.palette.primary.main,
                            letterSpacing: '.42px',
                            lineHeight: { lg: '55px', md: '50px', sm: '50px', xs: '40px' },
                            margin: '20px 0 40px 0',
                            fontFamily: theme.palette.typography.fontFamily
                        }}>Slight look on years of success!</Typography>
                    </Box>
                </Box>
                <Grid container spacing={4}>
                    <Grid className='TourtoUgam' item md={12} sm={12} xs={12}>
                        <OwlCarousel {...options}>

                            {
                                slid.map((item, index) => (
                                    <Box key={index}>
                                        <Box className='mainBox' sx={{
                                            minHeight: "600px", position: 'relative', display: 'flex', alignItems: 'center',

                                        }}>
                                            <Box sx={{ p: 5, width: '100%', height: 'auto', }}>
                                                <Typography variant='h4' sx={{
                                                    fontFamily: theme.palette.typography.fontFamily,
                                                    position: 'relative', fontWeight: 800, fontSize: '30px', lineHeight: '32px', mb: 2, pl: 2
                                                    , '&::before': {
                                                        content: '""',
                                                        background: theme.palette.secondary.main,
                                                        position: 'absolute',
                                                        height: '80%',
                                                        left: 0,
                                                        top: '-1px',
                                                        width: '2px',
                                                        opacity: 0.6,
                                                    }
                                                }}>#{item.Year}</Typography>
                                                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: '20px', fontWeight: 800, lineHeight: '22px' }}>{item.text}</Typography>
                                            </Box>
                                            <Box className='innerbox' sx={{ position: 'absolute', backgroundColor: '#121a35', boxShadow: `4px 9px 10px ${theme.palette.background.lightDark}`, transition: '.5s', width: '100%', }}>

                                                <Box sx={{
                                                    position: 'relative', background: theme.palette.background.default, '&::before': {
                                                        background: '#000000a3',
                                                        content: '""',
                                                        height: '100%',
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: 0,
                                                        width: '100%'
                                                    }
                                                }}  >
                                                    <Box sx={{ height: '15px', width: '15px', position: 'absolute', zIndex: 1, borderLeft: '2px solid #fff', borderTop: '2px solid #fff', top: 15, left: 15 }}></Box>
                                                    <Box sx={{ height: '15px', width: '15px', position: 'absolute', zIndex: 1, borderRight: '2px solid #fff', borderTop: '2px solid #fff', top: 15, right: 15 }}></Box>
                                                    <Box sx={{ height: '15px', width: '15px', position: 'absolute', zIndex: 1, borderLeft: '2px solid #fff', borderBottom: '2px solid #fff', bottom: 15, left: 15 }}></Box>
                                                    <Box sx={{ height: '15px', width: '15px', position: 'absolute', zIndex: 1, borderRight: '2px solid #fff', borderBottom: '2px solid #fff', bottom: 15, right: 15 }}></Box>
                                                    <img width='100%' src={item.image} alt={item.name} title='Slight look on years of success!' loading='lazy' height='auto'></img>
                                                    <Box sx={{ position: 'absolute', top: 0, fontFamily: theme.palette.typography.fontFamily, fontWeight: 800, right: 50, background: theme.palette.secondary.main, color: '#fff', py: 3, px: 1 }}>#{item.Year}</Box>
                                                    <Box sx={{ position: 'absolute', bottom: 40, left: 40, width: { md: '50%', sm: '63%', xs: '60%' } }}>
                                                        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: '#fff', textTransform: 'capitalize', pb: 2.5, borderBottom: `3px solid ${theme.palette.secondary.main}`, fontSize: '30px', fontWeight: 800, lineHeight: '2rem' }}>{item.text}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{
                                                    position: 'relative', p: 4, '&::before': {
                                                        background: theme.palette.secondary.main,
                                                        content: '""',
                                                        bottom: '-2px',
                                                        height: 3,
                                                        left: 34,
                                                        position: 'absolute',
                                                        width: '100px'
                                                    }
                                                }} >
                                                    <Typography sx={{ color: '#f0f4f8', fontSize: '16px', fontWeight: 400, lineHeight: '30px', fontFamily: theme.palette.typography.fontFamily, }}>{item.desc}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </OwlCarousel>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default AboutSlider