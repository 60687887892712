import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ServiceHeading } from '../../../Common';

const DesignProductBranding = () => {

    const theme = useTheme();

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ width: { lg: '52%', sm: '50%', xs: '100%' }, m: '0 auto' }}>
                    <img width='100%' src={`${process.env.REACT_APP_URL}/images/pages/services/designing/product-branding.png`} alt='product-branding' title='Brand Identity Crafting a Distinctive Image' loading='lazy' height='100%' />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <ServiceHeading text='Product Branding' />
                </Box>
                <Box sx={{
                    p: { lg: 6, md: 5, sm: 4, xs: 3 }, position: 'relative', border: `1px solid ${theme.palette.primary.light}`, mt: { lg: 6, md: 5, sm: 4, xs: 3 }, '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: { md: -16, xs: -10 },
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                        borderBottom: { md: `15px solid ${theme.palette.primary.light}`, xs: `10px solid ${theme.palette.primary.light}` },
                        borderLeft: { md: `15px solid transparent`, xs: `10px solid transparent` },
                        borderRight: { md: `15px solid transparent`, xs: `10px solid transparent` }
                    }
                }}>
                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 600, fontSize: { lg: '30px', md: '28px', sm: '24px', xs: '22px' } }}>
                    While serving our customers, we are primarily focused on:
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                            Without a distinct brand and appeal, why should customers choose your brand over another? This challenge is particularly significant for new brands entering markets dominated by established players. How will you capture the attention of consumers who are already loyal to other brands? Product branding is crucial for attracting consumers, encouraging them to try your product, and shaping their perception and response. What initially engaged them with your product, and how did their first experience align with their initial impressions?
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                            At Ugam Infotech, we employ advanced market research techniques to assess the impact of product and service branding. By focusing on consumer insights and core ideology, we develop effective brand positioning strategies, utilizing customer feedback and the services you provide.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default DesignProductBranding