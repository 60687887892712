import React, { useEffect } from 'react';
import PageHeading from '../../commonComponents/PageHeading';
import PageTilteSection from '../../commonComponents/PageTitleSection';
import DesignPageDesc from './Components/DesignPageDesc';
import DesignExperienceDesign from './Components/DesignExperienceDesign';
import DesignPrototypeWireframe from './Components/DesignPrototypeWireframe';
import DesignGraphics from './Components/DesignGraphics';
import DesignLogoBrandIdentity from './Components/DesignLogoBrandIdentity';
import DesignProductBranding from './Components/DesignProductBranding';
import DesignIllustration from './Components/DesignIllustration';
import DesignPrintMaterial from './Components/DesignPrintMaterial';
import ServiceOtherCommon from '../Common/ServiceOtherCommon';
import SEO from '../../SEO';

const Design = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SEO
                title="Design - Ugam Infotech"
                description="Discover our design services at Ugam Infotech. We specialize in creating visually stunning and user-friendly designs using the latest design principles."
                keywords="Ugam, Ugam Infotech, design services, visual design, user-friendly design, graphic design, web design, UI/UX design"
                canonical={`${process.env.REACT_APP_URL}/services/design`}
                ogTitle="Design - Ugam Infotech"
                ogDescription="Discover our design services at Ugam Infotech. We specialize in creating visually stunning and user-friendly designs using the latest design principles."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/services/design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Design - Ugam Infotech"
                twDescription="Discover our design services at Ugam Infotech. We specialize in creating visually stunning and user-friendly designs using the latest design principles."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={"Designing"} />
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/designing/graphic-development.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'User Interface Designing'} />
            <DesignPageDesc />
            <DesignExperienceDesign />
            <DesignPrototypeWireframe />
            <DesignGraphics />
            <DesignProductBranding />
            <DesignLogoBrandIdentity />
            <DesignIllustration />
            <DesignPrintMaterial />
            <ServiceOtherCommon />
        </>
    )
}

export default Design