import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../../../App.css'
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { CommonBtn } from '../../../CustomStyle/Common';
import { Link } from 'react-router-dom';
function OurCulture() {
    const theme = useTheme();
    const style = {
        WebkitTextStroke: theme.palette.primary.light,
        WebkitTextStrokeWidth: '1px',
        color: '#0000',
        fontFamily: '"Poppins", sans-serif;',
        fontWeight: 600,
        opacity: .4,
        fontSize: { xs: '66px', sm: '84px', md: '84px', lg: '114px' },
        lineHeight: { xs: '108px', sm: '108px', md: '108px', lg: '124px' }
    };
    const options = {
        loop: true,
        nav: true,
        arrows: 'true',
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, textAlign: { lg: 'start', md: 'start', sm: 'center', xs: 'center' } }} >
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ background: `${theme.palette.secondary.main}` }}>
                    <OwlCarousel {...options}>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/1.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-1' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/2.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-2' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/3.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-3' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/4.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-4' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/5.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-5' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/6.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-6' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/7.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-7' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/8.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-8' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/9.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-9' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                        <Box>
                            <img src={`${process.env.REACT_APP_URL}/images/ourculture-img/13.jpg`} style={{ objectFit: 'contain' }} alt='ourculture-img-13' title='Our Culture' loading='lazy' width='100%' height='auto' />
                        </Box>
                    </OwlCarousel>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12} item sx={{ px: { lg: 10, md: 8, sm: 6, xs: 4 }, display: 'flex', justifyContent: 'center', pb: { xs: 4 } }}>
                    <Box>
                        <Box sx={{ textAlign: { lg: 'start', md: 'start', sm: 'center', xs: 'center' } }}>
                            <Typography sx={style}>Ugam</Typography>
                        </Box>
                        <Typography sx={{
                            color: theme.palette.primary.main,
                            fontSize: { xs: '28px', sm: '43px', md: '45px', lg: '48px' },
                            lineHeight: { xs: '48px', sm: '57px', md: '63px', lg: '78px' },
                            fontFamily: theme.palette.typography.fontFamily,
                            fontWeight: 200,
                            marginTop: { xs: '-39px', sm: '-51px', md: '-51px', lg: '-51px' }
                        }}>Curious to Learn More?<br></br>Discover Insights Into
                        </Typography>
                        <Typography variant='h4' sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: '800', color: theme.palette.primary.main, fontSize: { md: '40px', sm: '36px', xs: '32px' } }}>Life@Ugam</Typography>
                        <Box sx={{ marginTop: '30px' }}>
                            <Link to="/company/our-culture" aria-label="Learn more about our culture" title='Our Culture' rel="follow"  >
                                <CommonBtn text={'Our Culture'} name={'ArrowOutwardIcon'} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} />
                            </Link>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}

export default OurCulture