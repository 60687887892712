import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'

function Benefite() {
    const theme = useTheme();
    const technology = [
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b1.svg`, text: 'Learning and Growth Opportunities', name: 'b1' },
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b2.svg`, text: 'Salary Increase Policy', name: 'b2' },
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b3.svg`, text: 'Transparent Communication Policy', name: 'b3' },
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b4.svg`, text: 'Recognition Awards', name: 'b4' },
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b5.svg`, text: 'Vibrant Environment & Enjoyable Activities', name: 'b5' },
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b6.svg`, text: 'Flexible Working Hours', name: 'b6' },
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b7.svg`, text: 'Paid Vacation', name: 'b7' },
        { icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/workplace-icons/b8.svg`, text: 'Food and Beverage', name: 'b8' },
    ]
    return (
        <Container maxWidth='xl'>
             <Typography sx={{ textAlign: 'center', fontWeight: 800, fontFamily: theme.palette.typography.fontFamily, lineHeight: { sm: '32px', xs: '36px' }, fontSize: { sm: '40px', xs: '30px' }, m: { sm: 10, xs: 5 } }}>Workplace Advantages</Typography>
            <Grid container spacing={4} mb={6} sx={{ pb: 5 }}>
                {
                    technology.map((item, i) => (
                        <Grid item md={3} sm={6} xs={12} key={i} >
                            <Box sx={{ width: '100%', height: '300px', border: `1px solid ${theme.palette.primary.main}`, alignItems: 'center', p: 4 }}>
                                <Box sx={{ width: '100%', height: '100px', p: 2, my: 3 }}>
                                    <img src={item.icon} width='100%' height='100%' alt={item.name} title={item.text} loading='lazy' />
                                </Box>
                                <Typography sx={{ textAlign: 'center', fontSize: '21px', lineHeight: '26px', fontWeight: 700, fontFamily: theme.palette.typography.fontFamily, }}>{item.text}</Typography>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    )
}

export default Benefite