import React, { useEffect } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import { useTheme, Typography, Tab, Grid, Link, Box, Container } from '@mui/material';
import { Gif } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

function MobileSidebar({ comapny, services, portfolio, handleDrawer }) {
    const theme = useTheme();
    const navgation = useNavigate();
    const location = useLocation();

    const [expandedVal, setExpandedVal] = React.useState({
        company: false,
        services: false,
        portfolio: false
    });

    const handleExpansion = (openAccordion) => {
        setExpandedVal({ ...expandedVal, [openAccordion]: !expandedVal[openAccordion] });
    };

    useEffect(() => {
        if (location.pathname.split("/")[1] && location.pathname.split("/")[1] in expandedVal) {
            setExpandedVal({ ...expandedVal, [location.pathname.split("/")[1]]: true });
        } else {
            setExpandedVal({ ...expandedVal, company: true })
        }
    }, [])

    const handleNavigate = (link) => {
        navgation(link)
        handleDrawer('right', false)
    }
    return (
        <>
            <Tab sx={{
                fontSize: '16px', fontWeight: 800, color: theme.palette.primary.main, '&:hover': {
                    color: theme.palette.secondary.main,
                },
            }} label={'Home'} onClick={() => handleNavigate('/')} />
            <Grid sx={{ marginLeft: { md: '40px !important' } }}>
                <Accordion expanded={expandedVal.company} onChange={() => handleExpansion("company")} sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.light, borderRadius: '14px !important', overflow: 'hidden', mb: 2, mt: 1 }}>
                    <AccordionSummary
                        expandIcon={<AddIcon sx={{ color: theme.palette.secondary.light }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box
                            sx={{ color: theme.palette.secondary.light, textTransform: 'uppercase' }}>
                            Comapany
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: theme.palette.background.menuBar }}>
                        <Container p={0} >
                            <Grid container spacing={2}>
                                {comapny.map((item, i) => (
                                    <Grid item lg={4} md={4} sx={{ width: { sm: '33.33%' } }} key={i} className='main' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} onClick={() => handleNavigate(item.link)}>
                                            <Grid>
                                                <Box className='img' sx={{ background: theme.palette.primary.dark, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                                                    <img src={item.icon} />
                                                </Box>
                                            </Grid>
                                            <Grid >
                                                <Box sx={{ width: { sm: '100%' } }}>
                                                    <Typography className='title' sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: '14px', fontWeight: 700, lineHeight: '20px', textTransform: 'uppercase', transform: { md: 'translateY(25px)', xs: 'translateY(8px)' } }} >
                                                        {item.title}
                                                    </Typography>
                                                    <Typography className='desc' sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.light, fontSize: '12px', fontWeight: 400, transform: { xs: 'translateY(8px)' } }}>
                                                        {item.desc}
                                                    </Typography>
                                                    <Typography className='bttn' sx={{ color: theme.palette.primary.main, fontSize: '14px', fontWeight: 600, mt: '10px', textTransform: 'none', textDecoration: 'none', position: 'relative', transform: { xs: 'translateY(3px) ' } }} >{item.btn} </Typography>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </AccordionDetails>
                </Accordion >
                <Accordion expanded={expandedVal.services} onChange={() => handleExpansion("services")} sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.light, borderRadius: '14px !important', overflow: 'hidden', mb: 2 }}>
                    <AccordionSummary
                        expandIcon={<AddIcon sx={{ color: theme.palette.secondary.light }} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Box
                            sx={{ color: theme.palette.secondary.light, textTransform: 'uppercase' }}>
                            Services
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: theme.palette.background.menuBar }}>
                        <Container >
                            <Grid container spacing={2}>
                                {services.map((item, i) => (
                                    <Grid item lg={4} md={4} sx={{ width: { sm: '33.33%' } }} key={i} className='main' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} onClick={() => handleNavigate(item.link)}>
                                            <Grid>
                                                <Box className='img' sx={{ background: theme.palette.primary.dark, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                                                    <img src={item.icon} />
                                                </Box>
                                            </Grid>
                                            <Grid >
                                                <Box sx={{ width: { sm: '100%' } }}>
                                                    <Typography className='title' sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: '14px', fontWeight: 700, lineHeight: '20px', textTransform: 'uppercase', transform: { md: 'translateY(25px)', xs: 'translateY(8px)' } }} >
                                                        {item.title}
                                                    </Typography>
                                                    <Typography className='desc' sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.light, fontSize: '12px', fontWeight: 400, transform: { xs: 'translateY(8px)' } }}>
                                                        {item.desc}
                                                    </Typography>
                                                    <Typography className='bttn' sx={{ color: theme.palette.primary.main, fontSize: '14px', fontWeight: 600, mt: '10px', textTransform: 'none', textDecoration: 'none', position: 'relative', transform: { xs: 'translateY(3px) ' } }} >{item.btn} </Typography>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expandedVal.portfolio} onChange={() => handleExpansion("portfolio")} sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.light, borderRadius: '14px !important', overflow: 'hidden', mb: 1 }}>
                    <AccordionSummary
                        expandIcon={<AddIcon sx={{ color: theme.palette.secondary.light }} />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <Box
                            sx={{ color: theme.palette.secondary.light, textTransform: 'uppercase' }}>
                            Portfolio
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: theme.palette.background.menuBar }}>
                        <Container >
                            <Grid container spacing={2}>
                                {portfolio.map((item, i) => (
                                    <Grid item lg={4} md={4} sx={{ width: { sm: '33.33%' } }} key={i} className='main' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} onClick={() => handleNavigate(item.link)} >
                                            <Grid>
                                                <Box className='img' sx={{ background: theme.palette.primary.dark, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                                                    <img src={item.icon} />
                                                </Box>
                                            </Grid>
                                            <Grid >
                                                <Box sx={{ width: { sm: '100%' } }}>
                                                    <Typography className='title' sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: '14px', fontWeight: 700, lineHeight: '20px', textTransform: 'uppercase', transform: { md: 'translateY(25px)', xs: 'translateY(8px)' } }} >
                                                        {item.title}
                                                    </Typography>
                                                    <Typography className='desc' sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.light, fontSize: '12px', fontWeight: 400, transform: { xs: 'translateY(8px)' } }}>
                                                        {item.desc}
                                                    </Typography>
                                                    <Typography className='bttn' sx={{ color: theme.palette.primary.main, fontSize: '14px', fontWeight: 600, mt: '10px', textTransform: 'none', textDecoration: 'none', position: 'relative', transform: { xs: 'translateY(3px) ' } }} >{item.btn} </Typography>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </AccordionDetails>
                </Accordion>
            </Grid >
            <Tab sx={{
                fontSize: '16px', fontWeight: 800, color: theme.palette.primary.main, '&:hover': {
                    color: theme.palette.secondary.main,
                },
            }} label={'Contact Us'} onClick={() => handleNavigate('/contact-us')} />
        </>
    )
}

export default MobileSidebar