import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { ArrowCommon, ServiceHeading } from '../../../Common';

const OpenCart = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' } ,display:'flex',alignItems:'center ! important'}}>
                    <Grid item lg={7} md={6} xs={12}>
                        <Box>
                            <ServiceHeading text='OpenCart' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>

                                The Ugam Infotech team is ready to provide you with top-notch OpenCart development services, addressing a wide range of issues related to customization, shipping, and other e-commerce needs.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>Our team is client-centric, with years of experience in delivering OpenCart services, and we are proud that our customers consider us the best provider of web design and development services. Additionally, we offer a variety of services, including:
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <ArrowCommon text={'Customization service'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                            <ArrowCommon text={'payment and shipping integration services'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                            <ArrowCommon text={'marketplaces development services'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                            <ArrowCommon text={'Drop Shipping services and many more'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={6} xs={12} sx={{
                        textAlign: 'center', width: { md: '100%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto', position: 'relative', '&::after': {
                            content: "''",
                            position: 'absolute',
                            top: 150,
                            left: { lg: -60, md: -43, sm: -58, xs: -40 },
                            width: { lg: '200px', md: '170px', sm: '150px', xs: '120px' },
                            height: { lg: '130px', md: '110px', sm: '90px', xs: '70px' },
                            backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/about.svg`})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            transform: 'rotate(270deg)',
                            zIndex: -1
                        },
                    }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/ecommerce-and-cms/opencart.png`} width='100%' height='auto' alt="opencart" loading='lazy' title='Online Shopping Cart Interface' />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default OpenCart