import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react'
import { ServiceHeading } from '../../../Common';


const ServiceHtmlCss = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { md: 10, xs: 8 }, pt: { lg: 40, md: 10, xs: 6 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={2} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' } }}>
                    <Grid item lg={9.5} md={9} xs={12}>
                        <Box>
                            <ServiceHeading text='HTML/CSS' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Ugam Infotech has established a reputation for serving the global market <Box component='span' sx={{ fontWeight: 600 }}>successfully for many years</Box>. Our experienced team specializes in HTML/CSS, utilizing HTML5 and CSS3 to develop responsive websites that precisely meet our clients' requirements.</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={2.5} md={3} xs={12} sx={{ display: { md: 'block', xs: 'none' }, textAlign: 'center', height: 'auto' }}>
                        <Box sx={{ width: { lg: '60%', md: '74%', sm: '70%', xs: '90%' }, m: '0 auto', height: { md: '100%', xs: 'auto' } }}>
                            <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/html-css-image.png`} width='100%' height='auto' alt="html-css-image" title='a computer with design software symbols' loading='lazy' />
                        </Box>
                    </Grid>
                </Grid>
                <Box>
                    <Typography sx={{ color: theme.palette.primary.main, fontWeight: 600, fontSize: { lg: '24px', md: '22px', sm: '20px', xs: '18px' }, py: 2 }}>
                        ARE YOU IN SEARCH OF A HIGHLY SKILLED AND REPUTABLE CSS DESIGNER OR HTML DEVELOPER?
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { md: '18px', xs: '16px' }, mt: { md: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '40px', sm: '36px', xs: '28px', borderLeft: `5px solid ${theme.palette.secondary.main}` }, pl: { md: 5, sm: 4, xs: 3 } }}>
                        Reach out to the <Box component='span' sx={{ fontWeight: 600 }}>experts at Ugam Infotech today!</Box> <br />
                        Our team will help you distinguish yourself from the competition by catering to various fields such as E-commerce websites.<Box component='span' sx={{ display: { lg: 'inline', xs: 'none' } }}><br /></Box>
                        landing pages, PSD to HTML conversion, Bootstrap development, and many other services.
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default ServiceHtmlCss