import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CommonHeading } from '../../../../CustomStyle/Common';

const ContactDesc = () => {
    const theme = useTheme();
    return (
        <Box sx={{
            textAlign: 'center', backgroundColor: theme.palette.background.lightDark, py: { md: 12, sm: 10, xs: 8 }
        }}>
            <Container maxWidth='xl'>
                <Box >
                    <CommonHeading text={"Let's"} fontSize={'24px'} margin={'0px 20px'} fontFamily={'gilroy-extraBold'} color={theme.palette.primary.main} />
                    <Typography sx={{
                        fontSize: { lg: '72px', md: '50px', sm: '40px', xs: '30px' },
                        fontWeight: 700,
                        color: theme.palette.secondary.main,
                        lineHeight: { lg: '80px', md: '70px', sm: '50px', xs: '40px' },
                        mt: 2,
                        fontFamily: theme.palette.typography.fontFamily
                    }}>Have any query? Get in Touch.</Typography>
                    <Typography sx={{
                        mt: { md: 3, xs: 2 }, fontSize: { md: '24px', sm: '20px', xs: '18px' },
                        fontFamily: theme.palette.typography.fontFamily
                    }}>Feel free to reach out with any questions or let's discuss how we can collaborate for the future.</Typography>
                </Box>
            </Container>
        </Box >

    )
}

export default ContactDesc