import React from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { ServiceHeading } from '../../../Common';

const ServiceLaravel = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ borderRadius: 6, backgroundColor: theme.palette.primary.contrastText, p: { lg: 10, md: 8, sm: 6, xs: 3 } }}>
                    <Grid container spacing={6} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Grid item lg={8} md={7}>
                            <Box>
                                <ServiceHeading text={'Laravel'} />
                                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                    Laravel, the widely adopted and rapidly expanding open-source PHP framework, offers cutting-edge features and scalability for applications.
                                </Typography>
                                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                    At Ugam Infotech, we are renowned for delivering top-notch Laravel web development services, prioritizing security as our foremost concern.
                                </Typography>
                                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                    Our team is recognized for being among the leading Laravel development companies, providing an extensive range of website development services and ensuring client satisfaction and happiness.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={5}>
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/laravel-image.png`} width='100%' loading='lazy' alt='laravel-image' title='Building Dynamic Websites with Laravel' height='100%' />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default ServiceLaravel