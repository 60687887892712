import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description, keywords, canonical, ogTitle, ogDescription, ogType, ogUrl, ogImage, twTitle, twDescription, twImage }) => {
    return (
        <Helmet>
            {/* primary metadata */}
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            <meta name="robots" content="index, follow" />

            {/* canonical metadata */}
            {canonical && <link rel="canonical" href={canonical} />}

            {/* openGraph metadata */}
            {ogTitle && <meta property="og:title" content={ogTitle} />}
            {ogDescription && <meta property="og:description" content={ogDescription} />}
            {ogType && <meta property="og:type" content={ogType} />}
            {ogUrl && <meta property="og:url" content={ogUrl} />}
            {ogImage && <meta property="og:image" content={ogImage} />}

            {/* Twitter Card Meta Tags  */}
            <meta name="twitter:card" content="summary_large_image" />
            {twTitle && <meta name="twitter:title" content={twTitle} />}
            {twDescription && <meta name="twitter:description" content={twDescription} />}
            {twImage && <meta name="twitter:image" content={twImage} />}

            {/* Schema.org Structured Data (JSON-LD) */}
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "${title}",
                        "description": "${description}",
                        "url": "${ogUrl}",
                        "image": "${ogImage}"
                    }
                `}
            </script>

        </Helmet>
    )
}

export default SEO