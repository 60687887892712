import React, { useEffect, useState } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Button, Container, Divider, Grid, Tabs, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';


import BrandDesignComponent from './BrandDesignComponent';
import { alltab } from '../Json';
import SEO from '../../SEO';


const BrandDesign = () => {
    const theme = useTheme();

    let BrandDesigncomponent1 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/branding-design/branding1.jpg`,
            name :'branding1'
        }
    ]

    let BrandDesigncomponent2 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/branding-design/branding2.jpg`,
            name :'branding2'
        }
    ]


    let BrandDesigncomponent3 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/branding-design/branding3.jpg`,
            name :'branding3'
        }
    ]


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <SEO
                title="Brand Identity Design Services - Ugam Infotech"
                description="Explore our brand identity design services at Ugam Infotech. We help you create a strong brand image through logos, color schemes, and visual elements."
                keywords="Ugam, Ugam Infotech, brand identity design services, logos, color schemes, visual elements, brand image"
                canonical={`${process.env.REACT_APP_URL}/portfolio/brandidentity-design`}
                ogTitle="Brand Identity Design Services - Ugam Infotech"
                ogDescription="Explore our brand identity design services at Ugam Infotech. We help you create a strong brand image through logos, color schemes, and visual elements."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/brandidentity-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Brand Identity Design Services - Ugam Infotech"
                twDescription="Explore our brand identity design services at Ugam Infotech. We help you create a strong brand image through logos, color schemes, and visual elements."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Brand Identity Design'} />
            <Box sx={{ overflow: 'hidden', width: '100%', backgroundColor: theme.palette.background.lightDark }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "14px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' } }}>We offer top-notch brand identity design services that involve creating a cohesive visual identity system for a business or organization. A strong corporate brand identity can enhance credibility, boost brand recognition, and cultivate a sense of loyalty among customers and employees.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{
                            display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                        {
                                            item.text == "Brand Identity Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main, fontSize: '16px !important   '
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark,
                                                    '&:hover': {
                                                        color: `${theme.palette.secondary.main} !important`
                                                    }
                                                }} aria-label={item.arialable} title={item.text} rel='follow' >{item.text}</Link>
                                        }

                                        {index < alltab.length && !index == 0 &&(
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ margin: '50px 0px 140px' }}>
                            <BrandDesignComponent allbrandDesignimage={BrandDesigncomponent1} />
                            <BrandDesignComponent allbrandDesignimage={BrandDesigncomponent2} />
                            <BrandDesignComponent allbrandDesignimage={BrandDesigncomponent3} />
                        </Box>
                    </Box>
                </Container >
            </Box>
        </>
    )
}

export default BrandDesign