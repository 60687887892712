import { Box, Divider, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import WOW from 'wowjs';


const LogoComponent = ({ allLogoimages }) => {

    const theme = useTheme();
    useEffect(() => {
        new WOW.WOW().init();
    }, [])
    return (
        <>
            <Box sx={{ margin: '30px 0px' }}>
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',
                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            background: theme.palette.background.default,
                            height: '66%',
                            width: '80%',
                            right: '10%',
                            top: '53%',
                            zIndex: '-1',
                            transform: 'translateY(-50%)',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            borderRadius: '10px',
                            display: { sm: 'block', xs: 'none' }

                        },
                    }}
                >
                    <Box sx={{
                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `6px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: '74%',
                            right: '13%',
                            top: '19%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        '&::before': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `8px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: '74%',
                            right: '13%',
                            bottom: '13%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        display: 'flex',
                        justifyContent: 'center'

                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', padding: { md: '46px 30px', xs: '20px' }, width: { sm: '72%', xs: '100%' } }}>
                            {allLogoimages.map((item, index) => (
                                <Box key={index} className="wow animate__animated animate__pulse" sx={{ width: { lg: '290px', md: '250px', sm: '200px', xs: '250px' }, height: { lg: '290px', md: '252px', sm: '200px', xs: '250px' }, boxShadow: '0 0 30px #0000000d', margin: { xl: '26px 0px 0px', lg: '8px', xs: '10px' } }}
                                >
                                    <img src={item.img} width='100%' height= '100%' loading='lazy' title='All creative logo design' alt={item.name} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default LogoComponent