import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ServiceHeading } from '../../../Common';

const SocialMediaMarketing = () => {
    const theme = useTheme();
    const SocialMarketing = [
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon1.svg`,
            text: 'Account Setup',
            name: 'icon1'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon2.svg`,
            text: 'Profile Creation',
            name: 'icon2'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon3.svg`,
            text: 'Profile Optimization',
            name: 'icon3'
        }
        , {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon10.svg`,
            text: 'Promotion',
            name: 'icon10'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon5.svg`,
            text: 'Posting',
            name: 'icon5'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon6.svg`,
            text: 'Paid Ads',
            name: 'icon6'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon7.svg`,
            text: 'Hashtag Suggestions',
            name: 'icon7'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon8.svg`,
            text: 'Trending Updates',
            name: 'icon8'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon9.svg`,
            text: 'Content for Post',
            name: 'icon9'
        }
        , {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon10.svg`,
            text: 'Best Practices of Post Scheduling',
            name: 'icon10'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon11.svg`,
            text: 'Management',
            name: 'icon11'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/icons/icon12.svg`,
            text: 'Monitoring & Reporting',
            name: 'icon12'
        }
    ]
    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 2, xs: 8 } }}>
                <Container maxWidth='xl'>
                    <Box sx={{ pb: { md: 15, xs: 8 } }}>
                        <Grid container spacing={6} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' }, display: 'flex', alignItems: 'center' }}>
                            <Grid item lg={7} md={6} xs={12}>
                                <Box sx={{ mb: 5 }}>
                                    <ServiceHeading text='Social Media Marketing' />
                                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>Our team has developed a suite of social media marketing services designed to engage your target audience and elevate brand visibility by blending proven practices with modern strategies.
                                    </Typography>
                                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>We provide services that enhance your presence on social media platforms, including <b>targeted audience engagement</b>, strategic posting management, and innovative content creation. Leveraging our expertise and successful social media marketing initiatives, we are poised to support our clients in reaching their peak business potential.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={5} md={6} xs={12} sx={{
                                textAlign: 'center', width: { md: '100%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto', display: 'flex', alignItems: "center"
                            }}>
                                <img src={`${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/socialmediamarketingimg.png`} width='100%' height='auto' alt="socialmediamarketingimg" title='Analyzing Social Media Campaigns' loading='lazy' />
                            </Grid>
                        </Grid>
                        <Container maxWidth='lg' sx={{ mt: 2 }}>
                            <Box sx={{
                                backgroundColor: theme.palette.background.default,
                                px: { sm: 7, xs: 3 }, py: { sm: 8, xs: 4 }, position: 'relative', border: `1px solid ${theme.palette.primary.light}`, '& ::before': {
                                    content: '""',
                                    position: 'absolute',
                                    borderBottom: `15px solid ${theme.palette.primary.dark}`,
                                    borderLeft: `15px solid #0000`,
                                    borderRight: '15px solid #0000',
                                    top: -15,
                                    left: '50%',
                                    transform: 'translate(-50% , 0)',
                                    zIndex: 1000
                                }
                            }}>
                                <Box>
                                    <Grid container>
                                        {
                                            SocialMarketing.map(((item, i) => (
                                                <Grid key={i} item lg={4} sm={6} xs={12} >
                                                    <Box sx={{ display: 'flex', my: 2, alignItems: 'center', justifyContent: 'left' }}>
                                                        <Box>
                                                            <img src={item.icon} alt={item.name} title={item.text} loading='lazy' width='auto' height='auto' />
                                                        </Box>
                                                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, fontWeight: 'bold', ml: 2, lineHeight: { md: '35px', xs: '28px' } }}>
                                                            {item.text}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )))
                                        }
                                    </Grid>
                                    <Box>
                                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, lineHeight: { md: '35px', xs: '28px' } }}>
                                            For our clients, we handle various social media platforms such as LinkedIn, Google My Business, Instagram, Twitter, Facebook, and YouTube.
                                        </Typography>
                                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, lineHeight: { md: '35px', xs: '28px' } }}>We will assess and identify which platforms best suit your requirements and <b>create a strategy to optimize</b> their use. In addition to social media management, we offer a range of services to support these initiatives.
                                        </Typography>
                                        <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, lineHeight: { md: '35px', xs: '28px' } }}>If you need help to achieve precise reach and attract new customers through a targeted social media campaign, feel free to reach out to Ugam Infotech today.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Container >
            </Box >

        </>
    )
}

export default SocialMediaMarketing