import React, { useEffect } from 'react'
import SliderHeroPart from './components/SliderHeroPart'
import CompanyStory from './components/CompanyStory'
import ExpertiseTechnology from './components/ExpertiseTechnology'
import JoinOurTeam from './components/JoinOurTeam'
import OfferingClient from './components/OfferingClient'
import OurCulture from './components/OurCulture'
import ExploreBrand from './components/ExploreBrand'
import WeAreTechComny from './components/WeAreTechComny'
import TrustLogoslick from './components/TrustLogoslick'
import TechCompanyTvSlider from './components/TechCompanyTvSlider'
import OurExpertisePart from './components/OurExpertisePart'
import WhyChoose from './components/WhyChoose'
import CounterPart from '../commonComponents/CounterPart'
import SEO from '../SEO'

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SEO
                title="Home - Ugam Infotech"
                description="Ugam Infotech specializes in React.js, Node.js and other backend and frontend languages."
                keywords="Ugam, Ugam Infotech, React.js, Node.js, backend, frontend, programming languages, web development, software development, JavaScript, HTML, CSS, PHP, Laravel, MySQL, MongoDB"
                canonical={process.env.REACT_APP_URL}
                ogTitle="Web Design, Development Company & Service Provider - Ugam Infotech"
                ogDescription="Ugam Infotech specializes in React.js, Node.js and other backend and frontend languages."
                ogType="website"
                ogUrl={process.env.REACT_APP_URL}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Ugam Infotech - React.js, Node.js, Backend, Frontend"
                twDescription="Ugam Infotech specializes in React.js, Node.js and other backend and frontend languages."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <SliderHeroPart />
            <CompanyStory />
            <TechCompanyTvSlider />
            <CounterPart />
            <JoinOurTeam />
            <OurCulture />
            <OfferingClient />
            <TrustLogoslick slidesFirst={1} slidesSecond={-1} slidesThird={1} />
            {/* <ExploreBrand /> */}
            <WhyChoose />
            <OurExpertisePart />
        </>
    )
}

export default Home