import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ArrowCommon, ServiceHeading } from '../../../Common';

const SEO_Common = () => {

    const theme = useTheme();

    const SeoData = [
        {
            Text: 'Search Term Exploration and Evaluation'
        },
        {
            Text: 'Local Business SEO'
        },
        {
            Text: 'Site and External Enhancements'
        },
        {
            Text: 'Tag Implementation'
        },
        {
            Text: 'Community-Based SEO'
        },
        {
            Text: 'Google Ads(PPC)'
        }
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, pt: { md: 4, xs: 8 } , pb:{md:10 , xs:8} }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6}>
                    <Grid item lg={6} md={6} xs={12}>
                        <Box sx={{pl:{lg:25,md:2}}}>
                            <ServiceHeading text='SEO' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 2, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            Search Engine Optimization (SEO) encompasses a variety of strategies, practices, and techniques designed to enhance the visibility of your website and achieve a top ranking on search engine results pages (SERPs).
                            </Typography>

                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}><b>Ugam Infotech offers premier SEO services</b> globally, adhering to optimal practices that involve refining your website to align with search engine algorithms. SEO services enhance your website’s presence in web indices, thereby increasing the likelihood of appearing prominently on Search Engine Result Pages.
                            </Typography>
                        </Box>

                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sx={{ textAlign: {md:'center' ,sm:'auto'}, width: { md: '100%', sm: '100%', xs: '100%' }, m: '0 auto', height: 'auto' }}>
                        <Box sx={{ mt:{md:0 ,sm:1} , display:'contents' }}>
                            <Grid container>
                                <Box sx={{p:'50px 50px 40px' ,backgroundColor:theme.palette.background.lightDark,borderRadius:6, boxShadow:'17px 14px 40px 0 #00000005' }}>
                                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '22px' }, fontFamily: theme.palette.typography.fontFamily, fontWeight: 700,mb:3 ,lineHeight: { md: '35px', xs: '28px' } }}>Our effective SEO services include
                                    </Typography>
                                    {
                                        SeoData.map((item, i) => (
                                            <Grid key={i} item md={12} sm={12} xs={12} >
                                                <ArrowCommon text={item.Text} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                                            </Grid>
                                        ))
                                    }
                                </Box>

                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default SEO_Common