import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { Heading72px, ParagraphStyle } from '../../../CustomStyle/Common'

const OfferingClient = () => {

    const theme = useTheme();

    const clientOfferdata = [
        {
            title: 'Web Developement',
            desc: 'Web development: outlining roles, responsibilities in creating and sustaining websites.',
            border: { borderBottom: '1px solid #e1e1e1', paddingBottom: '32px' }
        },
        {
            title: 'UI/UX Design',
            desc: 'UX design: techniques and tools for solving real problems and creating functional, reliable solutions.',
            border: { borderBottom: '1px solid #e1e1e1', padding: '32px 0' }
        },
        {
            title: 'Mobile Developement',
            desc: 'Customize mobile apps to meet business needs—a critical development decision.',
            border: { borderBottom: '1px solid #e1e1e1', padding: '32px 0' }
        },
        {
            title: 'API Developement',
            desc: 'APIs facilitate integration, allowing software to adapt to your business needs.',
            border: { paddingTop: '32px' }
        }
    ];

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            <Container maxWidth='xl'>
                <Grid container sx={{ padding: { xs: '40px 0', md: '60px 0', lg: '80px 0' } }}>
                    <Grid item md={12} lg={4.6}>
                        <Heading72px color={theme.palette.primary.main} margin={0} text={'What do we Offer to our Clients?'} />
                    </Grid>
                    <Grid item lg={0.4}></Grid>
                    <Grid item md={12} lg={7} sx={{ mt: { xs: '25px', md: '0px' } }}>
                        {
                            clientOfferdata.map((item, i) => {
                                return (
                                    <Grid container sx={{ ...item.border }} key={i}>
                                        <Grid item md={12} lg={5}>
                                            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: { xs: '20px', md: '25px', color: theme.palette.primary.main }, fontWeight: 500 }}>{item.title}</Typography>
                                        </Grid>
                                        <Grid item md={12} lg={7} sx={{ mt: { xs: '10px', md: '0px' } }}>
                                            <ParagraphStyle text={item.desc} color={theme.palette.primary.main} />
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default OfferingClient