import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Container, Divider, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';

import PamphletComponent from './PamphletComponet';
import { alltab } from '../Json';
import SEO from '../../SEO';

const PamphletDesign = () => {
    const theme = useTheme();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    let Pamphletcomponet1 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet1.jpg`,
            name :'pamphlet1'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet2.jpg`,
            name :'pamphlet2'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet3.jpg`,
            name :'pamphlet3'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet4.jpg`,
            name :'pamphlet4'
        }
    ]

    let Pamphletcomponet2 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet5.jpg`,
            name :'pamphlet5'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet6.jpg`,
            name :'pamphlet6'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet7.jpg`,
            name :'pamphlet7'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet8.jpg`,
            name :'pamphlet8'
        }
    ]

    let Pamphletcomponet3 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet9.jpg`,
            name :'pamphlet9'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet10.jpg`,
            name :'pamphlet10'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet11.jpg`,
            name :'pamphlet11'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet12.jpg`,
            name :'pamphlet12'
        }
    ]

    let Pamphletcomponet4 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet13.jpg`,
            name :'pamphlet13'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet14.jpg`,
            name :'pamphlet14'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet15.jpg`,
            name :'pamphlet15'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/pamphlet-design/pamphlet16.jpg`,
            name :'pamphlet16'
        }
    ]



    return (
        <>
            <SEO
                title="Flyer / Pamphlet Design - Ugam Infotech"
                description="Explore our flyer and pamphlet design services at Ugam Infotech. We create attractive and informative designs to promote your products or services."
                keywords="Ugam, Ugam Infotech, social media design services, engaging designs, visually appealing, social media channels"
                canonical={`${process.env.REACT_APP_URL}/portfolio/pamphlet-design`}
                ogTitle="Flyer / Pamphlet Design - Ugam Infotech"
                ogDescription="Explore our flyer and pamphlet design services at Ugam Infotech. We create attractive and informative designs to promote your products or services."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/pamphlet-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Flyer / Pamphlet Design - Ugam Infotech"
                twDescription="Explore our flyer and pamphlet design services at Ugam Infotech. We create attractive and informative designs to promote your products or services."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Flyer / Pamphlet Design'} />
            <Box sx={{ overflow: 'hidden', width: '100%', backgroundColor: theme.palette.background.lightDark }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "14px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' } }}>Flyer and pamphlet design services involve creating visually appealing and informative marketing materials intended to promote businesses, products, events, or services. These materials are usually designed for printing on paper and are distributed to potential customers or clients.
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{
                            display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                        {
                                            item.text == "Flyer / Pamphlet Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                        }

                                        {index < alltab.length &&  !index == 0 && (
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ margin: '50px 0px 140px' }}>
                            <PamphletComponent allpamphletimage={Pamphletcomponet1} />
                            <PamphletComponent allpamphletimage={Pamphletcomponet2} />
                            <PamphletComponent allpamphletimage={Pamphletcomponet3} />
                            <PamphletComponent allpamphletimage={Pamphletcomponet4} />

                        </Box>
                    </Box>

                </Container >
            </Box>
        </>
    )
}

export default PamphletDesign