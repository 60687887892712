import { useTheme } from '@emotion/react'
import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const AnimationPageDescSecond = () => {
    const theme = useTheme()
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '36px', md: '32px', sm: '28px', xs: '20px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '54px', md: '48px', sm: '36px', xs: '34px', borderLeft: `5px solid ${theme.palette.secondary.main}` }, pl: { md: 5, sm: 4, xs: 2 } }}>
                    We are also known for consistently completing tasks before the deadline. Below are the services we offer at the best and most reasonable costs:
                </Typography>
            </Container>
        </Box>
    )
}

export default AnimationPageDescSecond