import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'

const Magento = () => {
    const theme = useTheme();
    return (
        <Box sx={{ pt: { lg: 16, md: 12, sm: 8, xs: 4 }, pb: { lg: 18, md: 16, sm: 20, xs: 4 }, backgroundColor: theme.palette.background.lightDark, overflow: 'hidden' }}>
            <Container maxWidth='xl'>
                <Box sx={{
                    position: 'relative', zIndex: 1, width: { lg: '80%', md: '100%', sm: '90%', xs: '100%' }, m: '0 auto', '&::after': {
                        content: "''",
                        position: 'absolute',
                        bottom: -20,
                        top: { lg: 100, md: 97, sm: 65, xs: -150 },
                        right: { lg: -128, md: -70, sm: -80, xs: -150 },
                        //left: { lg: -140, md: -120, sm: -100, xs: -150 },
                        width: { lg: '200px', md: '170px', sm: '150px', xs: '120px' },
                        height: { lg: '130px', md: '110px', sm: '90px', xs: '70px' },
                        backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/about.svg`})`,
                        backgroundPosition: 'center',
                        display: { sm: 'block', xs: 'none' },
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        transform: 'rotate(90deg)',
                        zIndex: -1
                    },
                }}>
                    <Box sx={{ height: { sm: '100%', xs: '100px', overflow: 'hidden' } }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/ecommerce-and-cms/magento.jpg`} width='100%' alt='magento' title='"Magento" clearly identifies the platform or context.' loading='lazy' height='100%' />
                    </Box>
                    <Box sx={{ py: { lg: 2, md: 1, xs: 0 }, textAlign: { lg: 'center', md: 'auto', sm: 'center', xs: 'center' }, px: { lg: 0, md: 6, xs: 0 }, background: { md: theme.palette.background.default, sm: 'none' }, position: { md: 'absolute', xs: '' },  left: { md: 25, xs: 0 }, bottom:'6px', width: { lg: '28%', md: '70%', xs: '100%' }, zIndex: 2 }}>
                        <Typography sx={{
                            fontSize: { lg: '48px', md: '48px', sm: '44px', xs: '28px' },
                            fontWeight: 700,
                            letterSpacing: '.42px',
                            lineHeight: { lg: '60px', md: '60px', sm: '60px', xs: '32px' },
                            position: 'relative',
                            margin: '0px 0 0',
                            fontFamily: theme.palette.typography.fontFamily
                        }}>Magento</Typography>
                    </Box>
                </Box>
                <Box sx={{ width: { lg: '80%', md: '100%', sm: '90%', xs: '100%' }, m: '50px auto', }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px',md: '35px', xs: '28px' } }}>Why choose <span style={{ fontWeight: 'bold' }}>Ugam Infotech Magento experts? </span><br />Our e-commerce solutions offer a reliable, customizable, and optimized website tailored to your business needs. Our experienced developers extend functionality to ensure all your website requirements are met.<br />
                        Additionally, Ugam Infotech provides highly effective marketing, analytical, and SEO strategies designed to increase traffic, help customers quickly find your services online, and boost conversions.<br />
                        Want to be listed among the top 10 providers of Magento services? Contact us today!<br />

                        Our team is ready to assist you with top-tier Magento e-commerce services, designed to achieve a zero bounce rate and convert visitors into targeted leads.
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Magento