import { useTheme } from '@emotion/react';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import { ArrowCommon, ServiceHeading } from '../../../Common';

const AnimationMotionGraphic = () => {
    const theme = useTheme();

    const arr = [
        'Instructional Videos',
        'UI Animations',
        'Presentation & Infographics',
        'Concise Videos',
        'Advanced Animated Titles',
        'Text Animations',
        'Logos',
        'Template Editing',
        'Animated Graphic Loops',
        'Animated Commercialss',
        'Product Animations',
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, pt: { md: 10, xs: 8 }, pb: { md: 10, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row' } }}>
                    <Grid item lg={7} md={12} xs={12}>
                        <Box sx={{ position: 'relative' }}>
                            <ServiceHeading text='Motion Graphic' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                Motion graphics are animated segments that create the illusion of movement, often integrated with audio for multimedia projects. These videos frequently include animated text and are effective for conveying ideas through visuals such as graphs, bar charts, timelines, or abstract concepts. Motion graphic videos are commonly used in explainer videos to simplify complex ideas.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 6, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                At Ugam Infotech, our designers are<Typography variant='span' sx={{fontWeight:'bold'}}> ready to tackle any challenge </Typography>, offering clients more than just services—they provide a distinctive experience that positively impacts businesses and leaves clients completely satisfied. Our team of experts boasts extensive experience in producing brand animation videos across various industries, including healthcare, banking, education, NGOs, transportation, hotels, and tourism.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 6, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                We adhere to <Typography variant='span' sx={{fontWeight:'bold'}}>industry best practices </Typography> and methods to create original and powerful animated motion graphics. Our state-of-the-art infrastructure and equipment significantly enhance the quality of our work.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={12} xs={12} >
                        <Box sx={{ position: 'relative' }}>
                            <Box sx={{ p: { lg: 6, md: 5, sm: 4, xs: 3 }, backgroundColor: theme.palette.primary.contrastText, borderRadius: 6 }}>
                                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 600, fontSize: { lg: '26px', md: '24px', xs: '22px' } }}>
                                    As part of our illustration services, we provide the following:
                                </Typography>
                                <Box sx={{ mt: { md: 4, xs: 2 } }}>
                                    {
                                        arr.map((item, i) => (
                                            <ArrowCommon key={i} text={item} color={theme.palette.primary.main} backgroundColor={theme.palette.background.default} />
                                        ))
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}

export default AnimationMotionGraphic