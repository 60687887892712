import { Box, useTheme } from '@mui/material'
import React, { useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ToptoBottom = () => {
    const theme = useTheme();

    const [showTopBtn, setShowTopBtn] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    };
    window.addEventListener("scroll", changeBackground);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {
                showTopBtn && (
                    <Box sx={{
                        backgroundColor: theme.palette.secondary.main,
                        zIndex: 999999,
                        position: 'fixed',
                        borderRadius: 50,
                        width: 54,
                        height: 54,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bottom: { sm: 30, xs: 20 },
                        right: { sm: 30, xs: 20 },
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                    }}
                        onClick={goToTop} >
                        <KeyboardArrowUpIcon sx={{ fontSize: '34px', color: '#f0f4f8' }} />
                    </Box>
                )
            }
        </>
    )
}

export default ToptoBottom