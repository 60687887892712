import { useTheme } from '@emotion/react'
import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const CareerPageDesc = () => {
    const theme = useTheme();
    return (
        <Box sx={{ textAlign: 'center', py: { lg: '50px', md: '20px' }, backgroundColor: theme.palette.background.default }}>
            <Container maxWidth='xl'>
                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, lineHeight: { sm: '48px', xs: '30px' }, fontSize: { sm: '21px', xs: '16px' } }}><span style={{ fontWeight: "700" }}>Ugam Infotech</span> is committed to the values of teamwork and unity. Our goal is to build a cohesive and effective team, where individuals collaborate closely and are driven by a shared vision of success.</Typography>

                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, lineHeight: { sm: '48px', xs: '30px' }, fontSize: { sm: '21px', xs: '16px' } }}>Our employees are deeply connected to our strong <span style={{ color: `${theme.palette.secondary.main}` }}>work culture</span>, and we would be thrilled to have you join us.</Typography>

                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, lineHeight: { sm: '48px', xs: '30px' }, fontSize: { sm: '21px', xs: '16px' }, fontWeight: '700' }}>Working together as a team will be more exciting!</Typography>
            </Container>
        </Box>

    )
}

export default CareerPageDesc