import React from 'react'
import { Box, useTheme } from '@mui/material';

const AnimationSectionImage = () => {
    const theme = useTheme();
    const themeColor = localStorage.getItem('theme');
    return (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{ position: 'relative', left: { md: 0, sm: -200, xs: -80 }, width: { lg: '100%', md: '130%', sm: '200%', xs: '250%' }, backgroundColor: theme.palette.background.lightDark }}>                
                {
                    themeColor === 'light' ?
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/animations/motion-graphics-light.png`} alt='motion-graphics' title='Motion Graphics' loading='lazy' height='auto' width='100%' />
                        :
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/animations/motion-graphics.png`} alt='motion-graphics' title='Motion Graphics' loading='lazy' height='auto' width='100%' />
                }

            </Box>
        </Box>
    )
}

export default AnimationSectionImage