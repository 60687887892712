import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import { ServiceHeading } from '../../../Common';

const ServiceJQuery = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth="xl">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <ServiceHeading text='Jquery' />
                    <Box sx={{ width: { lg: '80px', md: '70px', sm: '60px', xs: '50px' }, height: 'auto' }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/jquery.png`} width='100%' alt='jquery' title='Jquery' loading='lazy' height='auto' />
                    </Box>
                </Box>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: '35px' }}>
                    At Ugam Infotech, we leverage <Typography variant='span' sx={{fontWeight:'bold'}}> top coding languages like JavaScript</Typography> effortlessly. JavaScript is highly popular and our developers utilize it to create interactive websites that seamlessly function across webpages and mobile applications.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 5, md: 4, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: '35px' }}>
                    At Ugam Infotech, we offer premier web and mobile solutions designed to captivate and engage your target audience. To ensure you stay ahead of customer and business demands, we utilize cutting-edge technologies in the development of web and mobile applications.
                </Typography>
            </Container>
        </Box>
    )
}

export default ServiceJQuery