import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { ServiceHeading } from '../../../Common';
import React from 'react'

const AnimationLogoAnimation = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={{ lg: 6, md: 4, xs: 2 }} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' }, display: 'flex', alignItems: 'center' }}>
                    <Grid item lg={7} md={7} xs={12}>
                        <Box>
                            <ServiceHeading text='Logo Animation' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                The logo is the first element that captures the attention of your target customers; the more <Typography variant='span' sx={{fontWeight:'bold'}}>attractive and distinctive</Typography> it is, the higher the likelihood of it being remembered.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                Additionally, a logo is a crucial component of branding, playing a significant role in representing the personality of your business or product. A compelling logo can convey your story and help you connect with your target audience.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                                The key to success lies in choosing the right concept for your company. However, a simple, clean logo design can also effectively capture the audience's attention.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={5} xs={12} sx={{ textAlign: 'center', height: 'auto', display: 'flex', alignItems: 'center' }}>
                        {theme.palette.mode == "light" ?

                            <Box sx={{ width: { md: '50%', sm: '50%', xs: '100%' }, m: '0 auto', mb: { md: 0, xs: 2 } }}>
                                <img src={`${process.env.REACT_APP_URL}/images/pages/services/animations/logo-1.png`} width='100%' height='auto' alt="logo-1" title='Logo' loading='lazy' />
                            </Box>
                            :
                            <Box sx={{ width: { md: '50%', sm: '50%', xs: '100%' }, m: '0 auto', mb: { md: 0, xs: 2 } }}>
                                <img src={`${process.env.REACT_APP_URL}/images/ugam-logo/logo_2.png`} width='100%' height='auto' alt="logo_2" title='Ugam Infotech Logo' loading='lazy' />
                            </Box>
                        }

                    </Grid>


                </Grid>
            </Container>
        </Box>
    )
}

export default AnimationLogoAnimation