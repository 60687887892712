import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import { Box, Container, List, ListItem, Typography, useTheme } from '@mui/material';
import { CommonBtn } from '../../../components/CustomStyle/Common';
import { Link } from 'react-router-dom';

const FooterMobile = ({ company, services, portfolio, contact, socialIcon, copyRightCnt, privacyTremBtn }) => {
    const theme = useTheme();
    return (
        <Container>
            <Accordion defaultExpanded sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.light, borderRadius: '14px !important', overflow: 'hidden', mb: 2, mt: 1 }}>
                <AccordionSummary
                    expandIcon={<AddIcon sx={{ color: theme.palette.secondary.light }} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box
                        sx={{ color: theme.palette.secondary.light, textTransform: 'uppercase' }}>
                        Comapany
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.menuBar }}>
                    <List sx={{ p: 0 }}>
                        {
                            company.map((val, i) =>
                                <Link style={{ textDecoration: 'none' }} key={i} to={val.link} aria-label={val.arialable} title={val.title} rel='follow'>

                                    <ListItem key={i} sx={{
                                        color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 2, cursor: 'pointer', '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}>
                                        {val.title}
                                    </ListItem>
                                </Link>
                            )
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.light, borderRadius: '14px !important', overflow: 'hidden', mb: 2, mt: 1 }}>
                <AccordionSummary
                    expandIcon={<AddIcon sx={{ color: theme.palette.secondary.light }} />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Box
                        sx={{ color: theme.palette.secondary.light, textTransform: 'uppercase' }}>
                        Services
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.menuBar }}>
                    <List sx={{ p: 0 }}>
                        {
                            services.map((val, i) =>
                                <Link style={{ textDecoration: 'none' }} key={i} to={val.link} aria-label={val.arialable} title={val.title} rel='follow'>

                                    <ListItem key={i} sx={{
                                        color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 2, cursor: 'pointer', '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}>
                                        {val.title}
                                    </ListItem>
                                </Link>
                            )
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.light, borderRadius: '14px !important', overflow: 'hidden', mb: 2, mt: 1 }}>
                <AccordionSummary
                    expandIcon={<AddIcon sx={{ color: theme.palette.secondary.light }} />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Box
                        sx={{ color: theme.palette.secondary.light, textTransform: 'uppercase' }}>
                        Portfolio
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.menuBar }}>
                    <List sx={{ p: 0 }}>
                        {
                            portfolio.map((val, i) =>
                                <Link style={{ textDecoration: 'none' }} key={i} to={val.link} aria-label={val.arialable} title={val.title} rel='follow'>

                                    <ListItem key={i} sx={{
                                        color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 2, cursor: 'pointer', '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}>
                                        {val.title}
                                    </ListItem>
                                </Link>
                            )
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                <Box>
                    <Typography sx={{
                        fontSize: { xl: '20px', lg: '16px', md: '16px' },
                        color: theme.palette.secondary.dark,
                        fontWeight: 600,
                        fontFamily: theme.palette.typography.fontFamily
                    }}>
                        Calls
                    </Typography>
                    <List sx={{ opacity: .8 }}>
                        {
                            contact.map((val, i) =>
                                <ListItem key={i} sx={{
                                    color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 1
                                }}>
                                    <b>{val.title}:</b>
                                    &nbsp;
                                    <Link style={{ textDecoration: 'none' }} to={val.link} aria-label={val.arialable} title={val.title} rel='follow'>
                                        <Typography sx={{
                                            fontFamily: theme.palette.typography.fontFamily,
                                            color: theme.palette.primary.main,
                                            fontSize: { xl: '20px', lg: '16px', md: '16px' },
                                            m: 0,
                                            cursor: 'pointer',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}>
                                            {val.number}
                                        </Typography>
                                    </Link>
                                </ListItem>
                            )
                        }
                    </List>
                </Box>
                <Box>
                    <Link to="/contact-us" aria-label='Our contact detail' rel='follow' title='contact us'>
                        <CommonBtn text={"contact us"} backgroundColor={theme.palette.secondary.main} name={"ArrowOutwardIcon"} />
                    </Link>
                </Box>
            </Box>
            <Box sx={{ mt: 4, display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-between' }}>
                <Box>
                    <Typography sx={{
                        fontFamily: theme.palette.typography.fontFamily,
                        fontSize: { xl: '20px', lg: '16px', md: '16px' },
                        color: theme.palette.secondary.dark,
                        mb: 2,
                        fontWeight: 600
                    }}>
                        Follow Us
                    </Typography>
                    <List sx={{ display: 'flex', gap: { xl: '16px', lg: '8px', xs: '8px' } }}>
                        {
                            socialIcon.map((val, i) =>
                                <Link to={val.link} key={i} target='_blank' style={{ color: theme.palette.primary.main }} aria-label={val.arialable} title={val.name} rel='noopener noreferrer'>
                                    <ListItem sx={{
                                        color: theme.palette.primary.main, width: { sm: '55px', xs: '40px' }, height: { sm: '55px', xs: '40px' }, p: { sm: 2, xs: 1 }, background: 'transparent', border: `1px solid ${theme.palette.secondary.dark}`, borderRadius: '50%', cursor: 'pointer', transition: 'all .2s ease-in-out', '&:hover': {
                                            backgroundColor: '#fff', color: '#1a2a1d'
                                        }
                                    }}>
                                        <val.icon />
                                    </ListItem>
                                </Link>

                            )
                        }
                    </List>
                </Box>
            </Box>
            <Box sx={{ mt: 6 }}>
                <Box>
                    <Typography sx={{
                        fontFamily: theme.palette.typography.fontFamily,
                        fontSize: { xl: '20px', lg: '16px', md: '16px' },
                        color: theme.palette.secondary.dark,
                        mb: 2,
                        fontWeight: 600
                    }}>
                        Copyright By
                    </Typography>
                    <List sx={{ width: '100%' }}>
                        <ListItem sx={{
                            color: theme.palette.primary.main,
                            p: 0,
                            display: 'block',
                            justifyContent: 'space-between'
                        }}>
                            <Box component='span'>
                                {copyRightCnt}
                            </Box>
                            {/* <Box sx={{ mt: 2 }}>
                                {
                                    privacyTremBtn.map((val, i) =>
                                        <Typography key={i} sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, p: 0, width: '100%', display: 'inline', whiteSpace: { md: 'pre', xs: 'none' } }}>
                                            {val.text}
                                            {
                                                i == 0 ? <Box component="span" sx={{ display: 'inline', px: { lg: 2, xs: 2 } }}>|</Box> : ''
                                            }
                                        </Typography>
                                    )
                                }
                            </Box> */}
                        </ListItem>

                    </List>
                </Box>
            </Box>
        </Container>
    )
}

export default FooterMobile