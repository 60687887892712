import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { ServiceHeading } from '../../../Common';

const DesignPrototypeWireframe = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' } ,display:'flex',alignItems:'center'}}>
                    <Grid item lg={7} md={7} xs={12}>
                        <Box>
                            <ServiceHeading text='Wireframe and Simulation' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                            What Wireframing and Prototype Services Can Do for Your Business    
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                            If you want to transform your ideas and imagination into a visual format, wireframing and prototypes are invaluable services to consider. They help define the application's layout structure, interface components, navigational flow, user journey, and overall usability, tailored to meet your business requirements.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                            At Ugam Infotech, our team has years of <Typography variant='span' sx={{fontWeight:'bold'}}>experience in delivering</Typography> reliable web design and development services globally. We use prototyping methods that enable rapid iteration cycles, speeding up the creation and testing of wireframes.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                            The Ugam Infotech team has successfully completed numerous projects by providing comprehensive wireframing and prototyping services. We can streamline your workflow and help your top brands stay competitive.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={5} xs={12} sx={{ textAlign: 'center', height: 'auto' }}>
                        <Box sx={{ width: { md: '100%', sm: '70%', xs: '100%' }, m: '0 auto', mb: { md: 0, xs: 2 } }}>
                            <img src={`${process.env.REACT_APP_URL}/images/pages/services/designing/prototype-and-wireframe.png`} width='100%' height='auto' alt='prototype-and-wireframe' loading='lazy' title='Prototype and Wireframe' />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default DesignPrototypeWireframe