import { Box, Container, Typography, useTheme } from '@mui/material';
import React from 'react';
import PageTilteSection from '../../../../commonComponents/PageTitleSection';

const AnimationVideosMobile = () => {
    const theme = useTheme();
    return (
        <Box>
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/animations/animation-video.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'Videos for Mobile'} />
            <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
                <Container maxWidth='xl'>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        At Ugam Infotech, we have developed solutions tailored for audiences <Typography variant='span' sx={{fontWeight:'bold'}}>seeking top-notch video creation</Typography> and download services.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        Our team, with backgrounds in film production, commercial video, visual effects, and mobile advertising, excels in creating <Typography variant='span' sx={{fontWeight:'bold'}}>eye-catching mobile videos</Typography>. We focus on branding, ensuring that all video content we produce aligns with your company’s concept and logo.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        Video content is an excellent choice for businesses looking to launch ongoing marketing campaigns. If you need a high-quality mobile video that delivers your message and is optimized for social media sharing, feel free to contact us for effective and efficient mobile video services.
                    </Typography>
                </Container>
            </Box>
        </Box>
    )
}

export default AnimationVideosMobile