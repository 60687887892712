import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'


const PageHeading = ({ text }) => {
    const theme = useTheme();
    return (
        <Container maxWidth="xl">
            <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: { lg: '445px', md: '365px', sm: '333px', xs: '250px' }, zIndex: -1 }}></Box>
            <Box sx={{ width: '100%', position: 'relative', pb: { lg: 20, md: 16, sm: 14, xs: 12 }, pt: { lg: 30, md: 24, sm: 24, xs: 18 } }}>
                <Box sx={{ position: 'absolute', maxWidth: '100%', height: { sm: '90px', xs: '70px' }, bottom: 10, right: { lg: 100, md: 100, sm: 50, xs: -10 } }}>
                    <img height='100%' width='100%' src={`${process.env.REACT_APP_URL}/images/about.svg`} alt='about' title='Creative Dotted Design' loading='eager'></img>
                </Box>
                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, textAlign: 'center', fontSize: { lg: '48px', md: '40px', sm: '34px', xs: '28px' }, fontWeight: '800', textTransform: 'capitalize' }} >
                    {text}
                </Typography>
            </Box >
        </Container>
    )
}

export default PageHeading