import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react'
import CountUp from 'react-countup';

const CounterPart = () => {

    const theme = useTheme();

    const counterData = [
        {
            title: 'SERVICE',
            count: 24,
            symbol: '*7',
            icon: `${process.env.REACT_APP_URL}/images/counter-icons/counter_01.svg`,
            border: {
                borderBottom: { sm: '0', xs: '1px solid #e1e1e1' },
                borderRight: { xs: '0', sm:'1px solid #e1e1e1', md: '1px solid #e1e1e1' },
            }
        },

        {
            title: 'FINISHED PROJECTS',
            count: 140,
            symbol: '+',
            icon: `${process.env.REACT_APP_URL}/images/counter-icons/counter_03.svg`,
            border: {
                borderBottom: { sm: '0', xs: '1px solid #e1e1e1' },
                borderRight: { xs: '0', sm: '0', md:'1px solid #e1e1e1' },
            }
        },
        {
            title: 'HOURS INVESTED',
            count: 4038,
            symbol: '+',
            icon: `${process.env.REACT_APP_URL}/images/counter-icons/counter_02.svg`,
            border: {
                borderBottom: { sm: '0', xs: '1px solid #e1e1e1' },
                borderRight: { xs: '0', sm: '1px solid #e1e1e1' },
            }
        },

        {
            title: 'SKILLED EXPERTS',
            count: 10,
            symbol: '+',
            icon: `${process.env.REACT_APP_URL}/images/counter-icons/counter_04.svg`,
            border: {
                borderBottom: { sm: '0', xs: '1px solid #e1e1e1' },
                borderRight: { xs: '0', sm: '0' },
            }
        },
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark }}>
            <Container maxWidth="xl" sx={{ py: { md: 10, xs: 8 } }}>
                <Grid container>
                    {
                        counterData.map((val, i) =>
                            <Grid key={i} md={3} sm={6} xs={12} item sx={{ ...val.border, my: { md: 0, sm: 3 ,xs:2} }}>
                                <Box sx={{ textAlign: 'center' }}>

                                    <Box>
                                        <Box sx={{ fontSize: { lg: '48px', md: '44px', xs: '40px' }, fontWeight: 700, color: theme.palette.secondary.main }}>
                                            <CountUp
                                                end={val.count}
                                                enableScrollSpy="true"
                                                duration={3}
                                            >
                                                {({ countUpRef }) => (
                                                    <>
                                                        <Box component='span' ref={countUpRef} /><Box component='span'>{val.symbol}</Box>
                                                    </>
                                                )}
                                            </CountUp>

                                        </Box>
                                        <Box sx={{
                                            py: 5,
                                            position: 'relative',
                                            '&::before': {
                                                content: '""',
                                                background: `url(${val.icon})`,
                                                position: 'absolute',
                                                backgroundSize: 'containt',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                top: 0,
                                                left: 0,
                                                height: '100%',
                                                width: '100%',
                                                zIndex: -1,
                                                opacity: .6
                                            }
                                        }} >
                                            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: { lg: '20px', md: '18px', xs: '16px' }, fontWeight: 600 }}>{val.title}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        </Box>
    )
}

export default CounterPart