import React, { useEffect, useState } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Container, Divider, useTheme } from '@mui/material'

import { CommonBtn } from '../../CustomStyle/Common';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css';
import { alltab } from '../Json'
import SEO from '../../SEO';

const GraphicDesign = () => {
    const theme = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0);
        new WOW.WOW().init();
    }, [])


    return (
        <>
            <SEO
                title="Graphic Design - Ugam Infotech"
                description="Explore our graphic design services at Ugam Infotech. We create stunning visual designs to enhance your brand identity and communication."
                keywords="Ugam, Ugam Infotech, graphic design services, visual designs, brand identity, communication design"
                canonical={`${process.env.REACT_APP_URL}/portfolio/graphic-design`}
                ogTitle="Graphic Design - Ugam Infotech"
                ogDescription="Explore our graphic design services at Ugam Infotech. We create stunning visual designs to enhance your brand identity and communication."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/graphic-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Graphic Design - Ugam Infotech"
                twDescription="Explore our graphic design services at Ugam Infotech. We create stunning visual designs to enhance your brand identity and communication."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Graphic Design'} />
            <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { lg: 10, xs: 8 } }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "12px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' }, fontFamily: theme.typography.fontFamily }}>We provide top-tier web design and web development software solutions to clients globally. Ugam Infotech has the comprehensive capabilities needed to create an ideal platform for marketing strategy and growth advancement.</Box>
                    </Box>
                    <Box sx={{ width: '100%', mb: 12 }}>

                        <Box sx={{
                            display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>

                                        {
                                            item.text == "Graphic Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark,
                                                }} aria-label={item.arialable} title={item.text} rel='follow'
                                                >{item.text}</Link>
                                        }

                                        {index < alltab.length  && !index == 0 && (
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>


                        <Box sx={{ my: 7, mx: 0 }}>
                            <Box>
                                {

                                    alltab.slice(1, 8)?.map((item, index) => (
                                        <Box  key={index} className="wow animate__animated animate__pulse" sx={{ my: 4, mx: 'auto', height: { lg: '516px', sm: 'auto', xs: '174px' }, width: '80%', position: 'relative' }}>
                                            <img src={item.img} width='100%' height='100%' loading='lazy' title='Graphic Design' alt={item.name}
                                             />
                                            {
                                                index % 2 == '0' ?
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        right: { sm: '2%', xs: '50%' },
                                                        top: { sm: '30%', xs: '-10%' },
                                                        transform: { xs: 'translate(50%, 50%)', sm: 'translate(0, 0)' },
                                                        textAlign: { sm: 'right !important', xs: 'center' },
                                                        width: { md: '350px', sm: '300px', xs: '240px' },
                                                        height: { md: '350px', sm: '300px', xs: '104px' }
                                                    }}
                                                    ><Box component='h4' sx={{ fontSize: { lg: '48px', md: '40px', sm: '30px', xs: '19px' }, letterSpacing: '1px', fontWeight: 600, marginTop: '0px', marginBottom: { md: '40px', sm: '20px' }, fontFamily: theme.typography.fontFamily, color: theme.palette.secondary.contrastText }}>{item.text}</Box>
                                                        <Link to={item.link} sx={{ textDecoration: 'none' }} aria-label={item.arialable} title={item.text} rel='follow'>
                                                            <CommonBtn text={'Show more'} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} />
                                                        </Link>
                                                    </Box> :

                                                    <Box sx={{
                                                        position: 'absolute',
                                                        left: { sm: '2%', xs: '50%' },
                                                        top: { sm: '30%', xs: '50%' },
                                                        transform: { xs: 'translate(-50%, -50%)', sm: 'translate(0, 0)' },
                                                        textAlign: { sm: 'left !important', xs: 'center' },
                                                        width: { md: '350px', sm: '300px', xs: '240px' },
                                                        height: { md: '350px', sm: '300px', xs: '104px' }
                                                    }}>

                                                        <Box component='h4' sx={{ fontSize: { lg: '48px', md: '40px', sm: '30px', xs: '19px' }, letterSpacing: '1px', fontWeight: 600, marginTop: '0px', marginBottom: { md: '40px', sm: '20px' }, fontFamily: theme.typography.fontFamily, color: theme.palette.secondary.contrastText }}>{item.text}</Box>
                                                        <Link to={item.link} sx={{ textDecoration: 'none' }} aria-label={item.arialable} title={item.text} rel='follow'>
                                                            <CommonBtn text={'Show more'} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} sx={{ with: { xs: '144px', md: '0px' }, height: { xs: '50px', md: '0px' }, fontSize: '12px', md: '0px' }} />
                                                        </Link>
                                                    </Box>

                                            }
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>

                    </Box>

                </Container >
            </Box>
        </>
    )
}

export default GraphicDesign