import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import PageTilteSection from '../../../../commonComponents/PageTitleSection';
import PageHeading from '../../../../commonComponents/PageHeading';
import { ServiceHeading } from '../../../Common';

const DesignPrintMaterial = () => {
    const theme = useTheme();
    return (
        <Box>
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/designing/printer-imgae.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'Print Material'} />
            <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { lg: 10, xs: 8 } }}>
                <Container maxWidth='xl'>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        At Ugam Infotech, we specialize in creating business cards, brochures, flyers, and pamphlets. Print marketing materials are incredibly effective for <b>boosting sales and generating leads for your business</b>. Our goal is to create a strong first impression, establish reliable connections with your target customers, and convey your message through our marketing and sales materials, ultimately driving high profits.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                        While <b>the demand for print materials</b> remains strong among today’s marketers, we at Ugam Infotech focus on a strategy that is centered on driving sales growth.
                    </Typography>
                    <Box sx={{ mt: { lg: 6, md: 5, sm: 4, xs: 2 } }}>
                        <ServiceHeading text='Why pick Ugam Infotech as your solution?' />
                        <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            After giving your precious time to our page, below are the reasons that will ensure you why you choose Ugam Infotech for graphic designing services.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            At Ugam Infotech, our team includes <b>highly qualifiedand experienced UI designers</b> who have successfully handled numerous live projects, consistently receiving positive feedback from our clients.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            As a leading graphic design firm in India, we have the expertise to <b>create unique and eye-catching</b> designs for flyers, advertisements, email campaigns, and various other materials.
                        </Typography>
                        <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            If you are seeking premier product branding services, you’ve come to the right place. Meet the Ugam Infotech team and discover how we can enhance your brand with our exceptional services.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default DesignPrintMaterial