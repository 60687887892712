
import { Box, Divider, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import WOW from 'wowjs';
import 'animate.css';

const BrandDesignComponent = ({ allbrandDesignimage }) => {
    
    useEffect(() => {
        new WOW.WOW({
            offset:100
        }).init();
    }, [])

    const theme = useTheme();

    return (
        <>
            <Box sx={{ margin: '30px 0px' }}>
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',

                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            background: theme.palette.background.default,
                            height: '66%',
                            width: { lg: '70%', xs: '100%' },
                            right: { lg: '15%', md: '0%', sm: '0%' },
                            top: '51%',
                            zIndex: '-1',
                            transform: 'translateY(-50%)',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            borderRadius: '10px',
                            display: { sm: 'block', xs: 'none' }
                        },

                    }}
                >
                    <Box sx={{
                        '&::after': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `6px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: { lg: '64%', md: '90%', sm: '88%' },
                            right: { lg: '18%', md: '5%', sm: '6%' },
                            top: '17%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        '&::before': {
                            position: 'absolute',
                            content: '""',
                            display: 'block',
                            borderTop: `8px solid ${theme.palette.secondary.main}`,
                            height: '10px',
                            width: { lg: '64%', md: '90%', sm: '88%' },
                            right: { lg: '18%', md: '5%', sm: '6%' },
                            bottom: '15%',
                            zIndex: '-1',
                            display: { sm: 'block', xs: 'none' }

                        },
                        display: 'flex',
                        justifyContent: 'center'

                    }}>
                        <Box sx={{ padding: { md: '46px 30px', xs: '20px' }, width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: { lg: '80%', md: '96%', xs: '100%' }, display: 'flex', flexWrap: 'wrap', padding: { sm: '10px', xs: '0px' } }}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', margin: { sm: '10px 0px', xs: '0px' }, flexWrap: { md: 'nowrap', xs: 'wrap' } }}>
                                    {
                                        allbrandDesignimage?.map((item ,index) => (
                                            <Box key={index}>
                                                {
                                                    <Box  className="wow animate__animated animate__pulse" sx={{ width: { lg: '800px', md: '670px', sm: '570px', xs: '100%' }, height: { lg: '770px', md: '620px', sm: '520px' }, marginTop: '10px' }}>
                                                        <img src={item.img} width='100%' height='100%' alt={item.name} title='Creative Brand Design' loading='lazy' />
                                                    </Box>
                                                }
                                            </Box>
                                        ))
                                    }
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </>
    )
}

export default BrandDesignComponent