import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import PageTilteSection from '../../../../commonComponents/PageTitleSection';


const AnimationMobileWebappVideo = () => {
    const theme = useTheme();
    return (
        <Box>
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/animations/animation-video1.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'Mobile and Web app Video'} fontSize={'39px'}  textAlign={'center'} />
            <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
                <Container maxWidth='xl'>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                        At Ugam Infotech, we provide <Typography variant='span' sx={{fontWeight:'bold'}}> user-friendly video-playing services for all applications and software</Typography>. Our professional team specializes in creating videos that are seamlessly accessible on mobile applications and website-based platforms.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, mt: { md: 5, xs: 3 }, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                        With years of experience, we understand the importance of creativity and high resolution. We prioritize our customers' preferences, recognizing that conveying a message effectively requires more than just creating an attractive video—it needs to be compelling and action-oriented.</Typography>
                </Container>
            </Box>
        </Box>
    )
}

export default AnimationMobileWebappVideo