import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CommonHeading } from '../../../../CustomStyle/Common';

const AboutOurMission = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, textAlign: 'center', py: { md: 10, xs: 8 } }} >
            <Container maxWidth='xl'>
                <Box sx={{ width: { lg: '94%', md: '96%', xs: '98%' }, m: '0 auto' }}>
                    <CommonHeading text={'Our Mission'} fontSize={'24px'} margin={'0px 20px'} fontFamily={'gilroy-extraBold'} color={theme.palette.primary.main} />
                    <Typography sx={{
                        fontSize: { lg: '44px', md: '32px', sm: '28px', xs: '22px' },
                        fontWeight: 200,
                        fontFamily: theme.palette.typography.fontFamily,
                        color: theme.palette.primary.main,
                        letterSpacing: '.42px',
                        lineHeight: { lg: '70px', md: '55px', sm: '50px', xs: '30px' },
                        position: 'relative',
                        margin: '20px 0 0',
                    }}>Our mission is to empower businesses with innovative IT solutions that propel growth and success. We achieve excellence by providing top-tier software and services, nurturing continuous innovation, and cultivating robust partnerships with our clients.</Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default AboutOurMission