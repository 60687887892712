import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Container, Divider, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';

import BusinessCardComponet from './BusinessCardComponet';
import { alltab } from '../Json';
import SEO from '../../SEO';



const BusinessCardDesign = () => {
    const theme = useTheme();

    let businesscardcomponet1 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-1.jpg`,
            name: 'business-card-1'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-2.jpg`,
            name: 'business-card-2'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-3.jpg`,
            name: 'business-card-3'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-4.jpg`,
            name: 'business-card-4'
        }
    ]

    let businesscardcomponet2 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-5.jpg`,
            name: 'business-card-5'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-6.jpg`,
            name: 'business-card-6'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-7.jpg`,
            name: 'business-card-7'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-8.jpg`,
            name: 'business-card-8'
        }
    ]
    let businesscardcomponet3 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-9.jpg`,
            name: 'business-card-9'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-10.jpg`,
            name: 'business-card-10'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-11.jpg`,
            name: 'business-card-11'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-12.jpg`,
            name: 'business-card-12'
        }
    ]
    let businesscardcomponet4 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-13.jpg`,
            name: 'business-card-13'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-14.jpg`,
            name: 'business-card-14'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-15.jpg`,
            name: 'business-card-15'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/business-card-design/business-card-16.jpg`,
            name: 'business-card-16'
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <SEO
                title="Business Card Design - Ugam Infotech"
                description="Explore our business card design services at Ugam Infotech. We create professional and eye-catching business cards to leave a lasting impression."
                keywords="Ugam, Ugam Infotech, business card design services, professional business cards, eye-catching designs, brand impression"
                canonical={`${process.env.REACT_APP_URL}/portfolio/business-card-design`}
                ogTitle="Business Card Design - Ugam Infotech"
                ogDescription="Explore our business card design services at Ugam Infotech. We create professional and eye-catching business cards to leave a lasting impression."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/business-card-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Business Card Design - Ugam Infotech"
                twDescription="Explore our business card design services at Ugam Infotech. We create professional and eye-catching business cards to leave a lasting impression."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Business Card Design'} />
            <Box sx={{ overflow: 'hidden', width: '100%', backgroundColor: theme.palette.background.lightDark }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "14px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' } }}>Our business card design services focus on creating custom business cards that portray a business or individual in a professional and memorable manner. Business cards are usually small and portable, making them an ideal tool for networking and marketing.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{
                            display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                        {
                                            item.text == "Business Card Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                        }

                                        {index < alltab.length && !index == 0 && (
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ margin: '50px 0px 140px' }}>
                            <BusinessCardComponet allcardimage={businesscardcomponet1} />
                            <BusinessCardComponet allcardimage={businesscardcomponet2} />
                            <BusinessCardComponet allcardimage={businesscardcomponet3} />
                            <BusinessCardComponet allcardimage={businesscardcomponet4} />
                        </Box>
                    </Box>

                </Container >
            </Box>
        </>
    )
}

export default BusinessCardDesign