import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import PageTilteSection from '../../commonComponents/PageTitleSection'
import Desc from './Components/Desc'
import { WordPress } from './Components/WordPress'
import WooCommerce from './Components/Woo-Commerce'
import Sopify from './Components/Sopify'
import OpenCart from './Components/OpenCart'
import Magento from './Components/Magento'
import BigCommerce from './Components/BigCommerce'
import ServiceOtherCommon from '../Common/ServiceOtherCommon'
import SEO from '../../SEO'


const EcommerceAndCMS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <SEO
        title="E-Commerce & CMS Solutions - Ugam Infotech"
        description="Discover our e-commerce and CMS solutions at Ugam Infotech. We provide robust and scalable platforms using React.js, Node.js, and more."
        keywords="Ugam, Ugam Infotech, e-commerce solutions, CMS solutions, React.js, Node.js, web development, scalable platforms, robust platforms"
        canonical={`${process.env.REACT_APP_URL}/services/ecommerce-and-cms`}
        ogTitle="E-Commerce & CMS Solutions - Ugam Infotech"
        ogDescription="Discover our e-commerce and CMS solutions at Ugam Infotech. We provide robust and scalable platforms using React.js, Node.js, and more."
        ogType="website"
        ogUrl={`${process.env.REACT_APP_URL}/services/ecommerce-and-cms`}
        ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
        twTitle="E-Commerce & CMS Solutions - Ugam Infotech"
        twDescription="Discover our e-commerce and CMS solutions at Ugam Infotech. We provide robust and scalable platforms using React.js, Node.js, and more."
        twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
      />
      <PageHeading text={'E-Commerce & CMS'} />
      <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/ecommerce-and-cms/ecommerce_1.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'E-Commerce & CMS'} />
      <Desc />
      <WordPress />
      <WooCommerce />
      <Sopify />
      <Magento />
      <OpenCart />
      <BigCommerce />
      <ServiceOtherCommon />
    </>
  )
}

export default EcommerceAndCMS