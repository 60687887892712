import { useTheme } from '@emotion/react';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import { ServiceHeading } from '../../../Common';

const ServiceJavascript = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' } }}>
                    <Grid item lg={7} md={8} xs={12}>
                        <Box>
                            <ServiceHeading text='Javascript' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                At Ugam Infotech, we deliver cutting-edge web and mobile solutions that captivate your target audience. We stay ahead of customer and business demands by leveraging trending technologies to design innovative web and mobile applications.
                            </Typography>
                            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                By harnessing JavaScript, a versatile text-based programming language, we develop responsive websites that enhance both client-side and server-side functionality. This enables us to create web pages that are not only visually appealing but also interactive.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={3.5} md={4} xs={12} sx={{ textAlign: 'center', width: { md: '100%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto' }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/javascript.png`} width='100%' height='auto' alt="javascript" title='javascript' loading='lazy' />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default ServiceJavascript