import { Box, Typography, styled } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';

const ServiceHeadingStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    // fontSize: { lg: '48px', md: '42px', sm: '38px', xs: '32px' },
    fontSize: '48px',
    fontWeight: 600,
    letterSpacing: 1,
    [theme.breakpoints.down('lg')]: {
        fontSize: '46px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '40px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
    },
}))

export const ServiceHeading = ({ text }) => (
    <ServiceHeadingStyle>
        {text}
    </ServiceHeadingStyle>
)
const ArrowRoundBox = styled(Box)(({ theme }) => ({
    color: theme.palette.secondary.main,
    padding: '2px 8px',
    borderRadius: 50,
    fontFamily: '"Poppins", sans-serif;',
    textAlign: 'center',
    height: '100%',
    [theme.breakpoints.down('md')]: {
        padding: '0px 4px',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0px 4px',
    },
}))

export const ArrowCommon = ({ text, color, fontWeight, backgroundColor }) => (
    <Box sx={{ display: 'flex', alignItems: { md: 'center' }, gap: { lg: '8px', md: '16px', sm: '14px', xs: '12px' }, mb: { md: 2, xs: 1 } }}>
        <ArrowRoundBox sx={{ backgroundColor: backgroundColor }}>
            <DoneIcon sx={{ mt: .6, fontSize: { lg: '26px', md: '24px', sm: '22px', xs: '18px' } }} />
        </ArrowRoundBox>
        <Typography sx={{ fontFamily: '"Poppins", sans-serif', color: color, fontWeight: fontWeight, fontSize: { md: '17px', sm: '16px', xs: '14px' } }}>
            {text}
        </Typography>
    </Box>
)
