import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ServiceHeading } from '../../../Common';

const BigCommerce = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pt: { lg: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ backgroundColor: theme.palette.background.default, position: 'relative', p: { lg: 10, md: 2.5, xs: 2 }, mx: { lg: 12, md: 8.5, sm: 0 }, mb: { md: -25, sm: 2, xs: 3 }, zIndex: 1 }}>
                    <ServiceHeading text='Big Commerce' />
                    <Typography sx={{ fontSize: { sm: '18px', xs: '16px' }, lineHeight: { sm: '42px', xs: '34px' }, fontFamily: theme.palette.typography.fontFamily }}>At  Infotech, we specialize in creating, enhancing, and managing effective enterprise eCommerce stores with our expert team. Using a range of industry-leading tools, we empower digital commerce for cutting-edge brands, producers, and distributors worldwide, helping companies of all types reach their full potential.</Typography>
                    <Typography sx={{ fontSize: { sm: '18px', xs: '16px' }, lineHeight: { sm: '42px', xs: '34px' }, mt: 3, fontFamily: theme.palette.typography.fontFamily }}>We are committed to driving more traffic to generate targeted leads and utilize intuitive shopping and marketing tools to boost sales. Our support is available at every step to address issues related to big commerce.<br />
                        Our fantastic team leverages resources efficiently to meet client needs promptly. Most of our business comes from referrals and word of mouth, and we pride ourselves on maintaining a friendly working environment.<br />
                        business comes from referrals and word of mouth and we have a <span style={{ fontWeight: 'bold' }}>friendly working environment.</span><br />
                        Making our clients and partners happy and satisfied is our top priority.</Typography>
                </Box>
                <Box sx={{ zIndex: -1 }}>
                    <img width='100%' height='100%' src={`${process.env.REACT_APP_URL}/images/pages/services/ecommerce-and-cms/bigcommerceImg.jpg`} alt='bigcommerceImg' loading='lazy' title='E-Commerce Checkout Process' />
                </Box>
            </Container>
        </Box>
    )
}

export default BigCommerce