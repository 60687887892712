import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { ServiceHeading } from '../../../Common';
import React from 'react'


const AnimationExplainerVideo = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={{ lg: 6, md: 4, xs: 2 }} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' } }}>
                    <Grid item lg={7} md={7} xs={12}>
                        <Box>
                            <ServiceHeading text='Explainer Video' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                An explainer video is a short, engaging video that highlights a company's product, service, or business idea effectively, making it ideal for marketing and sales objectives. These videos are commonly hosted on landing pages or featured on the home pages of websites. Some businesses also use platforms like Facebook and other social media to promote their goods or services through these videos. We focus on these aspects to deliver the <Typography variant='span' sx={{fontWeight:'bold'}}> best services to our clients</Typography> and achieve the desired results.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                We start from scratch and provide comprehensive services, including concept development, scriptwriting, design, animation, music, and sound—everything a professional animated video needs. If you have a clear vision, we are happy to collaborate with you.<Typography variant='span' sx={{fontWeight:'bold'}}> We enjoy working with companies or agencies</Typography> that already have a screenplay or other pre-existing materials.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Are you unsure how to creatively share your ideas with the world?
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                We can assist you in posting your message on social media or through search engines. All our programs are designed to offer strategic services that elevate your business to the top.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={5} xs={12} sx={{ textAlign: 'center', height: 'auto', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: { md: '80%', sm: '70%', xs: '100%' }, m: '0 auto', mb: { md: 0,sm:8, xs:2  } }}>
                            <img src={`${process.env.REACT_APP_URL}/images/pages/services/animations/explainer-video-analysis.png`} width='100%' height='auto' alt="explainer-video-analysis" title='Explainer Video Engagement Analysis' loading='lazy' />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default AnimationExplainerVideo