import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { CommonHeading } from '../../../../CustomStyle/Common'
import { useTheme } from '@emotion/react'

const CareerJoin = () => {
    const theme = useTheme();
    return (
        <Box >
            <Box sx={{
                textAlign: 'center', backgroundColor: theme.palette.background.lightDark, maxWidth: { md: '100%', sm: '100%', xs: '500px' }, pt: { md: 12, sm: 10, xs: 8 }
            }}>
                <CommonHeading text={'Want to join?'} fontSize={'24px'} margin={'0px 20px'} fontFamily={'gilroy-extraBold'} color={theme.palette.primary.main} />
                <Typography sx={{
                    fontSize: { lg: '72px', md: '60px', sm: '40px', xs: '30px' },
                    fontWeight: 700,
                    color: theme.palette.secondary.main,
                    lineHeight: { lg: '74px', md: '50px', sm: '50px', xs: '40px' },
                    mt: 2,
                    fontFamily: theme.palette.typography.fontFamily
                }}>Grow your career with us</Typography>
            </Box>
        </Box>

    )
}

export default CareerJoin