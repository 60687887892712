import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';

const Desc = () => {
    const theme = useTheme();

    const Data = [
        {
            image: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/digitalMarketingDescImage_1.png`,
            text: 'Search Engine Optimization',
            name: 'digitalMarketingDescImage_1'
        },
        {
            image: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/digitalMarketingDescImage_2.png`,
            text: 'Social Media Marketing',
            name: 'digitalMarketingDescImage_2 '
        },
        {
            image: `${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/digitalMarketingDescImage_3.png`,
            text: 'Content Marketing',
            name: 'digitalMarketingDescImage_3'
        }
    ]

    return (
        <Box sx={{ pt: { lg: 16, md: 12, sm: 8, xs: 4 }, pb: 2, backgroundColor: theme.palette.background.lightDark, overflow: 'hidden' }}>
            <Container maxWidth='xl'>
                <Box sx={{
                    position: 'relative', zIndex: 1, width: { lg: '70%', md: '100%', sm: '90%', xs: '100%' }, m: '0 auto', '&::after': {
                        content: "''",
                        position: 'absolute',
                        bottom: -20,
                        top: { lg: -20, md: 97, sm: 65, xs: -150 },
                        //right: { lg: -128, md: -70, sm: -80, xs: -150 },
                        left: { lg: -140, md: -120, sm: -100, xs: -150 },
                        width: { lg: '200px', md: '170px', sm: '150px', xs: '120px' },
                        height: { lg: '130px', md: '110px', sm: '90px', xs: '70px' },
                        backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/about.svg`})`,
                        backgroundPosition: 'center',
                        display: { sm: 'block', xs: 'none' },
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        transform: 'rotate(270deg)',
                        zIndex: -1
                    },
                }}>
                    <Box sx={{ height: { sm: '0%', xs: '100px', overflow: 'hidden' } }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/digitalMarketingImg.jpg`} width='100%' alt='digitalMarketingImg' title='The Evolution of Digital Advertising From Clicks to Conversions' loading='lazy' height='auto' />
                    </Box>
                    <Box sx={{ borderRadius: { sm: 8, xs: 3 }, py: { lg: 0, md: 2, xs: 0 }, textAlign: { lg: 'center', md: 'auto', sm: 'center', xs: 'center' }, px: { lg: 0, md: 6, xs: 0 }, background: theme.palette.background.default, position: { md: 'absolute', xs: '' }, bottom: { lg: 0, md: 0, sm: 50, xs: 30 }, position: 'relative', left: { lg: -100, md: 0, xs: 0 }, m: '0 auto', width: { lg: '120%', md: '100%', sm: '60%', xs: '80%' }, zIndex: 2 }}>
                        <Box sx={{
                            fontSize: { lg: '48px', md: '48px', sm: '44px', xs: '28px' },
                            fontWeight: 700,
                            letterSpacing: '.42px',
                            lineHeight: { lg: '60px', md: '60px', sm: '60px', xs: '32px' },
                            margin: '0 0 0',
                            textAlign: { md: 'start', sm: 'center' }
                            // border: '1px solid black',
                        }}>
                            <Grid container sx={{ py: 5, pr: { md: 10, sm: 0, xs: 2 }, pl: { lg: 20, md: 8, xs: 2 }, alignItems: 'center' }}>
                                {
                                    Data.map((item, i) => (
                                        <Grid key={i} item lg={4} md={4} sm={12} xs={12}>
                                            <Box sx={{
                                                display: { md: 'flex', sm: 'block' }, position: 'relative', alignItems: 'center', textAlign: 'left', '& ::before': {
                                                    backgroundColor: theme.palette.secondary.main,
                                                    content: '""',
                                                    height: '100%',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: { lg: 30, md: 0 },
                                                    width: { md: i == 2 ? 0 : '1px', sm: '0px' }

                                                }, '& ::after': {
                                                    backgroundColor: theme.palette.secondary.main,
                                                    content: '""',
                                                    height: 62,
                                                    zIndex: -1,
                                                    borderRadius: '50%',
                                                    position: 'absolute',
                                                    top: { lg: -15, md: -26, sm: 60, xs: 57 },
                                                    right: { lg: 100, md: 20, sm: 20, xs: 109 },
                                                    width: { sm: 62, xs: 0 },
                                                    opacity: .4
                                                }
                                            }}>
                                                <Box sx={{ width: { md: '24%', sm: '22%', xs: '24%' }, m: { md: 1, xs: '0 auto' } }}>
                                                    <img style={{ objectFit: 'contain' }} width='100%' height='100%' src={item.image} alt={item.name} title={item.text} loading='lazy' />
                                                </Box>
                                                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, ml: { sm: 2, xs: '0' }, fontWeight: 'bold', fontSize: { sm: '18px', xs: '16px' }, lineHeight: '27px', width: { md: '150px', sm: 'auto', xs: 'auto' }, height: '80px', textAlign: { md: 'start', sm: 'start', xs: 'center !important' } }}>{item.text}</Typography>
                                            </Box>
                                        </Grid>
                                    ))
                                }
                            </Grid>

                        </Box>
                    </Box>
                </Box>


                <Box sx={{ width: { lg: '80%', md: '100%', sm: '90%', xs: '100%' }, m: '50px auto', px: { md: 5, sm: 1, lg: 2 } }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>With our Digital Solutions, <b>clients benefit</b> from comprehensive Web Development and Web Designing services across digital platforms. We guarantee that our services will deliver the expected results, fostering business growth.</Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>Ugam Infotech is a <b>leading SEO service provider</b> in Indore, offering world-class assistance for both small and large businesses. Whether you are starting a new business or expanding your online presence, we are here to provide top-quality digital solutions and services. Our team at Ugam Infotech excels in delivering effective problem-solving digital solutions. We partner with you on digital marketing services to <b>enhance visibility</b>, generate business, and ensure a seamless user experience.</Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>A successful, optimized digital marketing strategy requires a perfect balance of content, keyword analysis, website structure, back-end optimization, and off-site marketing.</Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Desc