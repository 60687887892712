import React, { useContext, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Typography, useTheme } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SideBar from '../SideBar.js/index';
import { ThemeContext } from '../../components/commonContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Link } from 'react-router-dom';


const Header = () => {
    const theme = useTheme();
    const [logo, setLogo] = useState(false);
    const [state, setState] = React.useState({
        right: false,
    });
    const { toggleTheme, mode } = useContext(ThemeContext);
    const handleThemeChange = () => {
        toggleTheme();
    }

    const navItems = [       
        { icon: EmailOutlinedIcon, name: "info@ugaminfotech.com", link: 'mailto:ugaminfotech.com', arialabel: "our email detail" },
        { icon: WhatsAppIcon, name: "+91(846)-096-1523", link: 'tel:+91-8460961523', arialabel: "our contact detail" }
    ];

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: '100vw' }}
            role="slider"
        >
            <SideBar anchor={anchor} handlePass={toggleDrawer} />
        </Box>

    );

    const LoGoCompo = () => (
        <>
            {theme.palette.mode == "light" ?
                <Link to="/" style={{ textDecoration: 'none' }} aria-label='Ugam Infotech' title='Ugam Infotech' rel='follow' >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: theme.palette.secondary.main }}>
                        <Box sx={{ width: { sm: '250px', xs: '180px' }, mt: 1 }}>
                            <img src={`${process.env.REACT_APP_URL}/images/ugam-logo/ugamlightlogo.png`} width='100%' alt='ugamlightlogo' title='Ugam Infotech Logo' loading='eager' height='auto' />
                        </Box>
                    </Box>
                </Link>
                :
                <Link to="/" style={{ textDecoration: 'none' }} aria-label='Ugam Infotech' title='Ugam Infotech' rel='follow'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: theme.palette.primary.main }}>
                        <Box sx={{ width: { sm: '250px', xs: '180px' }, mt: 1 }}>
                            <img src={`${process.env.REACT_APP_URL}/images/ugam-logo/ugamdarklogo.png`} width='100%' alt='ugamlightlogo' title='Ugam Infotech Logo' loading='eager' height='auto' />
                        </Box>
                    </Box>
                </Link>
            }
        </>
    )

    const MenuIcon = ({ onClick }) => {
        return (
            <Box onClick={onClick} sx={{ perspective: '70px', position: 'relative' }}>
                <Box sx={{
                    alignSelf: 'center',
                    background: theme.palette.secondary.main,
                    height: '40px',
                    width: { md: '45px', xs: '35px' },
                    padding: '20px',
                    transform: 'rotateY(-40deg)',
                    borderRadius: '2px',
                    borderLeft: 'none',
                    borderTop: 'none',
                    position: 'absolute',
                    top: 3,
                    right: { md: '-12px', xs: '-6px' },
                    zIndex: -1
                }}>
                </Box>
                <DehazeIcon sx={{ width: '30px', height: 'auto', mt: 1 }} />
            </Box>
        )
    }

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setLogo(true);
        } else {
            setLogo(false);
        }
    };
    window.addEventListener("scroll", changeBackground);

    return (
        <>
            <CssBaseline />
            <AppBar component="nav" sx={{ backgroundColor: logo ? theme.palette.background.default : 'transparent', padding: logo ? {sm:'5px' , xs:'4px'} : {sm:'20px' , xs:'8px'}, boxShadow: 'none', position: 'fixed', backgroundImage: 'none', borderBottom: logo ? `1px solid ${theme.palette.primary.light}` : '', transition: 'all .2s ease-in-out' }}>
                <Container maxWidth='xl'>
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between', p: 0, flexDirection: { xs: 'row-reverse', lg: 'row' } }}>
                        <Box
                            sx={{ display: { lg: 'none', xs: 'flex', alignItems: 'center', gap: 6 } }}>
                            <IconButton sx={{ ml: 1 }} onClick={handleThemeChange} color="inherit">
                                {mode === 'dark' ?
                                    <LightModeIcon sx={{ color: theme.palette.primary.main }} /> :
                                    <DarkModeIcon sx={{ color: theme.palette.primary.main }} />}
                            </IconButton>
                            <MenuIcon onClick={toggleDrawer('right', true)} />
                        </Box>
                        <LoGoCompo />
                        <Box sx={{ display: { xs: 'none', lg: 'flex', transition: 'all .4s ease-in-out' } , marginLeft:'auto' }}>
                            {navItems.map((item, i) => (
                                <Link to={item.link} key={i} style={{ textDecoration: 'none' }} aria-label={item.arialabel} rel='noopener noreferrer'>
                                    <Box  sx={{ display: 'flex', alignItems: 'center' }}>
                                        <item.icon sx={{ color: theme.palette.secondary.main }} />
                                        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, pl: { lg: 1 }, pr: { lg: 3 }, fontWeight: 600, fontSize: '16px', cursor: 'pointer' }}>
                                            {item.name}
                                        </Typography>
                                    </Box>
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{ display: { xs: 'none', lg: 'flex', transition: 'all .4s ease-in-out' }, gap: 2, alignItems: 'center' }}>
                            <IconButton sx={{ ml: 1 }} onClick={handleThemeChange} color="inherit">
                                {mode === 'dark' ?
                                    <LightModeIcon sx={{ color: theme.palette.primary.main }} /> :
                                    <DarkModeIcon sx={{ color: theme.palette.primary.main }} />}
                            </IconButton>
                            <React.Fragment key={'right'}>
                                <Box sx={{ cursor: 'pointer' }}>
                                    <MenuIcon onClick={toggleDrawer('right', true)} />
                                    <Drawer
                                        anchor={'right'}
                                        className='demo123'
                                        open={state['right']}
                                    >
                                        {list('right')}
                                    </Drawer>
                                </Box>
                            </React.Fragment>
                        </Box>
                    </Toolbar>
                </Container >
            </AppBar>
        </ >
    )
}

export default Header