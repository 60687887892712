import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react'
import { ArrowCommon, ServiceHeading } from '../../../Common';

const ServiceNodeJs = () => {
    const theme = useTheme();

    const arr = [
        'Node.JS Consulting Services',
        'Node.JS Mobile Development',
        'Node.JS API Development',
        'Node.JS WebApp Development'
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' } }}>
                    <Grid item lg={7} md={6} xs={12}>
                        <Box>
                            <ServiceHeading text='Node JS Development' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Node.js is the leading server environment for developing agile and scalable network applications, Android apps, and iOS apps.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>Are you searching for a professional team to handle your Node.js app development or Node web development needs?
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>At Ugam Infotech, our expert team offers continuous services in:
                            </Typography>
                        </Box>
                        <Box sx={{ mt: { lg: 5, md: 4, sm: 3, xs: 2 } }}>
                            {arr.map((item, i) => (
                                <ArrowCommon key={i} text={item} fontWeight={700} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                            ))}
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={6} xs={12} sx={{
                        textAlign: 'center', width: { md: '100%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto', position: 'relative'
                    }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/node-js-image.jpg`} width='100%' height='auto' alt="node-js-image" title='Server Infrastructure Diagram' loading='lazy' />
                        <Box sx={{ width: { md: '20%', xs: '22%' }, py: { sm: 2, xs: 1 }, px: { sm: 3, xs: 2 }, backgroundColor: '#FFF', borderRadius: '50%', position: 'absolute', top: 70, right: 20 }}>
                            <img width='100%' height='100%' src={`${process.env.REACT_APP_URL}/images/Js_Icon.svg`} loading='lazy' alt='Js_Icon' title='Javascript' />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default ServiceNodeJs