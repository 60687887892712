import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import '../../../../App.css'
import { CommonBtn } from '../../../CustomStyle/Common'
import { useTheme } from '@emotion/react'

function ExploreBrand() {
    const theme = useTheme();
    return (
        <Grid container sx={{ backgroundColor: theme.palette.background.default, overflow: 'hidden', flexDirection: { lg: 'unset', md: 'unset', sm: 'unset', xs: 'column-reverse' }, paddingTop: '2.5rem', paddingBottom: { lg: '2.5rem', sm: '1.5rem' } }}>
            <Grid item lg={7} md={7.5} sm={6} xs={12}>
                <Box sx={{ textAlign: { lg: 'start', md: 'start', sm: 'start', xs: 'center' }, padding: { lg: '90px', md: '20px', sm: '25px', xs: '10px' }, maxWidth: { lg: '800px' } }}>
                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: { lg: '45px', md: '35px', sm: '25px', xs: '28px' }, marginBottom: { lg: '1.25rem', md: '1rem', sm: '1rem', xs: '1rem' }, fontWeight: 400, marginTop: { xs: '20px' } }} variant='h3'>Explore Our Brands</Typography>
                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: { lg: '18px' }, marginBottom: { lg: '2.25rem', md: '2rem', sm: '2rem', xs: '2rem' } }}>Dive into the diverse and dynamic universe of our IT solutions. Each brand under our umbrella represents a unique facet of our expertise and innovation. From cutting-edge software development to comprehensive IT services, explore how our brands are transforming industries and driving digital excellence. Discover the power and potential of our portfolio today.</Typography>
                    <CommonBtn text={'Visit a UgamTemplate.com'} name={'ArrowOutwardIcon'} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} />
                </Box>
            </Grid>
            <Grid item lg={5} md={4.5} sm={6} xs={12} sx={{ marginTop: { lg: '-5rem', md: '-3.2rem', sm: '-2.9rem' }, marginBottom: { lg: '-5rem', md: '-3.5rem', sm: '-3rem' }, margin: { xs: 'auto' } }}>
                <Box sx={{ height: { lg: '571px', md: '350px', sm: '350px', xs: '300px' }, width: { lg: '571px', md: '350px', sm: '350px', xs: '300px' }, position: 'relative', justifyContent: 'center', backgroundColor: theme.palette.secondary.main, borderRadius: '999px' }}>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ExploreBrand