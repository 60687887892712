import React from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { ServiceHeading } from '../../../Common';

const ServiceCodeIgniter = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, md: 8, sm: 6, xs: 5 } }}>
            <Container maxWidth='xl'>
                <Box>
                    <Box sx={{ width: { lg: '60%', md: '80%', sm: '90%', xs: '100%' }, m: '0 auto', position: 'relative' }}>
                        <Box sx={{ backgroundColor: theme.palette.background.default, px: 4, py: 2, position: 'absolute', top: 0, left: '50%', transform: 'translate(-50% , -50%)' }}>
                            <ServiceHeading text={'CodeIgniter'} />
                        </Box>
                        <img width='100%' height='100%' src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/codeignitor-image.jpg`} alt='codeignitor-image' title='Codeignitor' loading='lazy' />
                        <Box sx={{ position: 'absolute', top: 250, right: -110, transform: 'rotate(-270deg)', zIndex: -1, display: { md: 'block', xs: 'none' } }}>
                            <img src={`${process.env.REACT_APP_URL}/images/about.svg`} alt='about' title='about more details' loading='lazy' width='auto' height='auto' />
                        </Box>
                    </Box>
                    <Box sx={{ mt: { lg: 4, md: 3, xs: 2 }, px: { lg: 14, md: 10, xs: 0 } }}>
                        <Typography sx={{ textAlign: 'center', fontSize: { md: '18px', xs: '16px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '40px', xs: '26px' } }}>
                            For developers seeking a simple and refined toolkit to create fully functional web applications, CodeIgniter stands out as an efficient PHP framework with a compact footprint.
                        </Typography>
                    </Box>
                    <Grid container spacing={{ sm: 6, xs: 3 }} sx={{ px: { lg: 14, md: 10, xs: 0 }, mt: { xs: 0 } }}>
                        <Grid item md={6}>
                            <Box>
                                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '36px', md: '32px', sm: '28px', xs: '20px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '54px', md: '48px', sm: '36px', xs: '34px', borderLeft: `5px solid ${theme.palette.secondary.main}` }, pl: { md: 5, sm: 4, xs: 2 } }}>
                                    Why choose Ugam Infotech for CodeIgniter services?
                                </Typography>
                                <Box sx={{ width: { sm: '26%', xs: '40%' }, m: 'auto', mt: 5 }}>
                                    <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/why-consider-ugam-image.png`} width='100%' height='100%' alt='why-consider-ugam-image' title='All language on work' loading='lazy' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Typography sx={{ fontSize: { md: '18px', xs: '16px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '40px', xs: '26px' } }}>
                                While working with us, you <Box component='span' sx={{ fontWeight: 600 }}>can rest assured</Box> about the stability and performance of your website after project delivery.
                            </Typography>
                            <Typography sx={{ fontSize: { md: '18px', xs: '16px' }, mt: { md: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '40px', xs: '26px' } }}>
                                From our perspective, the CodeIgniter App Development process isn't considered complete until <Box component='span' sx={{ fontWeight: 600 }}>our testing team has identified and resolved all issues,</Box> ensuring we deliver fully responsive results to our customers.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default ServiceCodeIgniter