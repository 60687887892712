import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'

const Desc = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: 5 }}>
            <Container maxWidth='lg'>
                <Box sx={{ p: 1 }}>
                    <Box sx={{
                        '& ::before': {
                            content: '""',
                            position: 'absolute',
                            borderLeft: `6px solid ${theme.palette.secondary.main}`,
                            height: '100%',
                            top: '0px',
                            left: { lg: '3%', md: '-5%', sm: '-4%', xs: '-5%' },
                            zIndex: '1',
                        }, textAlign: { lg: 'center', md: 'left' }, m: 'auto', width: { lg: '80%', md: '70%', sm: '91%', xs: '88%' }
                    }}>
                        <Typography sx={{
                            fontSize: { sm: '24px', xs: '20px' }, position: 'relative', lineHeight: { sm: '48px', xs: '34px' }
                        }}>Why Are E-commerce Services Highly Preferred by Businesses?</Typography>
                    </Box>

                    <Box sx={{ mx: 'auto', my: 3 }}>
                        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: { sm: '18px', xs: '16px' }, lineHeight: { md: '35px', xs: '28px' } }}><Box component='span' sx={{ fontWeight: 700 }}>E-commerce services can significantly reduce costs</Box> and offer other benefits that attract sellers to online marketing through digital platforms. Operating a physical store often entails substantial expenses, and geographic limitations can constrain your customer base.</Typography>
                        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, mt: 5, fontSize: { sm: '18px', xs: '16px' }, lineHeight: { md: '35px', xs: '28px' } }}>
                            Furthermore, one of the significant advantages of eCommerce for businesses is that online stores operate 24/7, unlike traditional brick-and-mortar stores with fixed operating hours. Retailers can <Box component='span' sx={{ fontWeight: 700 }}>boost their order volume</Box> and increase sales by catering to customers who prefer to shop late at night or early in the morning.
                        </Typography>
                        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, mt: 5, fontSize: { sm: '18px', xs: '16px' }, lineHeight: { md: '35px', xs: '28px' } }}>Having an online business allows you to accommodate your consumers' busy schedules and provide them with what they need, at their convenience.</Typography>
                        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, mt: 5, fontSize: { sm: '18px', xs: '16px' }, lineHeight: { md: '35px', xs: '28px' } }}><Box component='span' sx={{ fontWeight: 700 }}>At Ugam Infotech,</Box> we offer a variety of services to enhance e-commerce usage.</Typography>
                    </Box>

                </Box>

            </Container>
        </Box>
    )
}

export default Desc