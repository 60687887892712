import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'

const AboutPageDesc = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { md: 10, xs: 4 } }}>
            <Container maxWidth="xl">
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {md: '35px', xs: '28px' } }}>
                    <Box component='span' sx={{ fontWeight: 700 }}>Ugam Infotech is dedicated to elevating our clients' businesses </Box>
                    with top-tier, effective services. Our goal is to achieve success strategically, providing comprehensive facilities that ensure 100% results in short timeframes.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {md: '35px', xs: '28px' } }}>
                    We take pride in assuring our customers that choosing Ugam Infotech means receiving exceptional services at competitive rates, with a strong focus on customer satisfaction. Our expertise spans across <Box component='span' sx={{ color: theme.palette.secondary.main }}>Website Designing, Web Development, Mobile App Development, Graphic Designing</Box>, Website Testing, <Box component='span' style={{ color: theme.palette.secondary.main }}>E-commerce</Box>, SEO, <Box component='span' style={{ color: theme.palette.secondary.main }}>Digital Marketing</Box> , and more.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {md: '35px', xs: '28px' } }}>
                    <Box component='span' sx={{ fontWeight: 700 }}>At Ugam Infotech, our professionals deliver high-quality</Box> web solutions at affordable prices. With a global clientele and enduring relationships, we have a wealth of experience across diverse projects and industries.
                </Typography>
            </Container>
        </Box>
    )
}

export default AboutPageDesc