import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CommonBtn, CommonHeading, Heading72px, ParagraphStyle } from '../../../CustomStyle/Common';
import { Link, useNavigation } from 'react-router-dom';

const CompanyStory = () => {
    const theme = useTheme();
    // const navigate = useNavigation();
    const data = [
        { num: '200+', decs: 'Happy Clients From Us' },
        { num: '140+', decs: 'Completed Projects' },
        { num: '10+', decs: 'Excellent Team Members' },
        { num: '2018', decs: 'Service Since' },
    ]
    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.background.HomeGradiont, padding: { xs: '40px 0', md: '60px 0', lg: '80px 0' }, position: 'realtive', zIndex: 10, overflow: 'hidden' }}>
                <Container maxWidth='xl'>
                    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item sm={12} md={8}>
                            <CommonHeading text={`Ugam Infotech's Story`} color={theme.palette.primary.main} />
                            <Heading72px text={"Shaping Future's Digital Landscape"} color={theme.palette.primary.main} />
                            <Box sx={{ display: 'flex', mt: '30px', mb: '48px' }}>
                                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: '20px', color: theme.palette.primary.main, whiteSpace: 'nowrap', display: { xs: 'none', md: 'block' } }}>
                                    About Us
                                </Typography>
                                <Box sx={{ pl: { xs: '0', md: '60px' } }}>
                                    <ParagraphStyle text={'Welcome to Ugam Infotech, where innovation meets expertise. Founded in 2018, we are a dynamic IT company dedicated to delivering cutting-edge technology solutions tailored to meet the <b>unique needs of our clients</b>. Our team of experienced professionals is committed to driving digital transformation through comprehensive IT services, including software development, IT consulting, and cybersecurity.'} color={theme.palette.primary.main} />

                                    <ParagraphStyle mt={'25px'} text={'At Ugam Infotech, we believe in the power of technology to transform businesses and empower growth. Our mission is to <b>provide exceptional service and create value</b> through our innovative solutions. We work closely with our clients to understand their challenges and goals, ensuring that our strategies are aligned with their business objectives.'} color={theme.palette.primary.main} />
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Link to="/company/about" aria-label="About Ugam Infotech" title='Explore About Us' rel="follow">
                                    <CommonBtn backgroundColor={theme.palette.secondary.main} text={'Explore About Us'} hoverBGColor={theme.palette.secondary.main} />
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item sm={12} md={3} sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, flexWrap: 'wrap', gap: '20px' }}>
                            {
                                data.map((item, index) =>
                                    <Box key={index} sx={{
                                        width: { xs: '100%', sm: '48%', md: '100%', lg: '100%' },
                                        textAlign: { md: 'left', sm: 'center', xs: 'center' },
                                        padding: { md: '0 30px 30px 0', xs: '0 0 30px 0' }
                                    }}>
                                        <Typography variant='h2' sx={{
                                            fontFamily: theme.palette.typography.fontFamily,
                                            fontWeight: 600,
                                            color: theme.palette.primary.main,
                                            fontSize: { xs: '45px', md: '60px' }
                                        }}>{item.num}</Typography>
                                        <Typography sx={{
                                            fontFamily: theme.palette.typography.fontFamily,
                                            fontSize: '18px',
                                            color: theme.palette.primary.main
                                        }}>{item.decs}</Typography>
                                    </Box>
                                )
                            }
                            <Box sx={{ display: { xs: 'block', md: 'none' }, margin: { xs: '0 auto' } }}>
                                <Link to="/company/about" aria-label="About Ugam Infotech" title='Explore About Us' rel="follow">
                                    <CommonBtn backgroundColor={theme.palette.secondary.main} text={'Explore About Us'} hoverBGColor={theme.palette.secondary.main} />
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default CompanyStory