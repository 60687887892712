import React from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'


const ContentManagment = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, overflow: 'hidden' }}>
            <Container maxWidth='xl'>
                <Box sx={{
                    position: 'relative', zIndex: 1, pt: 5, width: { lg: '80%', md: '100%', sm: '90%', xs: '100%' }, m: '0 auto', '&::after': {
                        content: "''",
                        position: 'absolute',
                        bottom: -20,
                        top: { lg: 100, md: 97, sm: 65, xs: -150 },
                        right: { lg: -128, md: -70, sm: -80, xs: -150 },
                        //left: { lg: -140, md: -120, sm: -100, xs: -150 },
                        width: { lg: '200px', md: '170px', sm: '150px', xs: '120px' },
                        height: { lg: '130px', md: '110px', sm: '90px', xs: '70px' },
                        backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/about.svg`})`,
                        backgroundPosition: 'center',
                        display: { sm: 'block', xs: 'none' },
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        transform: 'rotate(90deg)',
                        zIndex: -1
                    },
                }}>
                    <Box sx={{ height: { sm: '100%', xs: '100px', overflow: 'hidden' } }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/digital-marketing-and-contentSolutions/contentImg.jpg`} width='100%' alt='contentImg' loading='lazy' title='Content Marketing Managment' height='auto' />
                    </Box>
                    <Box sx={{ py: { md: 5, xs: 2 }, textAlign: { lg: 'center', md: 'auto', sm: 'center', xs: 'center' }, px: { lg: 5, md: 6, xs: 0 }, background: { md: theme.palette.background.default, sm: 'none' }, position: { md: 'absolute', xs: '' }, bottom: 0, right: { md: 0, xs: 0 }, width: { lg: '60%', md: '70%', xs: '100%' }, zIndex: 2 }}>
                        <Typography sx={{
                            fontSize: { lg: '48px', md: '48px', sm: '44px', xs: '28px' },
                            fontWeight: 700,
                            letterSpacing: '.42px',
                            lineHeight: { lg: '60px', md: '60px', sm: '60px', xs: '32px' },
                            position: 'relative',
                            margin: '0px 0 0',
                            fontFamily: theme.palette.typography.fontFamily,
                        }}>Content Management</Typography>
                    </Box>
                </Box>
                <Box sx={{ width: { lg: '80%', md: '100%', sm: '90%', xs: '100%' }, m: '50px auto', }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>
                        <b>High-quality content</b> is essential for engaging your site visitors effectively. A fundamental principle we follow is to separate website design from content creation.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>
                        We primarily focus on easy-to-use meta tags to optimize your website content for higher rankings. Additionally, we offer SEO-optimized image upload features and a flash content management system.</Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>
                        At Ugam Infotech, our main goal is to deliver exceptional and profitable <b>content management services</b> for your online business.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>
                        We provide a comprehensive range of Content Management Services that include top-notch content tailored to our clients' needs.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '45px', md: '35px',  xs: '28px' } }}>
                        Our strategies aim to deliver content that aligns with what users are searching for on the web. By achieving this, users are more likely to connect with your page and spend more time on it, thereby <b>reducing bounce rates</b>.
                    </Typography>

                </Box>
            </Container>
        </Box>
    )
}

export default ContentManagment