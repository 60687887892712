import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Container, Divider, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';

import SocialMediaComponet from './SocialMediaComponet';
import { alltab } from '../Json';
import SEO from '../../SEO';







const SocialMediaDesign = () => {
    const theme = useTheme();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    let Socialmediacomponet1 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia1.jpg`,
            name :'socialmedia1'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia2.jpg`,
            name :'socialmedia2'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia3.jpg`,
            name :'socialmedia3'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia4.jpg`,
            name :'socialmedia4'
        }
    ]

    let Socialmediacomponet2 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia5.jpg`,
            name :'socialmedia5'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia6.jpg`,
            name :'socialmedia6'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia7.jpg`,
            name :'socialmedia7'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia8.jpg`,
            name :'socialmedia8'
        }
    ]

    let Socialmediacomponet3 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia9.jpg`,
            name :'socialmedia9'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia10.jpg`,
            name :'socialmedia10'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia11.jpg`,
            name :'socialmedia11'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia12.jpg`,
            name :'socialmedia12'
        }
    ]

    let Socialmediacomponet4 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia13.jpg`,
            name :'socialmedia13'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia14.jpg`,
            name :'socialmedia14'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia15.jpg`,
            name :'socialmedia15'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia16.jpg`,
            name :'socialmedia16'
        }
    ]

    let Socialmediacomponet5 = [
        {
            img1: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia17.jpg`,
            name :'socialmedia17'
        },
        {
            img2: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia18.jpg`,
            name :'socialmedia18'
        },
        {
            img3: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia19.jpg`,
            name :'socialmedia19'
        },
        {
            img4: `${process.env.REACT_APP_URL}/images/pages/protfolio/socialmedia-design/socialmedia20.jpg`,
            name :'socialmedia20'
        }
    ]




    return (
        <>
            <SEO
                title="Social Media Design - Ugam Infotech"
                description="Explore our social media design services at Ugam Infotech. We create engaging and visually appealing designs for your social media channels."
                keywords="Ugam, Ugam Infotech, social media design services, engaging designs, visually appealing, social media channels"
                canonical={`${process.env.REACT_APP_URL}/portfolio/social-media-design`}
                ogTitle="Social Media Design - Ugam Infotech"
                ogDescription="Explore our social media design services at Ugam Infotech. We create engaging and visually appealing designs for your social media channels."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/social-media-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Social Media Design - Ugam Infotech"
                twDescription="Explore our social media design services at Ugam Infotech. We create engaging and visually appealing designs for your social media channels."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Social Media Design'} />
            <Box sx={{ overflow: 'hidden', width: '100%', backgroundColor: theme.palette.background.lightDark }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "14px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' } }}>Our social media design services focus on creating engaging visual content for various social media platforms to enhance audience interaction and boost brand awareness. This can include a variety of visual elements such as graphics, photos, videos, animations, and more.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{
                            display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                        {
                                            item.text == "Social Media Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                        }

                                        {index < alltab.length &&  !index == 0 &&(
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ margin: '50px 0px 140px' }}>
                            <SocialMediaComponet alllsocialmediaimages={Socialmediacomponet1} />
                            <SocialMediaComponet alllsocialmediaimages={Socialmediacomponet2} />
                            <SocialMediaComponet alllsocialmediaimages={Socialmediacomponet3} />
                            <SocialMediaComponet alllsocialmediaimages={Socialmediacomponet4} />
                            <SocialMediaComponet alllsocialmediaimages={Socialmediacomponet5} />

                        </Box>
                    </Box>

                </Container >
            </Box>
        </>
    )
}

export default SocialMediaDesign