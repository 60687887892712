import React from 'react'
import { Box, useTheme } from '@mui/material';
import Slider from "react-slick";


const SlickSlider = ({ slidesFirst, slidesSecond, slidesThird }) => {
    const theme = useTheme();
    const themeColor = localStorage.getItem('theme');
    const imgArr = [
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/react-icon.svg`,
            name: 'react-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/skill-icons_sass.svg`,
            name: 'skill-icons_sass'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_bootstrap.svg`,
            name: 'logos_bootstrap'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/Javascript.svg`,
            name: 'Javascript'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/vue-icon.svg`,
            name: 'vue-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/angular-icon.svg`,
            name: 'angular-icon'
        },       
        {
            svg: themeColor === 'light' 
            ? `${process.env.REACT_APP_URL}/images/lenguage-icon/next-icon.svg`
            : `${process.env.REACT_APP_URL}/images/lenguage-icon/next-icon-svg-dark.svg`,
            name: 'next-icon'
        }
    ];

    const imgArr2 = [
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/css-logo.svg`,
            name: 'css-logo'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_html-5.svg`,
            name: 'logos_html-5'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/laravel-logo.svg`,
            name: 'laravel-logo'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/php-icon.svg`,
            name: 'php-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/node-icon.svg`,
            name: 'node-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/logos_python.svg`,
            name: 'logos_python'
        }
    ];

    const imgArr3 = [
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/codeigniter-icon.svg`,
            name: 'codeigniter-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/mongodb-icon.svg`,
            name: 'mongodb-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/mysql-icon.svg`,
            name: 'mysql-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/postgresql-logo.svg`,
            name: 'postgresql-logo'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/sqlite-icon.svg`,
            name: 'sqlite-icon'
        },
        {
            svg: `${process.env.REACT_APP_URL}/images/lenguage-icon/firebase-icon.svg`,
            name: 'firebase-icon'
        }
    ];

    let settingFirst = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: slidesFirst,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 8000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: slidesFirst,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: slidesFirst
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: slidesFirst
                }
            }
        ]
    };

    let settingSecond = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        autoplay: true,
        slidesToScroll: slidesSecond,
        autoplaySpeed: 0,
        speed: 5000,
        pauseOnHover: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: slidesSecond,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: slidesSecond
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: slidesSecond
                }
            }
        ]
    };

    let settingThird = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 10000,
        pauseOnHover: false,
        slidesToScroll: slidesThird,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: slidesThird,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: slidesThird
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: slidesThird
                }
            }
        ]
    };

    return (
        <>
            <Slider key={1} {...settingFirst}>
                {
                    imgArr.map((val, i) => {
                        return (
                            <Box key={i} sx={{
                                border: `1px solid #bcbcbc`,
                                borderRight: 0,
                                borderBottom: 0,
                                height: { xs: '80px', sm: '100px', md: '120px', lg: '140px' },
                                display: 'flex !important',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <img src={val.svg} width='70%' height='50%' style={{ objectFit: 'contain' }} alt={val.name} loading='lazy' title='Modern Programming Languages' />
                            </Box>
                        )
                    })
                }
            </Slider>
            <Slider key={2} {...settingSecond}>
                {
                    imgArr2.map((val, i) => {
                        return (
                            <Box key={i} sx={{
                                border: `1px solid #bcbcbc`,
                                borderRight: 0,
                                height: { xs: '80px', sm: '100px', md: '120px', lg: '140px' },
                                display: 'flex !important',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <img src={val.svg} width='70%' height='50%' style={{ objectFit: 'contain' }} alt={val.name} loading='lazy' title='Modern Programming Languages' />
                            </Box>
                        )
                    })
                }
            </Slider>
            <Slider key={3} {...settingThird}>
                {
                    imgArr3.map((val, i) => {
                        return (
                            <Box key={i} sx={{
                                border: `1px solid #bcbcbc`,
                                borderRight: 0,
                                borderTop: 0,
                                height: { xs: '80px', sm: '100px', md: '120px', lg: '140px' },
                                display: 'flex !important',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <img src={val.svg} width='70%' height='50%' style={{ objectFit: 'contain' }} alt={val.name} loading='lazy' title='Modern Programming Languages' />
                            </Box>
                        )
                    })
                }
            </Slider>
        </>
    )
}

export default SlickSlider