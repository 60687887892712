import React from 'react'
import PageHeading from '../commonComponents/PageHeading'
import { Box, Container, Typography, useTheme } from '@mui/material'
import { CommonBtn } from '../CustomStyle/Common';
import { useNavigate } from 'react-router-dom';

const Pagenotfound = () => {
  const navgation = useNavigate();

  const theme = useTheme();
  return (
    <>
      {/* <PageHeading text={'Page Not Found'} /> */}
      <Box sx={{ background: theme.palette.background.lightDark, py: { md: 10, sm: 8, xs: 8 }, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}  >
        <Box sx={{ width: { lg: '40%', sm: '80%' }, margin: '0 auto', paddingRight: { sm: '40px', xs: '24px' } }}>
          <img src={`${process.env.REACT_APP_URL}/images/page-not-found/page-not-found.png`} style={{ width: '100%' }} />
        </Box>
        <Box sx={{ textAlign: 'center', p: { md: 0, xs: 1 } }}>
          <Typography sx={{ fontSize: { md: '38px', sm: '32px', xs: '26px' }, pt: 2, fontFamily: theme.palette.fontFamily, fontWeight: 600 }}>Oops! Page Not Found</Typography>
          <Typography sx={{ width: { lg: '50%', md: '90%', xs: '100%' }, margin: { sm: '34px auto 0px', xs: '24px auto 0px' } }}>It looks like the page you're looking for has either been moved, deleted, or does not exist. Please check the URL for errors or use the navigation menu to find what you need.</Typography>
          <Box sx={{ mt: { sm: 6, xs: 2 } }} onClick={() => navgation('/')}>
            <CommonBtn text={"Go Back Home"} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} hoverColor={"#fff"} />
          </Box>
        </Box>
      </Box></>

  )
}

export default Pagenotfound