import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Container, Divider, Grid, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css';
import { AllwebDesigntab } from '../Json'
import SEO from '../../SEO'


const WebsiteDesign = () => {
    const theme = useTheme();


    useEffect(() => {
         new WOW.WOW({
            offset:50
         }).init();
    }, [])

    let Allwebpage = [
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage1.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage2.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage3.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage4.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage5.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage6.png`
        },        
        
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage9.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage10.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage11.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage12.png`
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage13.png`
        },        
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <SEO
                title="Website Design - Ugam Infotech"
                description="Explore our website design and content solutions at Ugam Infotech. We create visually appealing websites with engaging content to elevate your brand."
                keywords="Ugam, Ugam Infotech, website design, content solutions, visually appealing websites, engaging content, brand elevation"
                canonical={`${process.env.REACT_APP_URL}/portfolio/website-design`}
                ogTitle="Website Design - Ugam Infotech"
                ogDescription="Explore our website design and content solutions at Ugam Infotech. We create visually appealing websites with engaging content to elevate your brand."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/website-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Website Design - Ugam Infotech"
                twDescription="Explore our website design and content solutions at Ugam Infotech. We create visually appealing websites with engaging content to elevate your brand."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Website Design '} />
            <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { lg: 10, xs: 8 } }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center" }}>
                        <Box component='p' sx={{ fontSize: { md: '21px' ,xs:'16px' }, lineHeight: { md: '48px', sm: '36px', xs: '30px' }, fontFamily: theme.typography.fontFamily }}><b>Website design focus on creating</b> a functional and visually appealing online presence. This process involves several stages, including <b>planning, design, coding, and testing</b>. The design phase covers layout, color schemes, graphics, and user experience (UX) considerations. The design is realized through programming languages such as <b>HTML, CSS, and JavaScript</b>, and includes integrating features like navigation menus, forms, and interactive elements.
                            At Ugam Infotech, our objective is to create an attractive, user-friendly, responsive, and search engine-optimized website that meets specific goals. Our expertly designed and developed websites help businesses build their online presence, attract visitors, engage users, and achieve their objectives, whether it's generating leads, driving sales, or disseminating information.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>

                        <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', sm: "row" }, alignItems: 'stretch', textAlign: 'center', mt: 6 }}>
                            {
                                AllwebDesigntab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                        {
                                            item.text == "All" ?
                                                <Link to={item.link}  style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'> {item.text}</Link>

                                                :
                                                <Link to={item.link}  style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                        }

                                        {
                                            index < AllwebDesigntab.length && !index == 0 &&  (
                                                <Divider
                                                    orientation="vertical"                                                    
                                                    sx={{
                                                        height: '18px',
                                                        display: { sm: 'block', xs: 'none' },
                                                        marginTop: -2.7,
                                                        backgroundColor: '#646464',
                                                        width: '1px'
                                                    }}
                                                />
                                            )
                                        }
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ mt: 6 }}>
                            <Grid container spacing={2} sx={{ padding: '0px' }}>
                                {
                                    Allwebpage?.map((item, i) => (
                                            <Grid key={i} item xs={12} md={6} sx={{ textAlign: 'center' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box className="wow animate__animated animate__pulse"
                                                        sx={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            maxWidth: { lg: '480px', md: '370px', sm: '470px', xs: '285px' },
                                                            height: { xs: '220px', md: '334px', sm: '376px', lg: '370px' },
                                                            padding: '25px 0 51px'
                                                        }}
                                                    >
                                                        <img
                                                            src={item.laptop}
                                                            alt="laptop"
                                                            style={{ position: 'absolute', top: '0', left: '0' }}
                                                            title='Laptop Screen'
                                                            loading='lazy'
                                                            width='100%'
                                                            height='100%'
                                                        />
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: { xs: '47px', sm: '76px', md: '70px', lg: '75px' },
                                                                right: { xs: '41px', sm: '66px', md: '56px', lg: '70px' },
                                                                width: { xs: '206px', sm: '340px', md: '263px', lg: '344px' },
                                                                height: { xs: '113px', sm: '200px', md: '174px', lg: '197px' },
                                                                overflow: 'hidden',
                                                                display: 'block',
                                                                '& img': {
                                                                    transition: 'transform 10s ease',
                                                                    width: '100%',
                                                                },
                                                                '&:hover img': {
                                                                    transform: 'translateY(-100%)'
                                                                }
                                                            }}
                                                        >
                                                            <img src={item.img} alt="Home Page" title='All Web page design' loading='lazy' width='auto' height='auto' />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                    ))
                                }

                            </Grid>
                        </Box>

                    </Box>

                </Container >
            </Box >
        </>
    )
}

export default WebsiteDesign