import React, { createContext, useState, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
    const [mode, setMode] = useState('light');

    useEffect(() => {
        if (localStorage.getItem('theme'))
            if (localStorage.getItem('theme') == "light")
                setMode("light");
            else
                setMode("dark")
    }, [])


    const palette = {
        primary: {
            ...(mode === 'light'
                ? {
                    main: '#393939',
                    light: '#231f20',
                    dark: '#d4d0d0',
                    contrastText: '#FFFFFF',
                } :
                {
                    main: '#f0f4f8',
                    light: '#f0f4f866',
                    dark: '#8a97a1',
                    contrastText: '#121a35',
                })
        },
        secondary: {
            ...(mode === 'light'
                ? {
                    main: '#ff7f53',
                    light: '#f0f4f8',
                    dark: '#00000066',
                    contrastText: '#000000',
                } :
                {
                    main: '#ff7f53',
                    light: '#f0f4f8',
                    dark: '#FFFFFF66',
                    contrastText: '#000000',
                })
        },
        typography: {
            fontFamily: 'Poppins,sans-serif', // default Material-UI font
        },
        background: {
            ...(mode === 'light'
                ? {
                    default: '#f0f4f8',
                    lightDark: '#FFFFFF90',
                    paper: 'rgba(0 ,0 ,0 , 0.8)',
                    menuBar:'#f0f4f8',
                    HomeGradiont : '#f0ece6'
                } :
                {
                    default: '#0a0f22',
                    lightDark: '#121a35b5',
                    paper: 'rgba(0 ,0 ,0 , 0.5)',
                    menuBar:'#353535',
                    HomeGradiont :'#121a35b5'
                })
        }
    };

    const theme = useMemo(() =>
        createTheme({
            palette: {
                mode: mode,
                primary: palette.primary,
                secondary: palette.secondary,
                background: palette.background,
                typography: palette.typography
            },
        }), [mode]);


    const toggleTheme = () => {
        if (mode == "light")
            localStorage.setItem('theme', 'dark');
        else
            localStorage.setItem('theme', 'light');

        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeContext.Provider value={{ toggleTheme, mode }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
