import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import PageTilteSection from '../../commonComponents/PageTitleSection'
import AnimationPageDesc from './Components/AnimationPageDesc'
import AnimationPageDescSecond from './Components/AnimationPageDescSecond'
import AnimationLogoAnimation from './Components/AnimationLogoAnimation'
import AnimationSectionImage from './Components/AnimationSectionImage'
import AnimationMotionGraphic from './Components/AnimationMotionGraphic'
import AnimationVideosMobile from './Components/AnimationVideosMobile'
import AnimationExplainerVideo from './Components/AnimationExplainerVideo'
import AnimationMobileWebappVideo from './Components/AnimationMobileWebappVideo'
import SEO from '../../SEO'
import ServiceOtherCommon from '../Common/ServiceOtherCommon'

const Animation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SEO
                title="Animation - Ugam Infotech"
                description="Explore our animation services at Ugam Infotech. We create captivating animations to enhance your digital presence using the latest technologies."
                keywords="Ugam, Ugam Infotech, animation services, digital animations, captivating animations, animation technology, web development"
                canonical={`${process.env.REACT_APP_URL}/services/animation`}
                ogTitle="Animation - Ugam Infotech"
                ogDescription="Explore our animation services at Ugam Infotech. We create captivating animations to enhance your digital presence using the latest technologies."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/services/animation`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Animation - Ugam Infotech"
                twDescription="Explore our animation services at Ugam Infotech. We create captivating animations to enhance your digital presence using the latest technologies."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={"Animations"} />
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/services/animations/animation-header-image.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'Animation'} />
            <AnimationPageDesc />
            <AnimationPageDescSecond />
            <AnimationLogoAnimation />
            <AnimationSectionImage />
            <AnimationVideosMobile />
            <AnimationExplainerVideo />
            <AnimationMobileWebappVideo />
            <AnimationMotionGraphic />
            <ServiceOtherCommon />
        </>
    )
}

export default Animation