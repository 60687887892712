import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { ServiceHeading } from '../../../Common';

const ServiceFullstack = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={3} sx={{ flexDirection: { md: 'row', xs: 'column-reverse' }, display: 'flex', alignItems: 'center' }}>
                    <Grid item md={8} xs={12}>
                        <Box>
                            <ServiceHeading text='FullStack' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            We offer comprehensive full-stack services that oversee every stage of app or website development. From testing and debugging to ensuring responsive design across devices, our full-stack developers manage applications and APIs on the server side. Our objective is to create seamless websites that fulfill customer specifications.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                            At Ugam Infotech, we <Typography variant='span' sx={{fontWeight:'bold'}}>deliver high-quality, user-friendly, and responsive websites</Typography> to our customers. Our full-stack developers are adept at switching between diverse technologies. Our Full-stack web development team excels in meeting customer demands promptly, completing projects more efficiently than other development types, ensuring timely delivery.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ textAlign: 'center', width: { md: '100%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto' }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/full-stack-imagepng.png`} width='100%' height='auto' alt="full-stack-imagepng" title='Coding Interface Components' loading='lazy' />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default ServiceFullstack