import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CommonHeading } from '../../../../CustomStyle/Common'

const AboutOurVision = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, position: 'relative', overflow: 'hidden' }}>
            <Container maxWidth='xl'>
                <Box sx={{ textAlign: 'center', width: { md: '80%', sm: '90%', xs: '100%' }, m: '0 auto', py: { lg: 13, md: 12, sm: 8, xs: 6 } }}>
                    <CommonHeading text={'Our vision'} fontSize={'24px'} margin={'0px 20px'} fontFamily={'gilroy-extraBold'} color={theme.palette.primary.main} />
                    <Typography sx={{
                        fontSize: { lg: '40px', md: '30px', sm: '24px', xs: '18px' },
                        fontWeight: 300,
                        fontFamily: theme.palette.typography.fontFamily,
                        color: theme.palette.primary.main,
                        letterSpacing: '.42px',
                        lineHeight: { lg: '70px', md: '55px', sm: '50px', xs: '30px' },
                        position: 'relative',
                        margin: '20px 0 0',
                    }}>Our vision is to transform the digital landscape by providing innovative, high-quality IT solutions that empower businesses to reach their full potential. We aspire to be a global technology leader, cultivating a culture of creativity, collaboration, and excellence. Our goal is to drive sustainable growth for our clients, employees, and the communities we serve.</Typography>
                </Box>
            </Container>
            <Box sx={{
                animation: 'circularMotion 8s linear infinite',
                content: '""',
                // borderRadius: '50%',
                border: `1px solid ${theme.palette.primary.main}`,
                zIndex: 0,
                height: { sm: '400px', xs: '360px' },
                width: { sm: '400px', xs: '360px' },
                position: 'absolute',
                top: { md: 100, sm: 220, xs: 190 },
                left: { sm: -250, xs: -190 },
                opacity: .5
            }}>
            </Box>
            <Box sx={{
                animation: 'circularMotion 8s linear infinite',
                content: '""',
                // borderRadius: '50%',
                border: `1px solid ${theme.palette.primary.main}`,
                zIndex: 0,
                height: { sm: '400px', xs: '360px' },
                width: { sm: '400px', xs: '360px' },
                position: 'absolute',
                top: { md: 100, sm: 230, xs: 180 },
                left: { sm: -180, xs: -150 },
                opacity: .5
            }}>
            </Box>
        </Box >

    )
}

export default AboutOurVision