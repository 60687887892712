import React from 'react'
import { List, Button, useTheme, createTheme, Typography, Tabs, Tab, Grid, Link, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function DesktopSidebar({ comapny, handleDrawer }) {
    const theme = useTheme();
    const navgation = useNavigate();

    const handleNavigate = (link) => {
        navgation(link);
        handleDrawer('right', false);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return (
        <Container >
            <Grid container spacing={9}>
                {comapny.map((item, i) => (
                    <Grid item xs={4} key={i} className='main' >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} onClick={() => handleNavigate(item.link)}>
                            <Grid>
                                <Box className='img' sx={{ background: theme.palette.primary.dark, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1.5, borderRadius: 2 }}>
                                    <img src={item.icon} />
                                </Box>
                            </Grid>
                            <Grid >
                                <Box sx={{ width: '100%' }}>
                                    <Typography className='title' sx={{ color: theme.palette.primary.main, fontSize: '16px', fontWeight: 700, lineHeight: '20px', textTransform: 'uppercase', fontFamily: theme.palette.typography.fontFamily }}>
                                        {item.title}
                                    </Typography>
                                    <Typography className='desc' sx={{ color: theme.palette.primary.light, fontSize: '13px', fontWeight: 400, fontFamily: theme.palette.typography.fontFamily }}>
                                        {item.desc}
                                    </Typography>
                                    <Typography className='bttn' sx={{ color: theme.palette.primary.main, fontSize: '14px', fontWeight: 600, mt: '10px', textTransform: 'none', textDecoration: 'none', position: 'relative' }} >{item.btn} </Typography>
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default DesktopSidebar