import { useTheme } from '@emotion/react';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import { ServiceHeading } from '../../../Common';


const ServiceBackend = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ flexDirection: { md: 'row-reverse', xs: 'column-reverse' } }}>
                    <Grid item lg={7} md={8} xs={12}>
                        <Box>
                            <ServiceHeading text='BackEnd' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                At Ugam Infotech, we specialize in robust backend development to ensure each site not only looks good but also functions effectively from the user's perspective. With years of experience in backend development, our logic-based coding approach guarantees the efficient operation of websites.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                We prioritize <Box component='span' sx={{ fontWeight: 600 }}>customer satisfaction</Box> in every backend task we undertake. Our strong emphasis on logic ensures the creation of robust backend solutions that are pivotal to the functionality of the website.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Our backend strategy revolves around a server and client-based data model, facilitating interaction with databases and APIs to ensure seamless functionality and data management.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={3.5} md={4} xs={12} sx={{ textAlign: 'center', width: { md: '100%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto', display: 'flex', alignItems: 'center' }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/web-design-development/backend-image.png`} width='100%' height='auto' alt="backend-image" title='Backend popular language' loading='lazy' />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default ServiceBackend