import { List, Button, useTheme, Typography, Tabs, Tab, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import { CommonBtn } from '../../components/CustomStyle/Common';
import DesktopSidebar from './Desktop';
import MobileSidebar from './Mobile';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Link, useNavigate } from 'react-router-dom';


export default function SideBar({ anchor, handlePass }) {
    const [selectedOption, setSelectedOption] = useState(0);

    const [logo, setLogo] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
    };


    const theme = useTheme();

    const sideBarnavItems = [
        {
            name: "Company"
        }, {
            name: "Services"
        }, {
            name: "Portfolio"
        }
    ];

    const Comapny = [
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/1.svg`,
            title: 'ABOUT US',
            desc: 'The more you know the more you grow.',
            btn: 'Know More',
            link: '/company/about'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/2.svg`,
            title: 'CAREERS',
            desc: "Let's take you up high.",
            btn: 'Know More',
            link: '/company/careers'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/3.svg`,
            title: 'CONTACT US',
            desc: 'Just one click away.',
            btn: 'Click Now',
            link: '/contact-us'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/4.svg`,
            title: 'OUR CULTURE',
            desc: "Let’s match the vibes to shine.",
            btn: 'Know More',
            link: '/company/our-culture'
        }
    ]
    const Services = [
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/s1.svg`,
            title: 'Web Design & Development',
            desc: 'Excel the Top-notch web services.',
            btn: 'Know More',
            link: '/services/web-design-development'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/s3.svg`,
            title: 'E-Commerce & CMS',
            desc: 'Attract the customers with the best ROI.',
            btn: 'Click Now',
            link: '/services/ecommerce-and-cms'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/s4.svg`,
            title: 'Animation',
            desc: "We convert your idea into reality.",
            btn: 'Know More',
            link: '/services/animation'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/s5.svg`,
            title: 'Designing',
            desc: 'Get the first-rate design to entice.',
            btn: 'Know More',
            link: '/services/design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/services/s6.svg`,
            title: 'Digital Marketing & Content Solutions',
            desc: 'Let’s achieve success together.',
            btn: 'Know More',
            link: '/services/digital-marketing-and-contentSolutions'
        }
    ]
    const Portfolio = [
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p1.svg`,
            title: 'Website Design',
            desc: 'We build what you think.',
            btn: 'Know More',
            link: '/portfolio/website-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p5.svg`,
            title: 'Graphic Design',
            desc: "We innovate what you think.",
            btn: 'Know More',
            link: '/portfolio/graphic-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p3.svg`,
            title: 'Brochure Design',
            desc: 'Be remarkable to others.',
            btn: 'Know More',
            link: '/portfolio/brochure-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p4.svg`,
            title: 'Coroprate Brand Identity Design',
            desc: "Your reputation is our responsibility.",
            btn: 'Know More',
            link: '/portfolio/brandidentity-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p5.svg`,
            title: 'Logo Design',
            desc: 'We make you inimitable.',
            btn: 'Know More',
            link: '/portfolio/logo-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p6.svg`,
            title: 'Business Card Design',
            desc: 'Have your business in everybody’s pocket.',
            btn: 'Know More',
            link: '/portfolio/business-card-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p7.svg`,
            title: 'Social Media Design',
            desc: 'We make you go with the trend.',
            btn: 'Know More',
            link: '/portfolio/social-media-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p8.svg`,
            title: 'Flyer/Pamphlet Design',
            desc: 'Take your business to the elevation(incomparable) with us.',
            btn: 'Know More',
            link: '/portfolio/pamphlet-design'
        }, {
            icon: `${process.env.REACT_APP_URL}/images/pages/protfolio/p9.svg`,
            title: 'T-shirt Design',
            desc: 'Grab the deal, to make your competitor defeat.',
            btn: 'Know More',
            link: '/portfolio/tshirt-design'
        }
    ]


    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                width='100% '
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}

            >
                {value === index && (
                    <Box sx={{ mt: 16, p: 3, width: '100%' }}>
                        <Box sx={{ fontFamily: theme.palette.typography.fontFamily }}>{children}</Box>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setLogo(true);
        } else {
            setLogo(false);
        }
    };
    window.addEventListener("scroll", changeBackground);

    const LoGoCompo = () => (
        <>
            {theme.palette.mode == "light" ?
                <Link to="/" style={{ textDecoration: 'none' }} aria-label='Ugam Infotech' title='Ugam Infotech' rel='follow' >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: theme.palette.secondary.main }}>
                        <Box sx={{ width: { sm: '250px', xs: '180px' }, mt: 1 }}>
                            <img src={`${process.env.REACT_APP_URL}/images/ugam-logo/ugamlightlogo.png`} width='100%' />
                        </Box>
                    </Box>
                </Link>
                :
                <Link to="/" style={{ textDecoration: 'none' }} aria-label='Ugam Infotech' title='Ugam Infotech' rel='follow' >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', color: theme.palette.primary.main }}>
                        <Box sx={{ width: { sm: '250px', xs: '180px' }, mt: 1 }}>
                            <img src={`${process.env.REACT_APP_URL}/images/ugam-logo/ugamdarklogo.png`} width='100%' />
                        </Box>
                    </Box>
                </Link>
            }
        </>
    )

    if (!localStorage.getItem('selectOption')) {
        localStorage.setItem('selectOption', JSON.stringify(0))
    }

    useEffect(() => {
        const savedTab = JSON.parse(localStorage.getItem('selectOption'));
        setSelectedOption(savedTab);
    }, [])


    const handleListItemClick = (name, i) => {
        localStorage.setItem('selectOption', JSON.stringify(i))
        setSelectedOption(i);
    };

    return (

        <List className='demo' sx={{ position: 'relative', background: theme.palette.background.default, backgroundColor: theme.palette.background.default }} >
            <AppBar component="nav" sx={{ backgroundColor: theme.palette.background.default, padding: '20px', boxShadow: 'none', position: 'fixed', backgroundImage: 'none' }}>
                <Container maxWidth='xl'>
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between', p: 0 }}>
                        <Box onClick={handlePass('right', false)} >
                            <LoGoCompo />
                        </Box>
                        <Box sx={{ width: '100%', marginLeft: { lg: 16, md: 4 } }}>
                            <Box sx={{ display: { xs: 'none', md: 'flex', transition: 'all .4s ease-in-out' } }}>
                                <Box onClick={handlePass('right', false)} >
                                    <Tab sx={{
                                        mx: { lg: 2, md: 1, sm: 2, xs: 1 }, opacity: 1, fontSize: { lg: '16px', md: '12px' }, transition: 'all .2s ease-in-out', fontWeight: 800, color: theme.palette.primary.main, '&:hover': {
                                            color: theme.palette.secondary.main
                                        }, px: 0
                                    }} label={'Home'} onClick={() => navigate('/')} />
                                </Box>
                                <Tabs value={selectedOption} onChange={handleChange} aria-label="basic tabs example" sx={{
                                    '.MuiTabs-indicator': {
                                        backgroundColor: theme.palette.secondary.main,
                                        height: '4px'
                                    }
                                }} >
                                    {sideBarnavItems.map((item, i) => (
                                        <Tab key={i} sx={{
                                            mx: { lg: 3, md: 1, sm: 2, xs: 1 }, padding: { md: '12px 2px' }, transition: 'all .2s ease-in-out', fontSize: { lg: '16px', md: '12px' }, fontWeight: 800, color: theme.palette.primary.main, '&:hover': {
                                                color: theme.palette.secondary.main,
                                            },
                                        }} label={item.name} {...a11yProps(i)} onClick={() => handleListItemClick(item.name, i)} />
                                    ))}
                                </Tabs>
                                <Box onClick={handlePass('right', false)} >
                                    <Tab sx={{
                                        mx: { lg: 2, md: 1, sm: 2, xs: 1 }, opacity: 1, fontSize: { lg: '16px', md: '12px' }, transition: 'all .2s ease-in-out', fontWeight: 800, color: theme.palette.primary.main, '&:hover': {
                                            color: theme.palette.secondary.main,
                                            backgroundColor: theme.palette.background.default
                                        }, px: 0
                                    }} onClick={() => navigate('/contact-us')} label={'contact Us'} />
                                </Box>
                            </Box>

                        </Box>
                        <Button sx={{ fontSize: '22px', padding: '7px 8px', minWidth: '47px', fontWeight: 600, color: theme.palette.secondary.main }} onClick={handlePass('right', false)}><CloseOutlinedIcon /></Button>

                    </Toolbar>
                </Container >
            </AppBar>

            <CustomTabPanel value={selectedOption} index={0}>
                <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                    <DesktopSidebar comapny={Comapny} handleDrawer={handlePass('right', false)} />
                </Box>
                <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                    <MobileSidebar comapny={Comapny} handleDrawer={handlePass('right', false)} services={Services} portfolio={Portfolio} />
                </Box>

            </CustomTabPanel>
            <CustomTabPanel value={selectedOption} index={1}>
                <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                    <DesktopSidebar comapny={Services} handleDrawer={handlePass('right', false)} />
                </Box>
                <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                    <MobileSidebar comapny={Comapny} services={Services} handleDrawer={handlePass('right', false)} portfolio={Portfolio} />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={selectedOption} index={2}>
                <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                    <DesktopSidebar comapny={Portfolio} handleDrawer={handlePass('right', false)} />
                </Box>
                <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                    <MobileSidebar comapny={Comapny} services={Services} handleDrawer={handlePass('right', false)} portfolio={Portfolio} />
                </Box>
            </CustomTabPanel>


            <Container maxWidth='lg' sx={{ mt: { md: 10, sm: 4, xs: 4 } }}>
                <Grid container >
                    <Grid item lg={9} md={12} sx={{ display: { md: 'flex' }, margin: { sm: 'auto' }, justifyContent: { lg: 'flex-start', md: 'center' }, textAlign: { xs: 'center' } }}>
                        <Typography variant='h3' sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 800, fontSize: { md: '34px', xs: '26px' }, color: theme.palette.primary.main }}>We are Hiring! Start your journey today
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={12} sx={{ marginTop: { md: '20px', sm: '20px', xs: '20px' }, margin: { md: '20px auto', sm: '20px auto', xs: '20px auto' }, textAlign: { md: 'center' } }} >
                        <CommonBtn handleDrawer={handlePass('right', false)} link={'/company/careers'} backgroundColor={theme.palette.secondary.main} text={'GROW CAREER WITH US'} name={'Add'} hoverBGColor={theme.palette.secondary.main} />
                    </Grid>
                </Grid>
                <Grid container sx={{
                    justifyContent: { lg: 'start', md: 'center', xs: 'center' }, '&::before': {
                        backgroundColor: theme.palette.secondary.main,
                        bottom: 0,
                        content: '""',
                        height: '10px',
                        left: '25%',
                        margin: '0 auto',
                        position: 'absolute',
                        width: '50%'
                    }
                }} >
                    <Grid lg={8} md={12} sm={12} item>
                        <Box className='custom' sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { lg: 'start', md: 'center' } }}>
                            <Box sx={{ marginLeft: { lg: '0px', md: '0px', sm: '65px', xs: '9px' } }} >
                                <Typography variant='h6' sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 400, fontSize: '16px', color: theme.palette.primary.main }}>
                                    or call us at
                                </Typography>
                                <Link to='tel:+91-8460961523' style={{ textDecoration: 'none' }} aria-label="our contact detail" title='contact' rel='noopener noreferrer'>
                                    <Typography variant='h6' sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 700, fontSize: '20px', color: theme.palette.primary.main }}>
                                        +91(846)-096-1523
                                    </Typography>
                                </Link>
                            </Box>
                            <Box sx={{ justifyContent: { xs: 'center' }, marginLeft: { md: '65px', sm: '65px', xs: '9px' } }}>
                                <Typography variant='h6' sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 400, fontSize: '16px', color: theme.palette.primary.main }}>
                                    or mail us at
                                </Typography>
                                <Link to='mailto:ugaminfotech.com' style={{ textDecoration: 'none' }} aria-label="our email detail" title='email' rel='noopener noreferrer'>
                                    <Typography variant='h6' sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 700, fontSize: { md: '20px', xs: '16px' }, color: theme.palette.primary.main }}>
                                        info@ugaminfotech.com
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid lg={4} item>
                        <Box sx={{ justifyContent: 'end', display: 'flex', flexWrap: 'wrap', marginTop: '20px', marginBottom: '80px', gap: { lg: 4, md: 8, sm: 6, xs: 4 } }}>
                            <Link to='https://www.facebook.com/ugaminfotech' target='_blank' aria-label='Visit our facebook page' rel='noopener noreferrer' title='Facebook'>
                                <FacebookIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer', width: '30px', height: 'auto' }} />
                            </Link>
                            <Link to='https://www.linkedin.com/company/ugam-infotech' target='_blank' aria-label='Visit our linkedin page' rel='noopener noreferrer' title='Linkedin'>
                                <LinkedInIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer', width: '30px', height: 'auto' }} />
                            </Link>
                            <Link to='https://www.instagram.com/ugaminfotech/' target='_blank' aria-label='Visit our instagram page' rel='noopener noreferrer' title='Instagram'>
                                <InstagramIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer', width: '30px', height: 'auto' }} />
                            </Link>
                        </Box>
                    </Grid>

                </Grid>
            </Container>

        </List >
    )
}
