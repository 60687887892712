import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react';
import { ServiceHeading } from '../../../Common';

const DesignGraphics = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ width: { lg: '40%', sm: '50%', xs: '100%' }, m: '0 auto' }}>
                    <img width='100%' src={`${process.env.REACT_APP_URL}/images/pages/services/designing/graphic-design.png`} alt='graphic-design' title='Abstract Geometric Patterns in Graphic Design' loading='lazy' height='auto' />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <ServiceHeading text='Graphic Design' />
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {md: '35px', xs: '28px' } }}>
                        Graphics are one of the most cutting-edge tools for reaching your target audience. Companies should leverage graphic design services to <Typography variant='span' sx={{fontWeight:'bold'}}>enhance their brand image and increase visibility</Typography>, including superior website designs, eye-catching flyers, memorable logos, and striking flexes. High-quality, creative graphics instantly capture and maintain the customer’s attention.
                    </Typography>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {md: '35px', xs: '28px' } }}>
                        At Ugam Infotech, we have a team dedicated to helping you grow and enhance your design business and customer-focused web services. We have successfully completed numerous design projects using the most effective methods. With extensive experience in <Typography variant='span' sx={{fontWeight:'bold'}}> creating designs tailored to customer requirements</Typography>, our expert designers use attractive graphics, vibrant colors, and stunning photos to create designs that leave viewers in awe.
                    </Typography>
                </Box>
                <Box sx={{ mt: { lg: 6, sm: 4, xs: 3 } }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '36px', md: '32px', sm: '28px', xs: '20px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { lg: '54px', md: '48px', sm: '36px', xs: '34px', borderLeft: `5px solid ${theme.palette.secondary.main}` }, pl: { md: 5, sm: 4, xs: 2 } }}>
                        Our Premier Graphic Design <Typography variant='span' sx={{fontWeight:'bold'}}>Services</Typography> Include:
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default DesignGraphics