import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ArrowCommon, ServiceHeading } from '../../../Common';

export const WordPress = () => {
    const theme = useTheme();

    const arr = [
        'WordPress plugins',
        'Web Development services',
        'Plugin Development',
        'Customised themes',
        'Installation & Configuration',
        'e-Commerce Solutions',
        'Responsive Design',
        'Blog Development services',
        'CMS Development'
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { lg: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Box>
                    <Box>
                        <ServiceHeading text='WordPress' />
                        <Typography sx={{ fontSize: { sm: '18px', xs: '16px' }, lineHeight: { sm: '35px', xs: '28px' }, fontFamily: theme.palette.typography.fontFamily }}>We are building a customized e-Commerce website tailored to create a fully functional and robust online presence. Our process includes integrating essential plugins and functionalities to enhance performance.<br />
                            To leverage the best WordPress results, at Ugam Infotech, we provide comprehensive web development services, delivering expert digital solutions from conception through deployment, including data migration, API integrations, and plugin creation.</Typography>
                        <Typography sx={{ fontSize: { sm: '18px', xs: '16px' }, my: 3, lineHeight: { sm: '35px', xs: '28px' }, fontFamily: theme.palette.typography.fontFamily }}>If you are seeking WordPress solutions, we are here to assist you with:</Typography>
                    </Box>
                    <Box sx={{
                        backgroundColor: theme.palette.background.lightDark, mb: 5, position: 'relative', '& ::before': {
                            backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/pages/services/ecommerce-and-cms/wordpress.png`})`,
                            height: { lg: 319, md: 300 },
                            display: { md: 'block', sm: 'none', xs: 'none' },
                            width: { lg: 390, md: 348 },
                            content: '""',
                            position: 'absolute',
                            top: { lg: -40, md: -14 },
                            right: { lg: 0, md: -0 },
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        }
                    }}>
                        <Grid container>
                            <Grid item lg={9} md={9}>
                                <Grid container spacing={3} sx={{ m: { lg: 3, md: 1.5 }, p: 2 }}>
                                    {arr.map((val, i) => (
                                        <Grid item key={i} lg={4} md={5} sm={6} xs={10}>
                                            <ArrowCommon text={val} fontWeight={400} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 7, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' }, fontWeight: 'bold' }}>We have successfully completed numerous WordPress projects and received positive feedback from our clients. For more information, please explore our client reviews and testimonials.</Typography>
                </Box>
            </Container>
        </Box>
    )
}
