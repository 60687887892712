import React, { useEffect } from 'react'
import PageHeading from '../../../../commonComponents/PageHeading'
import { Box, Container, Divider, Grid, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css';
import { AllwebDesigntab } from '../../../Json'


const Wordpress = () => {
    const theme = useTheme();


    useEffect(() => {
        window.scrollTo(0, 0);
        new WOW.WOW({
            offset:100
        }).init();
    }, [])

    let Allwebpage = [
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage1.png`,
            name: 'homepage1'
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage2.png`,
            name: 'homepage2'
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage3.png`,
            name: 'homepage3'
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage4.png`,
            name: 'homepage4'
        },
        {
            laptop: `${process.env.REACT_APP_URL}/images/laptop.png`,
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/webdesign-devlop/homepage5.png`,
            name: 'homepage5'
        }
    ]



    return (
        <>
            <PageHeading text={'WordPress'} />
            <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { lg: 10, md: 8, sm: 6, xs: 4 } }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center" }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '14px', xs: "12px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' }, fontFamily: theme.typography.fontFamily }}><Box component='span' sx={{ fontWeight: 600 }}>WordPress</Box>  is a popular content management system (CMS) that enables businesses and individuals to build and administer their own websites. We at  <Box component='span' sx={{ color: `${theme.palette.secondary.main}`, fontWeight: 400 }}>Ugam Infotech</Box> providing best WordPress design in a wide range of possibilities for businesses and organisations that require the development and maintenance of WordPress websites.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', sm: "row" }, alignItems: 'stretch', textAlign: 'center', mt: 6 }}>
                            {
                                AllwebDesigntab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>

                                        {
                                            item.text == "WordPress" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link}  style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                        }

                                        {index < AllwebDesigntab.length  &&  !index == 0 &&(
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px'
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ mt: 6 }}>
                            <Grid container spacing={2} sx={{ padding: '0px' }}>
                                {
                                    Allwebpage?.map((item ,i) => (
                                            <Grid key={i} item xs={12} md={6} sx={{ textAlign: 'center', mb: 8 }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box className="wow animate__animated animate__pulse"
                                                        sx={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            maxWidth: { lg: '480px', md: '370px', sm: '470px', xs: '285px' },
                                                            height: { xs: '220px', md: '334px', sm: '376px', lg: '370px' },
                                                            padding: '25px 0 51px'
                                                        }}
                                                    >
                                                        <img
                                                            src={item.laptop}
                                                            alt="laptop"
                                                            style={{ position: 'absolute', top: '0', left: '0' }}
                                                            title='Laptop Screen'
                                                            loading='lazy'
                                                            width='100%'
                                                            height='100%'
                                                        />
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: { xs: '47px', sm: '76px', md: '70px', lg: '75px' },
                                                                right: { xs: '41px', sm: '66px', md: '56px', lg: '70px' },
                                                                width: { xs: '206px', sm: '340px', md: '263px', lg: '344px' },
                                                                height: { xs: '113px', sm: '200px', md: '174px', lg: '197px' },
                                                                overflow: 'hidden',
                                                                display: 'block',
                                                                '& img': {
                                                                    transition: 'transform 10s ease',
                                                                    width: '100%'
                                                                },
                                                                '&:hover img': {
                                                                    transform: 'translateY(-100%)'
                                                                }
                                                            }}
                                                        >
                                                            <img src={item.img} alt="Home Page" title='All Web page design' loading='lazy' width='auto' height='auto'/>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Grid>                                      
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Wordpress