import { Box, Container, Typography, useTheme } from '@mui/material';
import React from 'react'
import { ServiceHeading } from '../../../Common';

const DesignExperienceDesign = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { md: 10, xs: 8 } }}>
            <Container maxWidth="xl">
                <Box sx={{textAlign:{md:'left' , xs:"center"}}}>
                    <ServiceHeading text='Customer Experience Design' />
                </Box>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight:{md:'35px' , xs:'28px' }}}>
                    We adopt a successful approach by applying best practices and leveraging the latest technological tools. Our UX team designs solutions that are easy to use and tailored to meet the unique needs and business objectives of our clients.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 5, md: 4, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight:{md:'35px' , xs:'28px' }}}>
                    At Ugam Infotech, we offer a range of design services, including <Typography variant='span' sx={{fontWeight:'bold'}}>UX research, interaction design, comprehensive usability audits, and more</Typography>, ensuring that the end user is always at the center of our design considerations.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 5, md: 4, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight:{md:'35px' , xs:'28px' }}}>
                    We are dedicated to prioritizing your requirements and will ensure that all essential and desired features are covered and provided.
                </Typography>
            </Container>
        </Box>
    )
}

export default DesignExperienceDesign