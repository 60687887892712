import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ArrowCommon } from '../../../Common';

const DesignPageDesc = () => {
    const theme = useTheme();

    const arr = [
        'The UI design should incorporate a variety of features, such as ample and intuitive space within the website structure.',
        'To enhance readability and usability, our team is meticulous in selecting the colors and content.',
        'We choose the most suitable icons that complement the website’s design.',
        'We focus on ensuring the user interface functions seamlessly across all platforms.',
        'Our primary goals are to provide user-friendly, animated, and responsive designs that meet the needs of our satisfied customers.'
    ]

    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { lg: 10, xs: 8 } }}>
            <Container>
                <Box>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                        To achieve the specific goals of using an app or visiting a website, you navigate through numerous screens and interact with various features. To make every action more interesting and engaging, we have a <Typography variant='span' sx={{fontWeight:'bold'}}>dedicated team focused</Typography> on creating customer-centric and responsive UI designs.
                    </Typography>
                </Box>
                <Box sx={{
                    p: { lg: 6, md: 5, sm: 4, xs: 3 }, position: 'relative', border: `1px solid ${theme.palette.primary.light}`, mt: { lg: 6, md: 5, sm: 4, xs: 3 }, '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: { md: -16, xs: -10 },
                        borderBottom: { md: `15px solid ${theme.palette.primary.light}`, xs: `10px solid ${theme.palette.primary.light}` },
                        borderLeft: { md: `15px solid transparent`, xs: `10px solid transparent` },
                        borderRight: { md: `15px solid transparent`, xs: `10px solid transparent` }
                    }
                }}>
                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 600, fontSize: { lg: '30px', md: '28px', sm: '24px', xs: '22px' } }}>
                        While serving our customers, we are primarily focused on:
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        {arr.map((item, i) => (
                            <Box key={i} sx={{ my: { xs: 2 } }}>
                                <ArrowCommon text={item} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                            </Box>))}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default DesignPageDesc