import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import Desc from './Components/Desc'
import SocialMediaMarketing from './Components/SocialMediaMarketing'
import ContentManagment from './Components/Content'
import ServiceOtherCommon from '../Common/ServiceOtherCommon'
import SEO_Common from './Components/SEO'
import SEO from '../../SEO'

const DigitalMarketingAndContentSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <SEO
        title="Digital Marketing & Content Solutions - Ugam Infotech"
        description="Explore our digital marketing and content solutions at Ugam Infotech. We provide innovative strategies to enhance your online presence and engage your audience."
        keywords="Ugam, Ugam Infotech, digital marketing, content solutions, online presence, audience engagement, marketing strategies, SEO, content creation"
        canonical={`${process.env.REACT_APP_URL}/services/digital-marketing-and-contentSolutions`}
        ogTitle="Digital Marketing & Content Solutions - Ugam Infotech"
        ogDescription="Explore our digital marketing and content solutions at Ugam Infotech. We provide innovative strategies to enhance your online presence and engage your audience."
        ogType="website"
        ogUrl={`${process.env.REACT_APP_URL}/services/digital-marketing-and-contentSolutions`}
        ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
        twTitle="Digital Marketing & Content Solutions - Ugam Infotech"
        twDescription="Explore our digital marketing and content solutions at Ugam Infotech. We provide innovative strategies to enhance your online presence and engage your audience."
        twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
      />
      <PageHeading text={'Digital Marketing & Content Solutions'} />
      <Desc />
      <SEO_Common />
      <SocialMediaMarketing />
      <ContentManagment />
      <ServiceOtherCommon />
    </>
  )
}

export default DigitalMarketingAndContentSolutions