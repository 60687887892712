import React, { useEffect } from 'react'
import PageHeading from '../../commonComponents/PageHeading'
import { Box, Container, Divider, useTheme } from '@mui/material'
import { Link } from 'react-router-dom';
import LogoComponent from './Components/LogoComponent';

import { alltab } from '../Json';
import SEO from '../../SEO';

const LogoDesign = () => {
    const theme = useTheme();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    let logocomponet1 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo1.jpg`,
            name :'logo1'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo2.jpg`,
            name :'logo2'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo3.jpg`,
            name :'logo3'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo4.jpg`,
            name :'logo4'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo5.jpg`,
            name :'logo5'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo6.jpg`,
            name :'logo6'
        }
    ]

    let logocomponet2 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo7.jpg`,
            name :'logo7'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo8.jpg`,
            name :'logo8'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo9.jpg`,
            name :'logo9'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo10.jpg`,
            name :'logo10'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo11.jpg`,
            name :'logo11'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo12.jpg`,
            name :'logo12'
        }
    ]

    let logocomponet3 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo13.jpg`,
            name :'logo13'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo14.jpg`,
            name :'logo14'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo15.jpg`,
            name :'logo15'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo16.jpg`,
            name :'logo16'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo17.jpg`,
            name :'logo17'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo18.jpg`,
            name :'logo18'
        }
    ]


    let logocomponet4 = [
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo19.jpg`,
            name :'logo19'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo20.jpg`,
            name :'logo20'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo21.jpg`,
            name :'logo21'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo22.jpg`,
            name :'logo22'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo23.jpg`,
            name :'logo23'
        },
        {
            img: `${process.env.REACT_APP_URL}/images/pages/protfolio/logo-design/logo24.jpg`,
            name :'logo24'
        }
    ]

    return (
        <>
            <SEO
                title="Logo Design - Ugam Infotech"
                description="Explore our logo design services at Ugam Infotech. We create unique and memorable logos that represent your brand identity."
                keywords="Ugam, Ugam Infotech, logo design services, unique logos, brand identity, memorable logos"
                canonical={`${process.env.REACT_APP_URL}/portfolio/logo-design`}
                ogTitle="Logo Design - Ugam Infotech"
                ogDescription="Explore our logo design services at Ugam Infotech. We create unique and memorable logos that represent your brand identity."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/portfolio/logo-design`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Logo Design - Ugam Infotech"
                twDescription="Explore our logo design services at Ugam Infotech. We create unique and memorable logos that represent your brand identity."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Logo Design'} />
            <Box sx={{ overflow: 'hidden', width: '100%', backgroundColor: theme.palette.background.lightDark }}>
                <Container maxWidth='xl' >
                    <Box sx={{ paddingTop: '50px', textAlign: "center", marginBottom: '10px' }}>
                        <Box component='p' sx={{ fontSize: { md: '21px', sm: '16px', xs: "14px" }, lineHeight: { md: '48px', sm: '36px', xs: '30px' } }}>Our logo design services are provided by a team of professional graphic designers with extensive experience in the IT industry. These services generally include crafting custom logos that are tailored to the unique needs and branding objectives of a business or organization.</Box>
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: '-70px !important' }}>
                        <Box sx={{
                            display: 'flex', justifyContent: "center", flexDirection: { xs: 'column', md: "row" },
                            alignItems: 'stretch', textAlign: 'center', mt: 6
                        }}>
                            {
                                alltab?.map((item, index) => {
                                    return <Box key={index} sx={{mb:{sm:0 , xs:1}}}>
                                        {
                                            item.text == "Logo Design" ?
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.main
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>

                                                :
                                                <Link to={item.link} style={{
                                                    textDecoration: 'none', margin: "10px 6px 10px 6px", color: theme.palette.secondary.dark
                                                }} aria-label={item.arialable} title={item.text} rel='follow'>{item.text}</Link>
                                        }

                                        {index < alltab.length  && !index == 0 && (
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    height: '18px',
                                                    display: { sm: 'block', xs: 'none' },
                                                    marginTop: -2.7,
                                                    backgroundColor: '#646464',
                                                    width: '1px',
                                                    display: { lg: 'block', xs: 'none' }
                                                }}
                                            />
                                        )}
                                    </Box>
                                })
                            }
                        </Box>
                        <Box sx={{ margin: '50px 0px 140px' }}>
                            <LogoComponent allLogoimages={logocomponet1} />
                            <LogoComponent allLogoimages={logocomponet2} />
                            <LogoComponent allLogoimages={logocomponet3} />
                            <LogoComponent allLogoimages={logocomponet4} />
                        </Box>
                    </Box>

                </Container >
            </Box>
        </>
    )
}

export default LogoDesign