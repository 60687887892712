import React, { useEffect } from 'react'
import Language from './component/language'
import Benefite from './component/Benefite'
import Award from './component/Award'
import CareerPageDesc from './component/CareerPageDesc'
import CareerJoin from './component/CareerJoin'
import PageHeading from '../../commonComponents/PageHeading'
import SEO from '../../SEO'

function Careers() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SEO
                title="Careers at Ugam Infotech"
                description="Join the Ugam Infotech team! Explore career opportunities in React.js, Node.js, and other backend and frontend technologies."
                keywords="Ugam, Ugam Infotech, careers, job openings, React.js, Node.js, web development, software development, backend, frontend"
                canonical={`${process.env.REACT_APP_URL}/company/careers`}
                ogTitle="Careers at Ugam Infotech"
                ogDescription="Join the Ugam Infotech team! Explore career opportunities in React.js, Node.js, and other backend and frontend technologies."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/company/careers`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="Careers at Ugam Infotech"
                twDescription="Join the Ugam Infotech team! Explore career opportunities in React.js, Node.js, and other backend and frontend technologies."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={'Careers'} />
            <CareerPageDesc />
            <CareerJoin />
            <Language />
            <Benefite />
            <Award />
        </>
    )
}

export default Careers