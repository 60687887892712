import React from 'react'
import { Box, useTheme } from '@mui/material';
import SlickSlider from './slick';


const TrustLogoslick = ({ slidesFirst, slidesSecond, slidesThird }) => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark }}>
            <SlickSlider slidesFirst={slidesFirst} slidesSecond={slidesSecond} slidesThird={slidesThird} />
        </Box>
    )
}

export default TrustLogoslick