import { AlertTitle, Box, Button, CircularProgress, Container, FormControl, Grid, Input, InputLabel, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import '../../../../../App.css';
import XIcon from '@mui/icons-material/X';
import PinterestIcon from '@mui/icons-material/Pinterest';
import { CommonBtn } from '../../../../CustomStyle/Common';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_API_URL;


const Contact = () => {
    const theme = useTheme();
    const [formData, setformData] = useState({
        Fname: '',
        Lname: '',
        Email: '',
        Subject: '',
        ContactNo: ''
    })

    const [file, setFile] = useState(null);
    const [errorfile, seterrorfile] = useState('');
    const [fileName, setFileName] = useState('');
    const [open, setOpen] = useState(false);
    const [AlertState, setAlertState] = useState({ type: '', message: '' });

    const [errors, setErrors] = useState({
        Fname: '',
        Lname: '',
        Email: '',
        Subject: '',
        ContactNo: ''
    });

    const [Loading, setLoading] = useState(false);

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setformData({
            ...formData,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: ''
        });
        setOpen(false)

    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name)
        seterrorfile("")
        setOpen(false)
    };

    const validateForm = () => {
        setOpen(true)
        let formIsValid = true;
        let errors = {};
        let errorfiles = "";
        const namePattern = /^[a-zA-Z]+$/;

        if (!formData.Fname) {
            formIsValid = false;
            errors.Fname = "First Name is required";
        } else if (!namePattern.test(formData.Fname)) {
            formIsValid = false;
            errors.Fname = "First Name must contain only alphabets";
        }

        if (!formData.Lname) {
            formIsValid = false;
            errors.Lname = "Last Name is required";
        } else if (!namePattern.test(formData.Lname)) {
            formIsValid = false;
            errors.Lname = "Last Name must contain only alphabets";
        }
        if (!formData.Email) {
            formIsValid = false;
            errors.Email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
            formIsValid = false;
            errors.Email = 'Email is invalid';
        }
        if (!formData.ContactNo) {
            formIsValid = false;
            errors.ContactNo = 'Contact No is required';
        }
        if (!formData.Subject) {
            formIsValid = false;
            errors.Subject = 'Subject is required';
        }
        if (file == null) {
            formIsValid = false;
            errorfiles = "File is required";
        }

        setErrors(errors);
        seterrorfile(errorfiles);
        setAlertState({ type: 'error', message: '* Filed is required' })
        return formIsValid;
    };

    // src/utils/encryption.js

    const secretKey = 'seacret_key';

    const encrypt = (text) => {
        return CryptoJS.AES.encrypt(text, secretKey).toString();
    };

    const gettoken = () => {
        const formDataToSend = new FormData();
        formDataToSend.append('hash', encrypt("9537650231"));
        return axios.post(`${API_URL}/contact/token`, formDataToSend, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            return res.data.accessToken
        })
    }

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            setOpen(false);
            const { Fname, Lname, Email, Subject, ContactNo } = formData

            const formDataToSend = new FormData();
            formDataToSend.append('Name', Fname + ' ' + Lname);
            formDataToSend.append('to', Email);
            formDataToSend.append('subject', Subject);
            formDataToSend.append('ContactNo', ContactNo);
            // formDataToSend.append('text', `My name is ${Fname}`);
            if (file) {
                formDataToSend.append('file', file);
            }

            try {
                const token = await gettoken();

                const response = await axios.post(`${API_URL}/contact/send-email`, formDataToSend, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                if (response.data) {
                    setLoading(false);
                    setAlertState({ type: 'success', message: 'Thank you for contact us !!' })
                    setOpen(true)
                    setformData({
                        Fname: '',
                        Lname: '',
                        Email: '',
                        Subject: '',
                        ContactNo: ''
                    });
                    setFile(null);
                    setFileName('');
                } else {
                    setLoading(false);
                    throw new Error('Failed to send email');
                }
            } catch (error) {
                setAlertState({ type: 'error', message: 'Failed to send email' });
                setOpen(true)
                setLoading(false);
            }
        }


    }



    useEffect(() => {
        // gettoken()
        // window.scrollTo(0, 0);
    }, [])

    return (
        <Box sx={{ overflow: 'hidden', backgroundColor: theme.palette.background.lightDark }}>
            <Container maxWidth='xl'>
                <Grid container>
                    <Grid item md={5} sm={12}>
                        <Box sx={{ padding: { md: '70px 43px 0', md: '60px 0' }, ml: { xl: 'auto' }, width: { lg: '75%', md: '100%' }, display: { md: 'block', sm: 'flex' }, flexWrap: 'wrap', gap: 4 }}>
                            <Box>
                                <Typography sx={{ color: theme.palette.primary.main, mb: 1, fontSize: '16px' }}>Call us on</Typography>
                                <Link style={{ textDecoration: 'none' }} to="tel:+91-8460961523" aria-label="our contact detail" rel="nofollow" title='+91(846)-096-1523'>
                                    <Typography sx={{ fontSize: '29px', lineHeight: '35px', fontFamily: theme.palette.typography.fontFamily, fontWeight: 800, color: theme.palette.primary.main }}>
                                        +91(846)-096-1523
                                    </Typography>
                                </Link>
                            </Box>
                            <Box sx={{ mt: { md: 5, sm: 0, xs: 3 } }}>
                                <Typography sx={{ color: theme.palette.primary.main, mb: 1, fontSize: '16px' }}>Mail us</Typography>
                                <Link style={{ textDecoration: 'none' }} to="mailto:ugaminfotech.com" aria-label="our email detail" rel="nofollow" title='info@ugaminfotech.com'>
                                    <Typography sx={{ fontSize: '20px', lineHeight: '35px', mb: 1, fontFamily: theme.palette.typography.fontFamily, fontWeight: 800, color: theme.palette.primary.main }}>info@ugaminfotech.com</Typography>
                                </Link>
                            </Box>
                            <Box sx={{ mt: { md: 5, sm: 0, xs: 3 } }}>
                                <Typography sx={{ fontFamily: theme.palette.typography.fontFamily }}>Follow us</Typography>
                                <Box sx={{ width: '100%', mt: 2 }}>
                                    <Link to='https://www.facebook.com/ugaminfotech' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Facebook' aria-label="Visit our Facebook page">
                                        <FacebookIcon sx={{ fontSize: '30px', color: theme.palette.primary.main }} />
                                    </Link>
                                    <Link to='https://www.linkedin.com/company/ugam-infotech' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Linkedin' aria-label="Visit our Linkedin page">
                                        <LinkedInIcon sx={{ fontSize: '30px', ml: 2, color: theme.palette.primary.main }} />
                                    </Link>
                                    <Link to='https://www.instagram.com/ugaminfotech/' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Instagram' aria-label="Visit our Instagram page">
                                        <InstagramIcon sx={{ fontSize: '30px', ml: 2, color: theme.palette.primary.main }} />
                                    </Link>
                                </Box>
                            </Box>
                            <Box sx={{ my: { md: 10, sm: 4, xs: 5 } }}>
                                <Typography sx={{ fontSize: '24px', lineHeight: '30px', fontFamily: theme.palette.typography.fontFamily, fontWeight: 700 }}>Join Us!</Typography>
                                <Typography sx={{ fontSize: '19px', fontFamily: theme.palette.typography.fontFamily, lineHeight: '42px', mt: 2 }}>
                                    Join our in-house team and help enhance the corporate ecosystem by simplifying connections between brands and talents.</Typography>
                                <Box sx={{ textAlign: 'left', mt: 3 }}>
                                    <Link to="/company/careers" aria-label="Learn more about career opportunities at Ugam Infotech" title='grow career with us' rel="follow">
                                        <CommonBtn text={'grow career with us'} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} />
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={7} sm={12} sx={{ position: 'relative', pt: 2, pr: 1 }}>
                        <Box sx={{ position: 'absolute', top: 0, left: { sm: 0, xs: -20 }, right: { sm: '-100%', xs: 0 }, height: '100%', width: { sm: '100%', xs: '130%' }, backgroundColor: theme.palette.background.default }}></Box>
                        <Box sx={{ position: 'relative', zIndex: 1, height: '100%', width: '100%', backgroundColor: theme.palette.background.default, py: { md: 6, xs: 4 }, pl: { lg: 6, md: 5, xs: 1 } }}>
                            {
                                open ?
                                    <Alert
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        severity={AlertState.type}
                                        autohideduration={2000}
                                        sx={{ mb: 2, mt: '-40px', backgroundColor: theme.palette.background.lightDark }}
                                    >
                                        <AlertTitle sx={{ textTransform: 'capitalize' }}>{AlertState.type}</AlertTitle>
                                        {AlertState.message}
                                    </Alert> : ''

                            }
                            <Box sx={{ p: { sm: 3, xs: 0 } }}>
                                <Box>
                                    <Typography sx={{ fontSize: '29px', lineHeight: '35px', fontFamily: theme.palette.typography.fontFamily, fontWeight: 800 }}>Ready to Serve You First!</Typography>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Grid item lg={12}>
                                        <TextField
                                            error={!!errors.Fname}
                                            helperText={errors.Fname}
                                            sx={{ mt: 2, mr: { sm: 1 }, width: { lg: '48%', md: '50%', sm: '48%', xs: '100%' } }}
                                            placeholder='Enter First Name'
                                            label="First Name"
                                            name="Fname"
                                            value={formData.Fname}
                                            onChange={onChangeHandler}
                                            required
                                            variant="outlined"
                                        />
                                        <TextField
                                            error={!!errors.Lname}
                                            helperText={errors.Lname}
                                            label="Last Name *"
                                            placeholder='Enter Last Name'
                                            sx={{ mt: 2, width: { lg: '50%', md: '48%', sm: '50%', xs: '100%' } }}
                                            variant="outlined"
                                            name="Lname"
                                            value={formData.Lname}
                                            onChange={onChangeHandler}
                                        />
                                        <TextField
                                            error={!!errors.Email}
                                            helperText={errors.Email}
                                            label="Email *"
                                            placeholder='Enter Email'
                                            variant="outlined"
                                            sx={{ mt: 2 }}
                                            name="Email"
                                            fullWidth
                                            value={formData.Email}
                                            onChange={onChangeHandler}
                                        />
                                        <TextField
                                            error={!!errors.ContactNo}
                                            helperText={errors.ContactNo}
                                            label="Contact No. *"
                                            type="number"
                                            placeholder='Enter Contact Number'
                                            variant="outlined"
                                            sx={{ mt: 2, appearance: 'none' }}
                                            name="ContactNo"
                                            value={formData.ContactNo}
                                            onChange={onChangeHandler}
                                            fullWidth
                                        />
                                        <TextField
                                            error={!!errors.Subject}
                                            helperText={errors.Subject}
                                            label="Subject *"
                                            placeholder='Subject'
                                            sx={{ mt: 2 }}
                                            fullWidth
                                            name='Subject'
                                            variant="outlined"
                                            multiline
                                            rows={8}
                                            value={formData.Subject}
                                            onChange={onChangeHandler}
                                        />
                                    </Grid>
                                </Box>
                                <Box sx={{ width: '100%', ml: 'auto', textAlign: { sm: 'auto', xs: 'center' } }} >
                                    <Typography sx={{ fontSize: '18px', textAlign: 'center', lineHeight: '35px', mt: 5, mb: 2, fontFamily: theme.palette.typography.fontFamily, fontWeight: 800 }}>Upload your document *</Typography>
                                    <Button component="label" sx={{ width: '100%' }} >
                                        <Box sx={{
                                            position: 'relative', width: '100%', m: 'auto', height: '100px', border: errorfile ? `1px solid red` : `1px solid ${theme.palette.primary.light}`, '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                bottom: -26,
                                                left: '50%',
                                                width: 55,
                                                height: 55,
                                                borderRadius: '50%',
                                                backgroundColor: theme.palette.secondary.main,
                                                transform: 'translate(-50%, 0)'
                                            }, '&::after': {
                                                backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/pages/company/contact-us/file_upload.svg`})`,
                                                content: '""',
                                                position: 'absolute',
                                                bottom: -26,
                                                left: '50%',
                                                width: 55,
                                                height: 55,
                                                backgroundPosition: '50%',
                                                backgroundRepeat: 'no-repeat',
                                                transform: 'translate(-50%, 0)'
                                            }, '&:hover::after': {
                                                content: '""',
                                                position: 'absolute',
                                                bottom: -10,
                                                left: '0%',
                                                width: '100%',
                                                height: 25,
                                                backgroundImage: `url(${`${process.env.REACT_APP_URL}/images/after_file_upload.svg`})`,
                                                backgroundSize: '24px',
                                                animation: 'fill 2s linear infinite'


                                            }
                                        }}>
                                            <input type="file" style={{ padding: '0px' }} width='100%' onChange={(e) => handleFileChange(e)} hidden />
                                            <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, m: { sm: 2, xs: 1 }, fontWeight: 'bold', fontSize: { lg: 16, md: 12, xs: 12 } }}>{fileName}</Typography>
                                        </Box>
                                    </Button>
                                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: '0.75rem', color: 'red', textAlign: 'left', ml: { sm: 3, xs: 2 } }}>
                                        {errorfile ? errorfile : ''}</Typography>
                                    {
                                        Loading ?
                                            <Box sx={{ textAlign: 'center', position: 'relative', mt: { md: 0, sm: 10, xs: 7 }, mb: { md: 0, sm: 14, xs: 18 } }}>
                                                <CircularProgress
                                                    size={40}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: { lg: 75, md: 75, sm: 0, xs: 0 },
                                                        left: { lg: '47%', md: '46%', sm: '47%', xs: '46%' },
                                                        zIndex: 1,
                                                        color: theme.palette.secondary.main
                                                    }}

                                                />
                                            </Box> :
                                            <Box sx={{ textAlign: 'center', mt: 8 }} onClick={() => handleSubmit()}>
                                                <CommonBtn text={'submit enquiry +'} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} />
                                            </Box>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>

            </Container>
        </Box >
    )
}

export default Contact