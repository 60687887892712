import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'

const ServicePageDesc = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { md: 10, xs: 4 } }}>
            <Container maxWidth="xl">
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                    <Box component='span' sx={{ fontWeight: 700 }}>We deliver incredible software solutions </Box>
                    across various sectors. Our team is dedicated to transforming your ideas into successful projects, always keeping your needs in mind.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                    Are you looking to expand your online presence with top-notch website design and development services that blend functionality, cutting-edge SEO strategies, and modern design? If so, you’ve come to the right place.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                    <Box component='span' sx={{ fontWeight: 700 }}>At Ugam Infotech, </Box>
                    we bring years of expertise in crafting 100% responsive, custom WordPress, WooCommerce, and Shopify websites that drive business success. Our websites are designed to do more than just serve as digital business cards—they are secure, user-friendly, and tailored to enhance user experience and streamline workflows, adhering to the highest standards.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '36px', md: '32px', sm: '28px', xs: '24px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px', borderLeft: `5px solid ${theme.palette.secondary.main}` }, pl: { md: 5, sm: 4, xs: 3 } }}>
                    Customized Website Development
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>We craft custom web designs and development for our clients, leveraging both front-end and back-end technologies to ensure they are unique, accessible, <Box component='span' sx={{ fontWeight: 700 }}>mobile-friendly , </Box>and perfectly tailored to their business needs.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: 0, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                Our web design and development services are designed to generate more leads, making your latest offerings stand out through customized web applications.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                If you are seeking top-notch <Typography variant='span' sx={{fontWeight:'bold'}}>web development, mobile app, and web design services</Typography> , Ugam Infotech has the ideal solution for you.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: 0, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                Our dedicated team at Ugam Infotech combines technical expertise, professional proficiency, and functional insight, focusing on achieving relevant outcomes with extensive experience.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                    We are committed to creating durable commercial solutions that fully meet your requirements.
                </Typography>
            </Container>
        </Box>
    )
}

export default ServicePageDesc