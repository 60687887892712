export const alltab = [
    {
        text: "Graphic Design",
        link: "/portfolio/graphic-design",
        arialable: 'Read more about our Logo Design',
    },
    {
        img: `${process.env.REACT_APP_URL}/images/pages/protfolio/graphic-design/logodesign1.jpg`,
        text: "Logo Design",
        link: "/portfolio/logo-design",
        arialable: 'Read more about our Logo Design',
        name: 'logodesign1'
    },
    {
        img: `${process.env.REACT_APP_URL}/images/pages/protfolio/graphic-design/logodesign2.jpg`,
        text: "Flyer / Pamphlet Design",
        link: "/portfolio/pamphlet-design",
        arialable: 'Read more about our Flyer / Pamphlet Design',
        name: 'logodesign2'
    },
    {
        img: `${process.env.REACT_APP_URL}/images/pages/protfolio/graphic-design/logodesign3.jpg`,
        text: "Brochure Design",
        link: "/portfolio/brochure-design",
        arialable: 'Read more about our Brochure Design',
        name: 'logodesign3'

    },
    {
        img: `${process.env.REACT_APP_URL}/images/pages/protfolio/graphic-design/logodesign4.jpg`,
        text: "Business Card Design",
        link: "/portfolio/business-card-design",
        arialable: 'Read more about our Business Card Design',
        name: 'logodesign4'

    },
    {
        img: `${process.env.REACT_APP_URL}/images/pages/protfolio/graphic-design/logodesign5.jpg`,
        text: "T-shirt Design",
        link: "/portfolio/tshirt-design",
        arialable: 'Read more about our T-shirt Design',
        name: 'logodesign5'
    },
    {
        img: `${process.env.REACT_APP_URL}/images/pages/protfolio/graphic-design/logodesign6.jpg`,
        text: "Brand Identity Design",
        link: "/portfolio/brandidentity-design",
        arialable: 'Read more about our Brand Identity Design',
        name: 'logodesign6'
    },
    {
        img: `${process.env.REACT_APP_URL}/images/pages/protfolio/graphic-design/logodesign7.jpg`,
        text: "Social Media Design",
        link: "/portfolio/social-media-design",
        arialable: 'Read more about our Social Media Design',
        name: 'logodesign7 '
    }
]


export const AllwebDesigntab = [
    {
        text: "All",
        link: "/portfolio/website-design",
        arialable: 'Read more about our All services'
    },
    {
        text: "WordPress",
        link: "/portfolio/website-design/wordpress",
        arialable: 'Read more about our WordPress services'
    },
    {
        text: "Shopify",
        link: "/portfolio/website-design/shopify",
        arialable: 'Read more about our Shopify services'
    },
    {
        text: "Laravel",
        link: "/portfolio/website-design/laravel",
        arialable: 'Read more about our Laravel services'
    },
    {
        text: "CodeIgniter",
        link: "/portfolio/website-design/codeIgniter",
        arialable: 'Read more about our CodeIgniter services'
    },
    {
        text: "React",
        link: "/portfolio/website-design/react",
        arialable: 'Read more about our React services'
    }
]
