import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import '../../../../../App.css'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { useNavigate } from 'react-router-dom';

function Language() {
    const theme = useTheme();
    const navigate = useNavigate();
    const Data = [
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c1.svg`,
            title: 'WordPress Developer',
            desc: 'Looking for skilled WordPress developers to bring websites to life with creativity and expertise.',
            name: 'c1'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c3.svg`,
            title: 'Web Developer',
            desc: 'If you are a tech enthusiast with in-depth knowledge of PHP and JavaScript frameworks, join the Ugam Infotech team.',
            name: 'c3'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c2.svg`,
            title: 'Shopify Developer',
            desc: 'Seeking a skilled Shopify developer to create captivating e-commerce experiences that drive business growth.',
            name: 'c2'
        },

        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c6.svg`,
            title: 'Digital Marketing & Content Solutions',
            desc: 'We assist in developing both technical and non-technical skills related to SEO, including technical SEO, on-page SEO, and off-page SEO, to help you excel in the digital marketing industry.',
            name: 'c6'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c4.svg`,
            title: 'Graphics Designer',
            desc: 'If you have a creative mind and want to build your career in the design world, we are looking for both freshers and experienced professionals.',
            name: 'c4'

        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c5.svg`,
            title: 'Bussiness Development Executive',
            desc: 'If you have excellent persuasion skills and seek practical exposure in a Business Development Executive (BDE) role, consider joining Ugam Infotech',
            name: 'c5'
        },

        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c11.svg`,
            title: 'Motion Graphics Designer',
            desc: 'We are seeking a professional 2D Motion Graphic Artist & Character Animator who is creative and enthusiastic about creating high-quality, engaging content, with a strong proficiency in After Effects.',
            name: ' c11'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c7.svg`,
            title: 'Project Manager',
            desc: 'At Ugam Infotech, we have openings for the Project Manager position. If you possess the skills to manage teams effectively, maintain positive client relationships, develop realistic project plans, ensure timely delivery of results, schedule tasks, and delegate assignments, we encourage you to apply.',
            name: ' c7'
        },

        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c12.svg`,
            title: 'UI/UX Designer',
            desc: 'The team is seeking candidates interested in a UI/UX Designer career to craft user-friendly products and contribute to our software development.',
            name: 'c12'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c8.svg`,
            title: 'Human Resource',
            desc: 'We are looking for a skilled candidate with relevant expertise in the HR field, who will identify, support, and develop talent through policy development and procedure oversight.',
            name: 'c8'
        },
        {
            icon: `${process.env.REACT_APP_URL}/images/pages/company/careers/grow-career-icons/c9.svg`,
            title: 'Web Designer',
            desc: 'Ugam Infotech is the perfect place for candidates to kick-start their careers in the field of web designing.',
            name: 'c9'
        },

    ]

    return (
        <Box sx={{ py: {md:10 ,xs:5}, backgroundColor: theme.palette.background.lightDark }}>
            <Container maxWidth='xl'>
                {
                    Data.map((item, i) => (
                        <Box width='100%' key={i} onClick={() => navigate('/contact-us')} className="carrerMain" sx={{
                            px: 2, py: 4, transition: 'background-color .5s ease',
                            borderTop: i == 0 ? '' : '1px solid #e3e3e3', cursor: 'pointer'

                        }}>
                            <Grid container>
                                <Grid item lg={1} md={1.5} sx={{ display: { md: 'block', xs: 'none' } }} >
                                    <Box sx={{ width: '80px', height: '80px', p: 2, backgroundColor: theme.palette.secondary.main, transition: 'background-color .5s ease', textAlign: 'center' }}>
                                        <img width='100%' height='100%' src={item.icon} alt={item.name} title='Icons of Top Programming Languages' loading='lazy' />
                                    </Box>
                                </Grid>
                                <Grid item lg={11} md={10}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className='name' sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: { sm: '21px', xs: '18px' }, fontWeight: 700, lineHeight: { sm: '26px', xs: '20px' }, color: theme.palette.primary.main }}>{item.title}</Typography>
                                        <Box sx={{ color: theme.palette.secondary.main }}><TrendingFlatIcon /></Box>
                                    </Box>
                                    <Typography className='Desc' sx={{ mt: 2, fontFamily: theme.palette.typography.fontFamily, fontSize: '16px', lineHeight: '32px', color: theme.palette.primary.main }}>{item.desc}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
            </Container>
        </Box>
    )
}

export default Language