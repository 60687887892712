import { Box, Container, Typography, useTheme } from '@mui/material'
import React from 'react'

const OurCultureDesc = () => {
  const theme = useTheme();
  return (
    <Container maxWidth='mx'>
      <Box sx={{ textAlign: 'center', py: { lg: '100px', md: '20px' , xs:'10px' }, backgroundColor: theme.palette.background.lightDark }}>
        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, lineHeight: { sm: '50px', xs: '30px' }, fontWeight: 800, fontSize: { sm: '40px', xs: '20px' } , mb:{md:3 , sm:2 , xs:0}}}>At Ugam Infotech, it’s all about the VIBES</Typography>

        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, mt: { sm: 0, xs: 2 }, lineHeight: { sm: '30px', xs: '26px' }, fontSize: { sm: '21px', xs: '14px' } }}>Though we hail from diverse backgrounds and cities, we are one joyful family, united by a variety of personalities.</Typography>

        <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, lineHeight: { sm: '30px', xs: '26px' }, fontSize: { sm: '21px', xs: '14px' } }}>Our foundation for strong relationships is built on <span style={{ fontFamily: theme.palette.typography.fontFamily, lineHeight: { sm: '48px', xs: '36px' }, fontSize: { sm: '21px', xs: '14px' }, fontWeight: '700' , mt:{md:3 , xs:2} }}>innovation, passion and dedication.</span></Typography>
      </Box>


    </Container>
  )
}

export default OurCultureDesc