import { Box, Container, Typography, useTheme } from '@mui/material';
import React from 'react'

const AnimationPageDesc = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.lightDark, pb: { md: 10, xs: 4 } }}>
            <Container maxWidth="xl">
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                    Ugam Infotech is renowned for <Typography variant='span' sx={{fontWeight:'bold'}}>delivering premium multimedia services at competitive prices</Typography>, and the accessibility of advanced technological infrastructure has driven our rapid growth. Our creative team specializes in 2D and 3D animation, and we also develop storyboards, animatics, sketches, portraits, and caricatures.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                    If  you are looking for top-notch animation design for your website,<Typography variant='span' sx={{fontWeight:'bold'}}> we can craft appealing computer graphics </Typography> tailored to your needs. We blend art, sound, and computer graphics design seamlessly in our animations to effectively convey your message.
                </Typography>
                <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, sm: 3, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: {  md: '35px', xs: '28px' } }}>
                    We are also known for our commitment to working ahead of schedule and completing projects swiftly, thereby enhancing productivity in your business. Our team at Ugam Infotech uses animation to produce high-quality content that delivers your message with humor and elegance, achieved through the perfect blend of art, sound, and computer graphics design.
                </Typography>
            </Container>
        </Box>
    )
}

export default AnimationPageDesc