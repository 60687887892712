import React, { useEffect } from 'react'
import { useTheme } from '@mui/material'
import '../../../App.css';
import PageHeading from '../../commonComponents/PageHeading'
import PageTilteSection from '../../commonComponents/PageTitleSection'
import CounterPart from '../../commonComponents/CounterPart'
import AboutPageDesc from './Components/AboutPageDesc'
import AboutOurVision from './Components/AboutOurVision'
import AboutPageService from './Components/AboutPageService'
import AboutOurMission from './Components/AboutOurMission'
import AboutChoose from './Components/AboutChoose'
import AboutTour from './Components/AboutTour'
import AboutSlider from './Components/AboutSlider'
import WeAreTechComny from '../../Home/components/WeAreTechComny'
import SEO from '../../SEO'

function AboutUs() {
    const theme = useTheme();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <SEO
                title="About - Ugam Infotech"
                description="Learn more about Ugam Infotech, our mission, values, and the technologies we specialize in including React.js, Node.js, and more."
                keywords="Ugam, Ugam Infotech, about us, mission, values, React.js, Node.js, backend, frontend, web development"
                canonical={`${process.env.REACT_APP_URL}/company/about`}
                ogTitle="About Us - Ugam Infotech"
                ogDescription="Learn more about Ugam Infotech, our mission, values, and the technologies we specialize in including React.js, Node.js, and more."
                ogType="website"
                ogUrl={`${process.env.REACT_APP_URL}/company/about`}
                ogImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
                twTitle="About Us - Ugam Infotech"
                twDescription="Learn more about Ugam Infotech, our mission, values, and the technologies we specialize in including React.js, Node.js, and more."
                twImage={`${process.env.REACT_APP_URL}/images/seo/home.jpg`}
            />
            <PageHeading text={"about us"} />
            <PageTilteSection imageSrc={`${process.env.REACT_APP_URL}/images/pages/company/about-us/about-2.jpg`} bgImage={`${process.env.REACT_APP_URL}/images/about.svg`} titleText={'Always Ready to Serve Our Clients with Ease'} subTitleText={'Who we are?'} />
            <AboutPageDesc />
            <AboutOurVision />
            <CounterPart />
            <WeAreTechComny />
            <AboutPageService />
            <AboutChoose />
            <AboutSlider />
            <AboutTour />
            <AboutOurMission />
        </>
    )
}

export default AboutUs