import React from 'react'
import './style.css'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { CommonBtn, TypingTextAnimation } from '../../../CustomStyle/Common';
import { Link, useNavigate } from 'react-router-dom';


const SliderHeroPart = () => {
    const navigate = useNavigate();
    const themeColor = localStorage.getItem('theme');

    const typing = [
        "Always",
        "Consistently",
        "Seamlessly",
    ];

    const theme = useTheme();
    const style = {
        WebkitTextStroke: theme.palette.primary.light,
        WebkitTextStrokeWidth: '1px',
        color: '#0000',
        fontFamily: '"Poppins", sans-serif;',
        fontWeight: 600,
        fontSize: { xs: '63px', sm: '94px', md: '119px', lg: '156px' },
        lineHeight: { xs: '59px', sm: '90px', md: '115px', lg: '156px' },
        textAlign: { md: 'start', xs: 'center' },
        opacity: .4
    };

    const styleForText = {
        background: 'linear-gradient(272deg, rgb(23 92 255) 20%, rgb(255 17 17) 80%) text',
        fontFamily: 'Lily Script One',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: '#00000000',
        display: 'inline-block',
        fontWeight: 400
    }

    const options = {
        loop: true,
        margin: 35,
        nav: false,
        autoplay: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 6
            }
        }
    };

    return (
        <Box sx={{ pt: { md: 24, sm: 20, xs: 14 }, pb: { lg: 5, md: 8, sm: 6, xs: 4 }, backgroundColor: theme.palette.background.default, position: 'relative', overflow: 'hidden' }}>
            <Box sx={{
                width: { xs: '400px', sm: '600px', md: '600px', lg: '1000px' },
                height: { xs: '400px', sm: '600px', md: '600px', lg: '1000px' },
                opacity: '.20',
                background: themeColor == 'light' ? 'radial-gradient(60.88% 60.88% at 35.03% 45.57%, #f3ad59 0%, rgba(255, 205, 3, 0) 100%)' : 'radial-gradient(60.88% 60.88% at 35.03% 45.57%, #121a35b5 0%, rgba(255, 205, 3, 0) 100%)',
                borderRadius: '50%',
                position: 'absolute',
                top: { xs: '-20%', sm: '-50%', md: '-20%', lg: '-20%', xl: '-20%' },
                left: { xs: '-20%', sm: '-30%', md: '-20%', lg: '-20%', xl: '-20%' }
            }}></Box>
            <Box sx={{
                width: { xs: '400px', sm: '600px', md: '600px', lg: '1000px' },
                height: { xs: '400px', sm: '600px', md: '600px', lg: '1000px' },
                opacity: '.20',
                background: themeColor == 'light' ? 'radial-gradient(60.88% 60.88% at 35.03% 45.57%, #f3ad59 0%, rgba(255, 205, 3, 0) 100%)' : 'radial-gradient(60.88% 60.88% at 35.03% 45.57%, #121a35b5 0%, rgba(255, 205, 3, 0) 100%)',
                borderRadius: '50%',
                position: 'absolute',
                top: { xs: '-20%', sm: '-50%', md: '-20%', lg: '-20%', xl: '-20%' },
                right: { xs: '-20%', sm: '-30%', md: '-20%', lg: '-20%', xl: '-20%' },
                // right: { xs: '-30%', sm: '-15%', lg: '-20%' },
                rotate: '140deg'
            }}></Box>
            <Container sx={{ textAlign: 'center' }}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: { sm: 'row', xs: 'column' }, gap: { sm: 0, xs: 1 } }}>
                        <Box sx={{ width: { lg: '4%', md: '5%', sm: '7%', xs: '12%' }, marginTop: '6px', marginRight: { sm: '12px', xs: '6px' } }}>
                            <img alt="swastik" title="swastik" src={`${process.env.REACT_APP_URL}/images/swastik.svg`} width="100%" height="100%" loading="lazy" />
                        </Box>
                        <Box sx={{ fontFamily: theme.palette.typography.fontFamily, fontWeight: 400, color: theme.palette.primary.main, fontSize: { sm: '20px', xs: '16px' } }}>
                            भवतः दृष्टिः, अस्माकं ध्येयः। संपूर्णताम् सह कार्येण।
                        </Box>
                    </Box>
                    <Typography variant='h1' sx={{
                        color: theme.palette.primary.main,
                        fontSize: { xs: '28px', sm: '32px', md: '50px', lg: '64px' },
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 700,
                        marginTop: { xs: '22px', sm: '30px', md: '44px', lg: '60px' },
                        lineHeight: { md: '86px', sm: '52px', xs: '40px' }
                    }}>Your Vision, Our Mission: Achieving Success Together,  <TypingTextAnimation textArr={typing} /></Typography>
                    <Box sx={{ mt: 3 }}>
                        <Typography sx={{
                            fontSize: '16px',
                            color: theme.palette.primary.main,
                            fontFamily: theme.palette.typography.fontFamily,
                        }}>Feel free to adjust the text to better reflect our company's vision, mission, and specific services.</Typography>
                    </Box>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', gap: { lg: 3, sm: 2, xs: 1 }, justifyContent: 'center', mt: 5 }}>
                        <Box onClick={() => navigate('/contact-us')}>
                            <CommonBtn backgroundColor={theme.palette.secondary.main} text={'Start a New Project'} hoverBGColor={theme.palette.secondary.main} />
                        </Box>
                        <Box onClick={() => navigate('/company/about')}>
                            <CommonBtn border={`2px solid ${theme.palette.secondary.main}`} backgroundColor={'transparent'} color={theme.palette.secondary.main} text={'Explore Our Work'} hoverBGColor={theme.palette.secondary.main} hoverColor={"#fff"} />
                        </Box>
                    </Grid>
                </Box>
            </Container>
            <Container sx={{ textAlign: 'center', marginTop: { xs: '50px', lg: '56px' } }}>
                <Box sx={{ mb: 3 }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '20px', color: theme.palette.primary.main, fontFamily: theme.palette.typography.fontFamily }}>See how over 7,700+ customers <Box component='span' style={styleForText}>Help the world work</Box></Typography>
                </Box>
            </Container>
            <Container sx={{ textAlign: 'center', marginTop: { xs: '50px', lg: '50px' } }}>
                    <Box sx={{ width: '100%'}}>
                        <Link to='https://www.facebook.com/ugaminfotech' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Facebook' aria-label="Visit our Facebook page">
                            <FacebookIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
                        </Link>
                        <Link to='https://www.instagram.com/ugaminfotech/' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Instagram' aria-label="Visit our Instagram page">
                            <InstagramIcon sx={{ fontSize: '40px', ml: 2, color: theme.palette.primary.main }} />
                        </Link>
                        <Link to='https://www.linkedin.com/company/ugam-infotech' style={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer" title='Linkedin' aria-label="Visit our Linkedin page">
                            <LinkedInIcon sx={{ fontSize: '40px', ml: 2, color: theme.palette.primary.main }} />
                        </Link>
                    </Box>
            </Container>
        </Box>
    )
}

export default SliderHeroPart