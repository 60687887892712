import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'

const AboutChoose = () => {
    const theme = useTheme();

    const Ugam = [

        {
            title: 'Easy Communication',
            desc: "At Ugam Infotech, we prioritize seamless and clear communication, ensuring you are always informed and engaged. Our advanced communication tools and dedicated support team make it easy for you to ask questions, provide feedback, and stay updated. With us, you can trust that your needs and concerns will be addressed promptly and effectively."
        },
        {
            title: 'Expert Advise',
            desc: "At Ugam Infotech, our seasoned professionals provide insightful and strategic advice tailored to your business needs. Leverage our expertise to make informed decisions and drive your success with confidence and clarity."
        },
        {
            title: 'Security Assured',
            desc: "At Ugam Infotech, we prioritize the highest standards of security to protect your data and ensure your privacy. Our robust security measures, including advanced encryption, regular audits, and compliance with industry regulations, guarantee that your information is safe and secure. With Ugam Infotech, you can have full confidence in the integrity and confidentiality of your data."
        }
    ]

    return (
        <Box sx={{ background: theme.palette.background.default, py: { md: 10, md: 8, sm: 6, xs: 4 } }}>
            <Container maxWidth='xl'>
                <Box sx={{ textAlign: 'center', mb: 10 }}>
                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: { lg: '55px', md: '45px', sm: '35px', xs: '30px' }, fontWeight: 700 }}>Why Choose Ugam Infotech?</Typography>
                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, fontSize: { md: '20px', sm: '18px', xs: '16px' }, letterSpacing: 1, mt: 2 }}>Choosing Ugam Infotech means partnering with a company dedicated to accelerating your digital transformation with unmatched expertise and commitment. Here are the top three reasons to choose Ugam:</Typography>
                </Box>
                <Grid container spacing={3}>
                    {
                        Ugam.map((item, index) => (

                            <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                <Box sx={{
                                    position: 'relative',
                                    background: theme.palette.background.default,
                                    textAlign: 'center', ml: 1, p: { md: 4, xs: 3 }, transition: 'all .3s ease-in-out',
                                    borderRadius: 8,
                                    boxShadow: `0 0 2px ${theme.palette.primary.light}`,
                                    '&:hover': {
                                        boxShadow: `0 0 30px ${theme.palette.primary.light}`,
                                        transform: 'translateY(-30px)',
                                        position: 'relative',
                                        '&::before': {
                                            opacity: 1
                                        }
                                    },
                                    '&::before': {
                                        content: `""`,
                                        background: `${theme.palette.secondary.main}`,
                                        borderRadius: '6px 6px 0 0',
                                        bottom: 0,
                                        position: 'absolute',
                                        height: '10px',
                                        left: { lg: '30%', md: '26%', sm: '28%', xs: '30%' },
                                        transform: 'traslate(-50% , -50%)',
                                        right: 0,
                                        width: '40%',
                                        opacity: 0,
                                        transition: 'all .3s ease-in-out',
                                    }

                                }}>
                                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: { md: '30px', xs: '26px' }, lineHeight: '32px', fontWeight: 700, mb: 4 }}>{item.title}</Typography>
                                    <Typography sx={{ fontFamily: theme.palette.typography.fontFamily, color: theme.palette.primary.main, fontSize: '16px', fontFamily: 'Poppins,sans-serif', lineHeight: '30px' }}>{item.desc}</Typography>
                                </Box>
                            </Grid>
                        ))
                    }

                </Grid>
            </Container>
        </Box>
    )
}

export default AboutChoose