import React from 'react'
import { Heading72px } from '../../../CustomStyle/Common'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'

const WhyChoose = () => {

    const theme = useTheme();

    const counterData = [
        {
            title: 'Skilled CV Developer',
            icon: `${process.env.REACT_APP_URL}/images/why-choose-ugam-icons/why_choose_01.svg`,
            border: {
                borderRight: { xs: '1px solid #e1e1e1' },
            },
            name: 'why_choose_01'
        },
        {
            title: 'Quick Response',
            icon: `${process.env.REACT_APP_URL}/images/why-choose-ugam-icons/why_choose_02.svg`,
            border: {
                borderRight: { xs: '0', md: '1px solid #e1e1e1' },
            },
            name: 'why_choose_02'
        },
        {
            title: 'Expert Advise',
            icon: `${process.env.REACT_APP_URL}/images/why-choose-ugam-icons/why_choose_03.svg`,
            border: {
                borderRight: { xs: '1px solid #e1e1e1' },
            },
            name: 'why_choose_03'
        },
        {
            title: 'No-cost Project Management',
            icon: `${process.env.REACT_APP_URL}/images/why-choose-ugam-icons/why_choose_04.svg`,
            border: {
                borderRight: { xs: '0', sm: '0' },
            },
            name: 'why_choose_04'
        },
    ];

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            <Container maxWidth="xl" sx={{ py: {md:12  , xs:8} ,   textAlign :{ md: 'start', xs: 'center' } }}>
                <Heading72px  color={theme.palette.primary.main} text={'Why Choose Ugam Infotech?'} />
                <Grid container>
                    {
                        counterData.map((val, i) =>
                            <Grid item key={i} md={3} xs={6} sx={{ ...val.border, mt: 6 }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box>
                                        <Box sx={{ background: theme.palette.secondary.main, display: 'block', m: '0 auto', p: { lg: 3, sm: 2, xs: 2 }, borderRadius: '50%', width: { lg: '100px', xs: '65px' }, height: { lg: '100px', xs: '65px' } }}>
                                            <img width="100%" src={val.icon} alt={val.name} title={val.title} loading='lazy' height='auto' />
                                        </Box>
                                        <Box sx={{
                                            pt: { lg: 5, md: 3, xs: 2 },
                                        }} >
                                            <Typography sx={{ fontSize: { lg: '20px', md: '18px', xs: '14px' }, fontWeight: 500, color: theme.palette.primary.main, fontFamily: theme.palette.typography.fontFamily }}>{val.title}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
            </Container>
        </Box>
    )
}

export default WhyChoose