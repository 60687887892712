import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ArrowCommon, ServiceHeading } from '../../../Common';

const Sopify = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, py: { md: 10, xs: 8 } }}>
            <Container maxWidth='xl'>
                <Grid container spacing={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item lg={6} md={6} xs={12} >
                        <Box>
                            <ServiceHeading text='Shopify' />
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 2, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>Want to build your digital store with Shopify?</Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { lg: 3, md: 3, sm: 2, xs: 2 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                Ugam Infotech is a trusted Shopify web development company based in India, renowned for our dedicated team of Shopify developers, extensive Shopify store development services, and proven development methodology.
                            </Typography>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: { lg: '18px' }, mt: { md: 5, xs: 3 }, fontFamily: theme.palette.typography.fontFamily, lineHeight: { md: '35px', xs: '28px' } }}>
                                We provide complete Shopify installation, configuration, and integration services to help your business achieve its e-commerce goals within the set timeframe. Using the latest tools and technologies, we create stunning Shopify websites that are <Typography variant='span' sx={{fontWeight:'bold'}}>feature-rich, efficient, and of high quality</Typography>. We are committed to delivering an e-commerce store that meets all your business requirements.
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Grid container>
                                <Grid item md={6} sm={6} xs={12} >
                                    <ArrowCommon text={'Shopify Ecommerce Expansion'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} >
                                    <ArrowCommon text={'Custom Shopify Development'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} >
                                    <ArrowCommon text={'Shopify Integration and Migration'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                                </Grid>
                                <Grid item md={6} sm={6} xs={12} >
                                    <ArrowCommon text={'Shopify Theme Development'} fontWeight={600} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.contrastText} />
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sx={{ textAlign: 'center', width: { md: '90%', sm: '65%', xs: '90%' }, m: '0 auto', height: 'auto' }}>
                        <img src={`${process.env.REACT_APP_URL}/images/pages/services/ecommerce-and-cms/sopify.png`} width='100%' height='auto' alt="sopify" title='Shopify Storefront Design Optimizing for Conversions' loading='lazy' />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Sopify