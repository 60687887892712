import { Box, Container, Grid, List, ListItem, Typography, useTheme } from '@mui/material'
import React from 'react'
import { CommonBtn } from '../../../components/CustomStyle/Common'
import { Link } from 'react-router-dom'

const FooterDesktop = ({ company, services, portfolio, contact, socialIcon, copyRightCnt, privacyTremBtn }) => {
    const theme = useTheme();
    return (
        <>
            <Box sx={{ opacity:'0.04',position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                <img width="100%" src={`${process.env.REACT_APP_URL}/images/footer-img/footer-petter.png`} alt='footer-petter' title='Footer Petter Image' loading='lazy' height='auto' />
            </Box>
            <Box sx={{ width: { lg: '85%', md: '90%' }, m: '0 auto', fontSize: { xl: '20px', lg: '16px', md: '16px' } }}>
                <Grid container>
                    <Grid lg={2} md={3} item>
                        <Box>
                            <Typography sx={{
                                fontFamily: theme.palette.typography.fontFamily,
                                fontSize: { xl: '20px', lg: '16px', md: '16px' },
                                color: theme.palette.secondary.dark, fontWeight: 600,
                                mb: 1
                            }}>
                                Company
                            </Typography>
                            <List>
                                {
                                    company.map((val, i) =>
                                        <Link style={{ textDecoration: 'none' }} key={i} to={val.link} aria-label={val.arialable} title={val.title} rel='follow'>
                                            <ListItem sx={{
                                                color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 2, cursor: 'pointer', '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}>
                                                {val.title}
                                            </ListItem>
                                        </Link>
                                    )
                                }
                            </List>
                        </Box>
                    </Grid>
                    <Grid lg={3} md={5} item>
                        <Typography sx={{
                            fontFamily: theme.palette.typography.fontFamily,
                            fontSize: { xl: '20px', lg: '16px', md: '16px' },
                            color: theme.palette.secondary.dark, fontWeight: 600,
                            mb: 1
                        }}>
                            Services
                        </Typography>
                        <List>
                            {
                                services.map((val, i) =>
                                    <Link style={{ textDecoration: 'none' }} key={i} to={val.link} aria-label={val.arialable} title={val.title} rel='follow'>
                                        <ListItem sx={{
                                            color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 2, cursor: 'pointer', '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}>
                                            {val.title}
                                        </ListItem>
                                    </Link>
                                )
                            }
                        </List>
                    </Grid>
                    <Grid lg={3} md={4} item>
                        <Typography sx={{
                            fontFamily: theme.palette.typography.fontFamily,
                            fontSize: { xl: '20px', lg: '16px', md: '16px' },
                            color: theme.palette.secondary.dark, fontWeight: 600,
                            mb: 1
                        }}>
                            Portfolio
                        </Typography>
                        <List>
                            {
                                portfolio.map((val, i) =>
                                    <Link style={{ textDecoration: 'none' }} key={i} to={val.link} aria-label={val.arialable} title={val.title} rel='follow'>
                                        <ListItem sx={{
                                            color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 2, cursor: 'pointer', '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}>
                                            {val.title}
                                        </ListItem>
                                    </Link>
                                )
                            }
                        </List>
                    </Grid>
                    <Grid lg={4} md={12} item sx={{ mt: { lg: 0, md: 2 } }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: theme.palette.typography.fontFamily,
                                    fontSize: { xl: '20px', lg: '16px', md: '16px' },
                                    color: theme.palette.secondary.dark, fontWeight: 600,
                                }}>
                                    Calls
                                </Typography>
                                <List sx={{ opacity: .8 }}>
                                    {
                                        contact.map((val, i) =>
                                            <ListItem key={i} sx={{
                                                color: theme.palette.primary.main, textTransform: 'capitalize', p: 0, my: 2
                                            }}>
                                                <b>{val.title}:</b>
                                                &nbsp;
                                                <Link style={{ textDecoration: 'none' }} to={val.link} aria-label={val.arialable} rel='follow' title={val.title}>
                                                    <Typography sx={{
                                                        fontFamily: theme.palette.typography.fontFamily,
                                                        color: theme.palette.primary.main,
                                                        fontSize: { xl: '20px', lg: '16px', md: '16px' },
                                                        m: 0,
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            textDecoration: 'underline'
                                                        }
                                                    }}>
                                                        {val.number}
                                                    </Typography>
                                                </Link>
                                            </ListItem>
                                        )
                                    }
                                </List>
                            </Box>
                            <Box>
                                <Link to="/contact-us" aria-label='Our contact detail' title='contact us' rel='follow'>
                                    <CommonBtn text={"contact us"} backgroundColor={theme.palette.secondary.main} hoverBGColor={theme.palette.secondary.main} name={"ArrowOutwardIcon"} />
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 6, display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: theme.palette.typography.fontFamily,
                                    fontSize: { xl: '20px', lg: '16px', md: '16px' },
                                    color: theme.palette.secondary.dark, fontWeight: 600,
                                    mb: 2
                                }}>
                                    Follow Us
                                </Typography>
                                <List sx={{ display: 'flex', gap: { xl: '16px', lg: '8px', md: '8px' } }}>
                                    {
                                        socialIcon.map((val, i) =>
                                            <Link to={val.link} key={i} target='_blank' style={{ color: theme.palette.primary.main }} aria-label={val.arialable} title={val.name} rel='noopener noreferrer'>
                                                <ListItem sx={{
                                                    color: theme.palette.primary.main, p: { xl: 2, lg: 1, md: 1 }, background: 'transparent', border: `1px solid ${theme.palette.secondary.dark}`, borderRadius: '50%', cursor: 'pointer', transition: 'all .2s ease-in-out', '&:hover': {
                                                        backgroundColor: "#FFF", color: '#1a2a1d'
                                                    }
                                                }}>
                                                    <val.icon />
                                                </ListItem>

                                            </Link>
                                        )
                                    }
                                </List>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 6 }}>
                            <Box>
                                <Typography sx={{
                                    fontFamily: theme.palette.typography.fontFamily,
                                    fontSize: { xl: '20px', lg: '16px', md: '16px' },
                                    color: theme.palette.secondary.dark, fontWeight: 600,
                                    mb: 2
                                }}>
                                    Copyright By
                                </Typography>
                                <List sx={{ width: '100%' }}>
                                    <ListItem sx={{
                                        color: theme.palette.primary.main,
                                        p: 0,
                                        display: 'flex',
                                        flexDirection: { lg: 'column' },
                                        justifyContent: { lg: '', md: 'space-between' },
                                        alignItems: 'start'
                                    }}>
                                        <Box component='span' sx={{ color: theme.palette.primary.main }}>
                                            {copyRightCnt}
                                        </Box>
                                        {/* <Box sx={{ display: 'flex' }}>
                                            {
                                                privacyTremBtn.map((val, i) =>
                                                    <Typography key={i} sx={{ p: 0, width: '100%', color: '#FFF', display: 'block', whiteSpace: 'pre', color: theme.palette.primary.main, fontFamily: theme.palette.typography.fontFamily, }}>
                                                        {val.text}
                                                        {
                                                            i == 0 ? <Box component="span" sx={{ display: 'inline', px: { lg: 2, md: 1 } }}>|</Box> : ''
                                                        }
                                                    </Typography>
                                                )
                                            }
                                        </Box> */}
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default FooterDesktop